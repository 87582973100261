import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import queryString from "query-string"


class Home extends Component {
    
    state = {
        loading: true,
        editing: false,
        search: "",
        redirect: "",
        processes: [
            { id: 0, name: "Drafting", selected: false},
            { id: 1, name: "Material Prep.", selected: false},
            { id: 2, name: "Laser", selected: false},
            { id: 3, name: "Turning", selected: false},
            { id: 4, name: "Milling", selected: false},
            { id: 5, name: "Grinding", selected: false},
            { id: 6, name: "Fitting", selected: false},
            { id: 7, name: "Folding", selected: false},
            { id: 8, name: "Pressing", selected: false},
            { id: 9, name: "Welding", selected: false},
            { id: 10, name: "Supply", selected: false},
            { id: 11, name: "Hypermill", selected: false}
        ],
        TurningJobs: [],
        MillingJobs: [],
        GrindingJobs: [],
        FittingJobs: [],
    }

    async componentDidMount() {
        let t=this;
        var query = queryString.parse(this.props.location.search);
        var search = "";
        if(typeof query.q !== 'undefined'){
            search = query.q;
        }
        const {setPage} = this.props;
        setPage("Machining - Process List");
        this.reload(true, search);
        this.setState({search: search, loading: false});

        
    }
    reload = async (loading, search)=>{
        const {setPage, databaseRequest}=this.props;
        let t =this;
        
        setPage("Machining - Process List");
        this.setState({loading: loading});
        var Jobs = await databaseRequest("/jobs?CompletedDateTime=-1","GET");
        var ProcessList = await databaseRequest("/schedule/process-list/milling?q="+search,"GET");
        var Companies = await databaseRequest("/companies","GET");
        var Contacts = await databaseRequest("/contacts","GET");
        var Contacts = await databaseRequest("/contacts","GET");
        var TurningJobs = [];
        var MillingJobs = [];
        var GrindingJobs = [];
        var FittingJobs = [];
        for(var i=0; i<ProcessList.length; i++){
            for(var j=0; j<Jobs.length; j++){
                if(Jobs[j].Id==ProcessList[i].JobId){
                    ProcessList[i].Job = Jobs[j];
                }
            }
            for(var j=0; j<Companies.length; j++){
                if(Companies[j].Id==ProcessList[i].Job.CompanyId){
                    ProcessList[i].Company = Companies[j];
                }
            }
            for(var j=0; j<Contacts.length; j++){
                if(Contacts[j].Id==ProcessList[i].Job.ContactId){
                    ProcessList[i].Contact = Contacts[j];
                }
            }
            if(ProcessList[i].Process==3 && ProcessList[i].Job.State < 6){
                TurningJobs.push(ProcessList[i]);
            }
            if(ProcessList[i].Process==4 && ProcessList[i].Job.State < 6){
                MillingJobs.push(ProcessList[i]);
            }
            if(ProcessList[i].Process==5 && ProcessList[i].Job.State < 6){
                GrindingJobs.push(ProcessList[i]);
            }
            if(ProcessList[i].Process==6 && ProcessList[i].Job.State < 6){
                FittingJobs.push(ProcessList[i]);
            }
        }
        this.setState({
            ProcessList: ProcessList,
            TurningJobs: TurningJobs,
            MillingJobs: MillingJobs,
            GrindingJobs: GrindingJobs,
            FittingJobs: FittingJobs,
            Jobs: Jobs,
            Companies: Companies,
            Contacts, Contacts,
        });
        
    }
    rowData = (row) =>{
        var name = row.Company.Name;
        const ProcessList = this.state.ProcessList;
        if(row.Company.Id==-2){
            name = row.Contact.Name;
        }
        var otherWork = "";
        for(var i=0; i<ProcessList.length; i++){
            if(ProcessList[i].JobId==row.JobId && ProcessList[i].ProcessId!=row.ProcessId){
                if(ProcessList[i].ProcessId > row.ProcessId){
                    
                    if(ProcessList[i].Process=="3"){otherWork += "Turn";}
                    if(ProcessList[i].Process=="4"){otherWork += "Mill";}
                    if(ProcessList[i].Process=="5"){otherWork += "Grind";}
                    otherWork += " after, ";
                }
                else{
                    if(ProcessList[i].Process=="3"){otherWork += "Turn";}
                    if(ProcessList[i].Process=="4"){otherWork += "Mill";}
                    if(ProcessList[i].Process=="5"){otherWork += "Grind";}
                    otherWork += " before, ";
                }
            }
        }
        otherWork = otherWork.slice(0, -2); 
        return (
        <p className='p-0 m-0'>
            <h2 className="pb-0 mb-1">[{row.Job.Id}] {name.toUpperCase()} | {row.Job.Name.toUpperCase()} <small> - {otherWork.toUpperCase()}</small></h2>
            <p style={{fontSize: '1rem'}}>
            {row.Job.Scope.split("\\n").map(function(item, idx) {
                return (
                    <span key={idx}>
                        {item}
                        <br/>
                    </span>
                )
            })}
            </p>
        </p>)
    }
    secondsToTime = (time)=>{
        var seconds = time%60;
        var minutes = ((time - seconds)/60)%60;
        var hours = (time-seconds-(minutes*60))/3600;
        return `${hours<10?"0"+hours:hours}:${minutes<10?"0"+minutes:minutes}:${seconds<10?"0"+seconds:seconds}`
    }
    setRedirect = (url) =>{
        this.setState({redirect:url})
    }
    render(){
        if(this.state.redirect.length>1){
            this.props.history.push(this.props.location.pathname);
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        if(this.state.loading){
            return (
                <h1>Loading..</h1>
            )
        }
        return (
            <main role="main" className="p-2 pt-6">
                 <h2><i className="fe fe-trending-up"></i> Machining Process List</h2>


                 <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <tr><th scope="col">TURNING</th></tr>
                            {
                                this.state.TurningJobs.map((row, i) => {
                                    return (<tr><td>{this.rowData(row)}</td></tr>)
                                })
                            }
                        </table>
                    </div>
                    <div className="col" >
                        <table className="table table-bordered">
                            <tr><th scope="col">MILLING</th></tr>
                            {
                                this.state.MillingJobs.map((row, i) => {
                                    return (<tr><td>{this.rowData(row)}</td></tr>)
                                })
                            }
                        </table>
                    </div>
 
                    <div className="col" >
                        <table className="table table-bordered">
                            <tr><th scope="col">GRINDING</th></tr>
                            {
                                this.state.GrindingJobs.map((row, i) => {
                                    return (<tr><td>{this.rowData(row)}</td></tr>)
                                })
                            }
                        </table>
                        <table className="table table-bordered">
                            <tr><th scope="col">FITTING</th></tr>
                            <tr>
                            {
                                this.state.FittingJobs.map((row, i) => {
                                    return (<tr><td>{this.rowData(row)}</td></tr>)
                                })
                            }
                            </tr>
                        </table>
                    </div>

                    </div>

            </main>

            
        )
    }
}
export default Home;