import React, { Component, Fragment } from 'react';
import {Link, Redirect, useLocation } from "react-router-dom";
import TimeAgo from '../../Components/TimeAgo.js';

import Pricing from './Pricing.js';
import {BottomScrollListener} from 'react-bottom-scroll-listener';
import queryString from "query-string"



class Nests extends Component {
    disableLoad = false
    state = {
        loading: true,
        searchComponents: {
            Description: "",
            Material: "",
            Thickness: -1,
            State: -1
        },
        search: "",
        redirect: "",
        scroll: 0,
        pg: 0,
        location: "",
        url: "",
        data: [],
        filerState: -1,
        showFilterButtons: false,
        showProcessButtons: false,
    }
    setFilter = (state)=>{
        let t = this;
        let searchComponents = this.state.searchComponents;

        if(searchComponents.filerState==state){
            state=-1;
        }
        searchComponents.State = state;
        this.searchInput.focus();
        this.setState({data: [], pg: 0, searchComponents: searchComponents});
        setTimeout(()=>{
            t.load();
        },200);
    }
    atBottom = () =>{
        let t = this;
        var newPage = this.state.pg+1;
        this.setState({
            pg: newPage,
            scroll: window.scrollY
        });
        setTimeout(()=>{
            if(!t.disableLoad){
                t.load(newPage, false, window.scrollY);
            }      
        },100)
    }

    onSearch = (e) => {
        let t = this;
        let searchComponents = this.state.searchComponents;
        searchComponents[e.target.name]=e.target.value;



        this.setState({data: [], pg: 0, searchComponents: searchComponents});
        setTimeout(()=>{
            t.load();
        },200);
    }
    clearSearch = () =>{
        let t = this;
        let searchComponents = this.state.searchComponents;
        searchComponents.Description = "";
        searchComponents.Thickness = -1;
        searchComponents.Material = "";
        searchComponents.State = -1;
        this.setState({data: [], pg: 0, searchComponents: searchComponents});
        this.searchInput.focus();
        setTimeout(()=>{
            t.load();
        },200);
    }

    updateState = async (NestId, newState) =>{
        const {databaseRequest,params,setPage}=this.props;
        var {location, scroll} = this.state;
        location = location.substring(1);
        
        let t = this;
        this.setState({
            loading: false,
            loadingMsg: `Updating nest...`
        });
        var data = this.state.data;
        var req = {
            url: `/nests/${NestId}`,
            method: "PUT"
        }
        if(NestId!=="-1"){
            for(var i=0; i<data.length; i++){
                if(data[i].Id==NestId){      
                    data[i].IsHidden = true;
                }
            }
            this.setState({
                data: data
            });
            var newData = await databaseRequest(req.url,req.method, JSON.stringify({
                State: newState
            }));
        }
    }
    deleteNest = async (NestId) =>{
        const {databaseRequest,params,setPage}=this.props;
        var {location, scroll} = this.state;
        location = location.substring(1);
        
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: `Deleting nest...`
        });
        var data = this.state.data;
        var req = {
            url: `/nests/${NestId}`,
            method: "DELETE"
        }
        if(NestId!=="-1"){
            for(var i=0; i<data.length; i++){
                if(data[i].Id==NestId){      
                    data[i].IsHidden = true;
                }
            }
            this.setState({
                data: data
            });
            var newData = await databaseRequest(req.url,req.method);
            setTimeout(()=>{
                t.setState({loading: false});
            },500);
        }

    }
    stateClass = (state) =>{
        switch(state){
           case -1: return "card float-left badge-soft-danger d-none"; 
           case 0: return "card float-left badge-soft-warning text-dark"; 
           case 1: return "card float-left badge-soft-success text-dark"; 
           case 2: return "card float-left badge-soft-info text-dark"; 
           case 3: return "card float-left badge-soft-danger text-dark"; 
        }
        return "";
    }

    componentDidMount = async () =>{
        const {databaseRequest}=this.props;
        window.addEventListener('scroll', this.handleScroll);


        Pricing.loadPricing();
        



        /*NEST STATES
        0 - Not Associated - Warning
        1 - Cut - Primary
        2 - Quote - Info

        -1 - Delete - Not Shown
        */
        var query = queryString.parse(this.props.location.search);
        var pg = 0;
        let searchComponents = this.state.searchComponents;
        if(typeof query.description !== 'undefined'){
            searchComponents.Description = query.description;
        }
        if(typeof query.state !== 'undefined'){
            searchComponents.State = query.state;
        }
        if(typeof query.thickness !== 'undefined'){
            searchComponents.Thickness = query.thickness;
        }
        if(typeof query.material !== 'undefined'){
            searchComponents.Material = query.material;
        }
        if(typeof query.pg !== 'undefined'){
            pg = Number(query.pg);
            if(isNaN(pg)){
                pg = 0;
            }
        }
        
        var position = 0;
        if(typeof query.p !== 'undefined'){
            position = query.p;
        }
        if(position == 0){
            pg=0;
        }

        this.load(pg, true, position);
        this.setState({pg: pg, searchComponents: searchComponents});   
    }
    load = async (pg=0, loadUpToPage=false, position=0) =>{
        const {databaseRequest,params, view, setPage}=this.props;
        let t = this;
        let {searchComponents, scroll} = this.state;

        

        var url = "/nests?";

        var showFilterButtons = false;
        var showProcessButtons = false;
        switch(view){
            case "All":
                showFilterButtons = true;
                setPage("Laser Nests - Search");
            break;
            case "Processing":
                url += "&state="+0;
                showProcessButtons = true;
                setPage("Laser Nests - For Processing");
            break;
            case "Complete":
                url += "&state="+1;
                setPage("Laser Nests - Complete");
            break;
            case "Quotes":
                url += "&state="+2;
                setPage("Laser Nests - Quotes");
            break;
            case "Archived":
                url += "&state="+3;
                setPage("Laser Nests - Cut | Not Priced");
            break;
        }


        if(searchComponents.Description.length > 0){
            url += "&description="+encodeURIComponent(searchComponents.Description);
        }
        if(searchComponents.Thickness!=-1){
            url += "&thickness="+encodeURIComponent(searchComponents.Thickness);
        }
        if(searchComponents.Material.length > 0){
            url += "&material="+encodeURIComponent(searchComponents.Material);
        }

        var location = url.replace("/nests",window.location.pathname);
        location += "&pg="+pg;
        if(position==0){
            this.props.history.replace(`${location}`);
        }
        
        var data = t.state.data;
        var startingPage = pg;
        if(loadUpToPage){
            startingPage=0;
        }

        for(let j=startingPage; j<=pg; j++){
            var newData = await databaseRequest(url+"&pg="+j,"GET");
            for(let i=0; i<newData.length; i++){
               
                newData[i].Prediction = newData[i].CostCutting == 0;
                newData[i] = Pricing.processData(newData[i]);

                var found = false;
                for(let j=0; j<data.length; j++){
                    if(data[j].Id==newData[i].Id){
                        found=true;
                    }
                }
                if(!found){
                    newData[i].IsHidden = false;
                    data.push(newData[i]);   
                }
                                    
            }    
        }
        if(position > 0){
            setTimeout(()=>{
                window.scrollTo(0, position);
            },100);
             
        }
        t.setState({
            showFilterButtons: showFilterButtons,
            showProcessButtons: showProcessButtons,
            location: location,
            data:data,
            loading:false,
        })
    }
    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll =  (event) => {
        const {location,loading} = this.state;
        if(!loading){
            this.setState({scroll: window.scrollY})
            this.props.history.replace(`${location}&p=${window.scrollY}`);
        }
    }
    minsToTime(min){
        var hh = Math.floor(min/60);
        var mm = (min - (hh*60));
        var ss = (min%1)*60;
        var hhStr = hh<10?"0"+hh.toFixed(0):hh.toFixed(0);
        var mmStr = mm<10?"0"+mm.toFixed(0):mm.toFixed(0);
        var ssStr = ss<10?"0"+ss.toFixed(0):ss.toFixed(0);
        return  hhStr+ ":" + mmStr + ":" +  ssStr;
    }
    table = () => {   
        const {showProcessButtons} = this.state; 
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading nests...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                
                return (
                    <div className="col" ref={this.scrollRef}>
                        {this.state.data.map((row, i) => {
                            if(row.IsHidden){
                                return (<></>);
                            }
                            return (
                            <div key={i} className={this.stateClass(row.State)} style={{width: "50%"}}>
                            <Link to={`/nests/${row.Id}`} onClick={()=>{this.disableLoad=true;}}><img src={row.Image} className="card-img-top cursor-pointer" alt={row.JobName}/></Link>
                            <div className="card-body">
                              <h5 className="card-title font-weight-bold">{row.JobName}</h5>
                              <h5><b><TimeAgo time={row.CreatedDateTime} /></b> ({new Date(row.CreatedDateTime).toLocaleString()})</h5>
                              <p className="card-text">
                                <p className="mb-0 font-weight-bold">{row.MaterialName}</p>
                                <p className="mb-0 font-weight-bold">{row.Thickness.toFixed(1)}mm</p>
                                {/*<p className="mb-0">{row.SheetSizeX.toFixed(1)} x {row.SheetSizeY.toFixed(1)} - {row.Weight.toFixed(1)}kg</p>
                                <p className="mb-0">{this.minsToTime(row.RunTime)}</p>
                                <p className={row.Prediction?"mb-0 font-italic":"mb-0 font-weight-bold"}>C:${(row.CostCutting).toFixed(0)} + M:${(row.CostMaterial).toFixed(0)} + H:${(row.CostHandling).toFixed(0)} = ${(row.CostCutting+row.CostMaterial + row.CostHandling).toFixed(0)}</p>*/}
                            </p>
                            {showProcessButtons?(<>
                                <button type="button" className="btn btn-danger float-right ml-2" onClick={()=>{this.deleteNest(row.Id)}}><i className='mr-2 fe fe-trash-2'></i> Delete</button>
                                <button type="button" className="btn btn-warning float-left ml-2" onClick={()=>{this.updateState(row.Id, 3)}}><i className='mr-2 fe fe-archive'></i> Cut | Not Priced</button>
                            </>):(<></>)}
                            {/*<button type="button" className={this.buttonState(row.State,-1, "danger")} onClick={()=>{this.deleteNest(row.Id)}}><i className='mr-2 fe fe-trash-2'></i> Delete</button>
                            <button type="button" className={this.buttonState(row.State,2, "info")} onClick={()=>{this.updateState(row.Id, 2)}}><i className='mr-2 fe fe-dollar-sign'></i> Quote</button>
                            <button type="button" className={this.buttonState(row.State,1, "success")} onClick={()=>{this.updateState(row.Id, 1)}}><i className='mr-2 fe fe-check-circle'></i> Complete</button>*/}
                            </div>
                          </div>)
                        })}
                    </div>

                )
            }
    }

    render(){
        const {searchComponents, showFilterButtons} = this.state;

        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-sunset"></i> Laser Nests</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline w-100 d-block">
                            <input className="form-control mr-sm-2 ml-3" type="search" name="Description" value={searchComponents.Description} ref={(input) => { this.searchInput = input; }} onChange={this.onSearch} placeholder="Search" aria-label="Search"/> 

                            <select className="form-control ml-2" name="Thickness" value={searchComponents.Thickness} onChange={this.onSearch}>
                                    <option value="-1">All</option>
                                        {Pricing.allThickness().map((t, i)=>{
                                            return (<option key={i} value={t}>{t.toFixed(1)}mm</option>)
                                        })}
                            </select>

                            <select className="form-control ml-2" value={searchComponents.Material} name="Material" onChange={this.onSearch}>
                                    <option value="all">All</option>
                                    {Pricing.Material.map((m)=>{
                                        return (<option value={m.Key}>{m.Name}</option>)
                                    })}
                            </select>

                            {showFilterButtons?(
                            <>  
                                <button type="button" className={searchComponents.State==0?"form-control ml-3 btn-warning":"form-control ml-3 btn-outline-warning"} onClick={(e)=>{this.setFilter(0)}}><i className='mr-2 fe fe-shuffle'></i> Not Allocated</button>     
                                <button type="button" className={searchComponents.State==2?"form-control ml-3 btn-info":"form-control ml-3 btn-outline-info"}  onClick={(e)=>{this.setFilter(2)}}><i className='mr-2 fe fe-dollar-sign'></i> Quote</button>    
                                <button type="button" className={searchComponents.State==1?"form-control ml-3 btn-success":"form-control ml-3 btn-outline-success"} onClick={(e)=>{this.setFilter(1)}}><i className='mr-2 fe fe-check-circle'></i> Complete / Cut</button>         
                            </>):(<></>)}
                            <button type="button" className="form-control mr-3 btn-secondary float-right " onClick={this.clearSearch}><i className='mr-2 fe fe-check-cross'></i> Clear</button>          
                        </form>
                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
                <BottomScrollListener onBottom={this.atBottom} />;
                <button className="btn btn-info" style={{position: "fixed", bottom: "5px", right: "5px" }} onClick={()=>{window.scrollTo(0,0);}}><i className="fe fe-chevron-up"></i></button>
            </main>

            
        )
    }
}

export default Nests;