import React, { Component } from 'react';
import { BrowserRouter as Router,Route,Switch,Redirect } from "react-router-dom";

import {isMobile} from 'react-device-detect';
import Login from './Login/Login.js';
import Logout from './Login/Logout.js';
import Main from './System/Main';
import ServerNotActive from './ServerNotActive.js'
import './App.css';


class App extends Component {
  state = {
    debugMode: false,
    serverActive: true,
    socketActive: false,
    isLoggedIn: false,
    version: "1.0",
    devServer: "http://192.168.1.6:8079",
    //devServer: "http://127.0.0.1:8080",
    restServer:"",
    socketServer:""
  }
  databaseRequest = (url,method,body=null,upload=false) =>{

    let t = this;
    var DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    
    return new Promise(function(resolve,reject){
      var opts;
      if(!upload){
        opts = {
          method: method,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token'),
            'x-mobile': isMobile
          }
        };
      }
      else{
        opts = {
          method: method,
          headers: {
            'Accept': 'application/json',
            'x-auth-token': localStorage.getItem('token')
          }
        };
      }
      if(body!==null){
        opts.body=body;
      }
      let status = 0;
        fetch(`${DEV?t.state.devServer:t.state.restServer}/api${url}`,opts).then((response)=>{
        if(response.status!==200 && response.status!==404 && response.status!==502){
          t.setState({serverActive:false, socketActive: false});
        }
        if(response.status===401){
          throw response.status;
        }
        if(response.status===502){
          t.setState({debugMode: true});
        }
        if(response.status===200){
          //
        }
        status = response.status;
        return response.json();
      }).then((data) => {
        //t
        setTimeout(function(){
          data.status = status;
          resolve(data);
        }, 10);
        
      },(status)=>{
        reject(status);
        t.setState({isLoggedIn:false,serverActive:false});
      }).catch(function(e) {
        console.log(e);
        console.log("error");
      });
    });
  }
  onSetServerState = (ServerState) =>{
    let t = this;
    t.setState({serverActive:ServerState, socketActive: ServerState});
  }
  onSignIn = (loginState) =>{
    if(loginState !== this.state.isLoggedIn){
      this.setState({isLoggedIn:loginState});
    }
    
  }
  setPage = (page) =>{
    document.title = page + " | JSS v"+this.state.version;
  }
  componentDidMount() {
    let t = this;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        console.log("Dev code");
        t.setState({restServer:t.state.devServer,socketServer:t.state.devServer})
    } else {
        // production code
        console.log("Production code")
    }
  }
  serverNotActive = () =>{
    return (<React.Fragment>
        <h3 className="p-5">Server not active...</h3>
    </React.Fragment>)
  }
  render (){

    if(this.state.debugMode){
      return (
        <div className="container">
            Job Scheduling System is in debug mode
            <br/>
            Try again later
        </div>);
    }
    else{
      return (
        <Router>
          <Switch>
            
            {!this.state.serverActive && <Route exact path="/500" component={(props) => this.state.serverActive?(<Redirect to="/home"/>):(<ServerNotActive onSetServerState={this.onSetServerState} setPage={this.setPage} {...props} socketServer={this.state.socketServer} databaseRequest={this.databaseRequest} googleLoaded={this.googleLoaded} />)} />}
            {this.state.serverActive && <Route exact path="/500" component={() => (<Redirect to="/login"/>)} />}
            {!this.state.serverActive && <Route component={() => (<Redirect to="/500"/>)} />}
  
  
            {/*true?(<Route exact path="/purchasing/:PurchaseOrderId/print" render={({match})=>(<PurchaseOrderEditPrint  databaseRequest={this.databaseRequest} {...match} {...props} />)}/>):(<Redirect to="/home"/>)*/}
  
            {this.state.isLoggedIn && <Route exact path="/logout" component={()=>(<Logout onSignIn={this.onSignIn} databaseRequest={this.databaseRequest} />)}/>}
            {this.state.isLoggedIn && <Route exact path="/login" component={() => (<Redirect to="/home"/>)} />}  
            {this.state.isLoggedIn && <Route render={(props) => (<Main setPage={this.setPage} {...props} socketServer={this.state.socketServer} restServer={this.state.restServer} databaseRequest={this.databaseRequest} googleLoaded={this.googleLoaded} getGoogleState={this.getGoogleState}/>)} />}
            <Route exact path="/logout" component={()=>(<Redirect to="/login"/>)}/>
            <Route exact path="/login" component={()=>(<Login setPage={this.setPage} onSignIn={this.onSignIn} databaseRequest={this.databaseRequest} signOut={false}/>)}/>
            <Route component={()=>(<Login setPage={this.setPage} onSignIn={this.onSignIn} databaseRequest={this.databaseRequest} signOut={false}/>)}/>
  
  
  
          </Switch>
        </Router>
    );
    }
}
}
export default App;

