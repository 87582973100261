import React, { Component } from 'react';

class TimeAgo extends Component {
    timeAgo = (elapsed) => {
        var d = new Date().getTime();
        elapsed = d - elapsed;
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerWeek = msPerHour * 24*7;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;
        if (elapsed < msPerMinute) {
            return Math.round(elapsed/1000) + ' seconds ago';   
        }

        else if (elapsed < msPerHour) {
            return Math.round(elapsed/msPerMinute) + ' minute' + (Math.round(elapsed/msPerMinute)>1?'s':'')+' ago';   
        }

        else if (elapsed < msPerDay ) {
            return Math.round(elapsed/msPerHour ) + ' hour' + (Math.round(elapsed/msPerHour)>1?'s':'')+' ago';   
        }

        else if (elapsed < msPerWeek) {
            return Math.round(elapsed/msPerDay) + ' day' + (Math.round(elapsed/msPerDay)>1?'s':'')+' ago';   
        }
        else if (elapsed < msPerMonth) {
            return Math.round(elapsed/msPerWeek) + ' week' + (Math.round(elapsed/msPerWeek)>1?'s':'')+' ago';   
        }
        else if (elapsed < msPerYear) {
            return  Math.round(elapsed/msPerMonth) + ' months ago';   
        }

        else {
            return Math.round(elapsed/msPerYear ) + ' years ago';   
        }
    }
    timeAgoDay = (datetime) => {
        var date = new Date(datetime);
        
        var now = new Date();
        var yesterday = new Date(now - 86400000);
        if((now.getTime() - date.getTime()) < 86400000 && now.getDay()==date.getDay()){
            return 'Today';
        }

        if(yesterday.getDate()==date.getDate() && yesterday.getMonth()==date.getMonth() && yesterday.getFullYear()==date.getFullYear()){
            return 'Yesterday';
        }
        if((now.getTime() - date.getTime()) < 86400000 * 4){
            switch(date.getDay()){
                case 0: return "Sunday";
                case 1: return "Monday";
                case 2: return "Tuesday";
                case 3: return "Wednesday";
                case 4: return "Thursday";
                case 5: return "Friday";
                case 6: return "Saturday";
            }
        }
        return this.timeAgo(datetime)
    }
    render (){
        const {lowRes} = this.props;
        if(lowRes){
            return this.timeAgo(this.props.time)
        }

        return this.timeAgoDay(this.props.time)
        
    }
}

export default TimeAgo;