import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import queryString from "query-string"

import ReactToPrint from 'react-to-print';
import LaserJobsPrint from './LaserJobsPrint.js'
import TimeAgo from '../../Components/TimeAgo.js';

class LaserJobs extends Component {
    state = {
        loading: true,
        editing: false,
        socket: null,
        redirect: "",
        processes: [
            { id: 0, name: "Drafting", selected: false},
            { id: 1, name: "Material Prep.", selected: false},
            { id: 2, name: "Laser", selected: false},
            { id: 3, name: "Turning", selected: false},
            { id: 4, name: "Milling", selected: false},
            { id: 5, name: "Grinding", selected: false},
            { id: 6, name: "Fitting", selected: false},
            { id: 7, name: "Folding", selected: false},
            { id: 8, name: "Pressing", selected: false},
            { id: 9, name: "Welding", selected: false},
            { id: 10, name: "Supply", selected: false},
            { id: 11, name: "Hypermill", selected: false}
        ],
        jobModal:{
            Id: -1,
            Loading: false,
            OperatorName: "",
            JobName: "",
            steps: [],
            details: {
                ProcessGuid: ""
            },
            uploads: []
        }
    }
    redirect = (url)=>{
        this.setState({redirect: url});
    }
    loadModal = async (operator, job)=>{
        const {databaseRequest}=this.props;
        var {jobModal} = this.state;
        jobModal.Id=job;
        jobModal.JobName = this.JobName(job);

        var JobListing = this.Job(job);
        var uploadList = [];
        var uploads = [];
        try{
            uploadList = JSON.parse(JobListing.Uploads);      
            for(let i=0; i<uploadList.length; i++){
                var result = await databaseRequest(`/upload/${uploadList[i]}`,"GET");
                if(result.length==1){
                    uploads.push(result[0]);
                }
            }
        }
        catch(e){
            console.log(e);
        }


        jobModal.uploads = [];
        for(let i=0; i<uploads.length; i++){
            if(uploads[i].Filename.toLowerCase().indexOf(".dxf") !=-1 || uploads[i].Filename.toLowerCase().indexOf(".dwg") !=-1){
                jobModal.uploads.push(uploads[i]);
            }
        }


        jobModal.Loading = true;
        this.setState({jobModal:jobModal});
        jobModal.steps = await databaseRequest(`/jobs/processes/${job}`,"GET");
        for(var k=0; k<jobModal.steps.length; k++){
            jobModal.steps[k].descShown=jobModal.steps[k].description.length>0
        }
        
        jobModal.details = await databaseRequest(`/schedule/${job}/${operator}`,"GET");

        jobModal.Loading = false;
        this.setState({jobModal:jobModal});
    }
    stepDescription=(i)=>{
        const {steps} = this.state.jobModal;
        if (i > -1 && i<steps.length) {
            return steps[i].description;
        }
    }
    toggleCompletion = (i)=>{
        var {jobModal} = this.state;
        if (i > -1 && i<jobModal.steps.length) {
            jobModal.steps[i].complete=!jobModal.steps[i].complete;
        }
        this.setState({
            jobModal:jobModal
        });
    }
    saveBtn = (JobId) =>{
        const {databaseRequest}=this.props;
        let t = this;
        this.setState({
            loading: true
        });
        var req = {
            url: `/jobs/processes/${JobId}`,
            method: "PUT"
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(this.state.jobModal.steps)
        ).then((data)=>{
            this.reload();
        });
    }
    saveTarget = () =>{
        const {databaseRequest}=this.props;
        let t = this;
        this.setState({
            loading: true
        });
        var req = {
            url: `/jobs/processes`,
            method: "PUT"
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(this.state.ProcessList)
        ).then(()=>{
            this.reload(true);
            this.setState({editing: false});
            this.componentRef.reload(false)
        });
    }
    editMode = (e)=>{
        this.setState({editing: e});
        this.reload(true);
    } 
    reload = async (loading)=>{
        const {setPage, databaseRequest}=this.props;
        let t =this;
        setPage(`Schedule`);
        this.setState({loading: loading});
        var Companies = await databaseRequest("/companies","GET");
        var Operators = await databaseRequest("/operators?Id=0","GET");
        var Contacts = await databaseRequest("/contacts","GET");
        var Jobs = await databaseRequest("/jobs?CompletedDateTime=-1","GET");
        var ProcessList = await databaseRequest("/jobs/processes?OperatorId=0&Complete=0","GET");
        var Users = await databaseRequest("/users","GET");

        databaseRequest(`/schedule`,"GET").then((data)=>{
            if(typeof data.error !== 'undefined'){
                t.setState({
                    loading: false,
                    Operators:Operators,
                    Companies:Companies,
                    Contacts:Contacts,
                    Jobs:Jobs,
                    Users:Users,
                    ProcessList:ProcessList
                })
            }
            else{
                t.setState({
                    loading: false,
                    data: data,
                    Operators:Operators,
                    Companies:Companies,
                    Contacts:Contacts,
                    Jobs:Jobs,
                    Users:Users,
                    ProcessList:ProcessList
                })
            }
        });
    }
    componentDidMount() {
        let t=this;
        const {socketServer, setPage} = this.props;
        setPage("Laser Jobs");
        /*t.socket = io(socketServer);
        t.socket.on('connect', ()=>{
            t.socket.on('server-update', ()=>{
                if(!t.state.editing){
                    t.reload(false);
                }  
            });
        });*/
        this.reload(true);
    }
    componentWillUnmount(){
        let t=this;
        if(t.socket!=null){
            t.socket.disconnect();
        }
    }
    UserName = (e) =>{
        const {Users} = this.state;
        for(let i=0; i<Users.length; i++){
            if(Users[i].Id===e){
                return Users[i].Name;
            }
        }
        return " - ";
    }
    bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
     }
    TargetProcess = (e) =>{
        const {ProcessList} = this.state;
        for(let i=0; i<ProcessList.length; i++){
            if(ProcessList[i].JobId===e){
                return ProcessList[i].TargetProcess;
            }
        }
        return 0;
    }
    toggleTargetProcess = (OperatorId, JobId) =>{
        var {ProcessList} = this.state;
        for(let i=0; i<ProcessList.length; i++){
            if(ProcessList[i].JobId===JobId && ProcessList[i].OperatorId===OperatorId){
                ProcessList[i].TargetProcess = !ProcessList[i].TargetProcess;
            }
        }
        this.setState({ProcessList: ProcessList});
    }
    ContactName = (e)=>{
        const {Contacts} = this.state;
        for(let i=0; i<Contacts.length; i++){
            if(Contacts[i].Id===e){
                return (Contacts[i].Name);
            }
        }
        return "";
    }
    CompanyName = (e)=>{
        const {Companies} = this.state;
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===e){
                return (Companies[i].Name);
            }
        }
        return "";
    }
    OperatorName = (e)=>{
        const {Operators} = this.state;
        for(let i=0; i<Operators.length; i++){
            if(Operators[i].Id===e){
                return (Operators[i].Name);
            }
        }
        return "";
    }
    OperatorFirstName = (e)=>{
        const {Operators} = this.state;
        for(let i=0; i<Operators.length; i++){
            if(Operators[i].Id===e){
                return (Operators[i].Name).split(' ')[0];
            }
        }
        return "";
    }
    ProcessName = (e)=>{
        const {processes} = this.state;
        for(let i=0; i<processes.length; i++){
            if(processes[i].id===e){
                return (processes[i].name);
            }
        }
        return "";
    }
    Job = (e) =>{
        const {Jobs} = this.state;
        for(let i=0; i<Jobs.length; i++){
            if(Jobs[i].Id===e){
                return Jobs[i];
            }
        }
    }
    JobName = (e) =>{
        const {Jobs} = this.state;
        var JobName = "";
        var JobUrgent = false;
        for(let i=0; i<Jobs.length; i++){
            if(Jobs[i].Id===e){
                JobUrgent = Jobs[i].IsUrgent;
                if(Jobs[i].CompanyId===-2){
                    JobName = `[${this.ContactName(Jobs[i].ContactId)}] ${Jobs[i].Name}`;
                }
                else{
                    JobName = `[${this.CompanyName(Jobs[i].CompanyId)}] ${Jobs[i].Name}`;
                }
                
            }
        }
        var len = 1000;
        if(JobName.length > len){
            JobName = JobName.substr(0,len-3)+"...";
        }
        var TargetProcess = this.TargetProcess(e);
        if(TargetProcess){
            return (<span  className="rounded bg-info font-weight-bold text-white p-2">{this.state.editing?(<i className="fe fe-edit mr-2"></i>):(<React.Fragment></React.Fragment>)}{e} - {JobName}</span>);
        }
        if(JobUrgent){
            return (<span  className="rounded bg-danger font-weight-bold text-white p-2">{this.state.editing?(<i className="fe fe-edit mr-2"></i>):(<React.Fragment></React.Fragment>)}{e} - {JobName}</span>);
        }
        else{
            return (<span  className="font-weight-bold p-2">{this.state.editing?(<i className="fe fe-edit mr-2"></i>):(<React.Fragment></React.Fragment>)}{e} - {JobName}</span>);
        }
        
    }
    table = () =>{
        if(this.state.loading){
            return (
                <div className="w-100 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">Loading schedule...</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
        else{
            var Operators = this.state.Operators;
            var Processes = this.state.data;
            
            for(let i=0; i<Operators.length; i++){
                Operators[i].Jobs = [];
                for(let j=0; j<Processes.length; j++){
                    for(let k=0; k<Processes[j].Operators.length; k++){
                        if(Processes[j].Operators[k].OperatorId===Operators[i].Id){
                            Operators[i].Jobs = Operators[i].Jobs.concat(Processes[j].Operators[k].Jobs.Urgent);
                            Operators[i].Jobs = Operators[i].Jobs.concat(Processes[j].Operators[k].Jobs.NextInLine);
                            Operators[i].Jobs = Operators[i].Jobs.concat(Processes[j].Operators[k].Jobs.Muted);
                        }
                    }
                }
            }

        

            
            for(let i=0; i<Operators.length; i++){
                Operators[i].Jobs = this.arrayUnique(Operators[i].Jobs);                 
            }
            //copy jobs for laser only
            var jobs = Operators[0].Jobs;
            var OperatorId = Operators[0].Id;


            var table = [];
            
            var tableIndex = 0;
            table[tableIndex] = [];
            for(let i=0; i<jobs.length; i++){
                if(table[tableIndex].length > 4){
                    tableIndex++;
                    table[tableIndex] = [];
                }
                table[tableIndex].push(jobs[i]);
            }
            return (
                <table className="table table-striped w-100">
                <tbody>
                   {table.map((row, i)=>{
                            return ( 
                            <tr key={i}>
                                

                            {row.map((JobId, j)=>{
                                return(
                                <td key={j}>
                                    <span className="link" data-toggle={this.state.editing?"":"modal"} data-target="#jobModal" onClick={(e)=>{
                                        e.preventDefault();
                                        if(this.state.editing){
                                            this.toggleTargetProcess(OperatorId, JobId);
                                        }  
                                        else{
                                            this.loadModal(OperatorId, JobId);
                                        } 
                                        
                                        }} >
                                        {this.JobName(JobId)}
                                    </span>
                                </td>
                                )                       
                            })}
                                
                                </tr>);
                        })
                        }                
                    
 
     
                </tbody>
                </table>
            );
        }
    }
    arrayUnique = (array)=>{
        var a = array.concat();
        for(var i=0; i<a.length; ++i) {
            for(var j=i+1; j<a.length; ++j) {
                if(a[i] === a[j])
                    a.splice(j--, 1);
            }
        }
    
        return a;
    }
    steps = ()=>{
        const {jobModal,processes} = this.state;
        const {steps}=jobModal;
        return (
                <div style={{display: 'inline-block', width: '100%'}}>
                {steps.map((row, i) => {
                       return (

                        <div key={i} className={row.complete?"modal-item bg-success text-dark":row.guid===jobModal.details.ProcessGuid?("modal-item bg-warning text-dark"):("modal-item bg-secondary")}>
                            <div className="row">
                                <div className="col"><h3 className={row.guid===jobModal.details.ProcessGuid || row.complete?("text-dark mb-0 m-3"):("text-white mb-0 m-3")}>{i+1} - {this.ProcessName(row.process)}</h3></div>
                                <div className="col">
                                    {row.process!==2?(<React.Fragment></React.Fragment>):(<h3 className="text-right mb-0 m-3">
                                    <i className={row.complete?"fe fe-minus-circle hover-dark mr-2":row.guid===jobModal.details.ProcessGuid?("fe fe-check-circle hover-dark mr-2"):("fe fe-check-circle hover mr-2")} onClick={()=>{this.toggleCompletion(i)}}></i>
                                    </h3>)}
                                </div>
                            </div> 
                            {row.descShown || row.description.length>0?(
                            <div className="row">
                            <div className="col pl-4">
                                <i className="fe fe-chevron-right"></i> {this.stepDescription(i)}
                            </div>
                            </div>     
                            ):(<React.Fragment></React.Fragment>)}                 
                        </div>
                        )                      
                })}
                </div>
            );
    } 
    render(){
        const access = localStorage.getItem("access");
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        const query = queryString.parse(this.props.location.search);
        var fullscreen = false;
        if(typeof query.fullscreen!=='undefined'){
            fullscreen = query.fullscreen==="true"?true:false;
        }
        return (
            <main role="main" className={fullscreen?"p-5":"p-5 pt-6"}>
                {fullscreen?(<React.Fragment></React.Fragment>):(
                    <React.Fragment>
                    <h2>
                        <i className="fe fe-list"></i> Laser Jobs
                    </h2>  
                    <div className="row">
                    <div className="col-mx-auto">
                    {Number(access) > 15?(
                        <React.Fragment>    
                        {!this.state.editing?(
                        <button type="button" className="btn btn-primary mb-3" onClick={()=>{this.editMode(true)}}>Todays Cuts</button>
                        ):(
                            <React.Fragment>    
                                <button type="button" className="btn btn-success mb-3 mr-2" onClick={()=>{this.saveTarget()}}>Save</button>  
                                <button type="button" className="btn btn-secondary mb-3" onClick={()=>{this.editMode(false)}}>Cancel</button>  
                            </React.Fragment>
                        )}
                        </React.Fragment>
                        ):(<React.Fragment></React.Fragment>)}
                    </div>
                    <div className="col">
                    <ReactToPrint
                        trigger={() => {
                            return <button type="button" disabled={this.state.editing} className="btn btn-secondary float-right ml-2 mb-3">Print</button>
                        }}
                        content={() => this.componentRef}
                    />
                    <div style={{ display: "none" }}><LaserJobsPrint {...this.props} ref={el => (this.componentRef = el)} /></div>
                    </div>
                    </div>
                </React.Fragment>
                )}

                {this.table()}
                <div className="modal" id="jobModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title">{this.state.jobModal.JobName}</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {!this.state.jobModal.Loading?(
                            <React.Fragment>
                                <div className="modal-body">                                
                                    {this.steps()}   
                                    {this.state.jobModal.uploads.length>0?(
                                    <div>
                                        <h2 className="mt-3 text-center">Associated Files</h2>
                                        <table className="table table-striped ">
                                            <thead className="bg-warning">
                                                <tr>
                                                <th scope="col" className="text-left bg-secondary text-white">File Name</th>
                                                <th scope="col" className="text-left bg-secondary text-white">Uploaded By</th>
                                                <th scope="col" className="text-left bg-secondary text-white">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.jobModal.uploads.map((file, i)=>{
                                                    return (
                                                    <tr key={i}>
                                                        <td><a href={this.props.restServer+"/api/download/"+file.Guid+""} target="_blank" >{file.Filename}</a></td>
                                                        <td>{this.UserName(file.UserId)}</td>
                                                        <td><TimeAgo time={file.DateTime}/>  ({new Date(file.DateTime).toLocaleString()})</td>
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </table>   
                                    </div> 
                                    ):(<React.Fragment></React.Fragment>)}                           
                                </div>
                                <div className="modal-footer">
                                    {Number(access) > 15?(<button type="button" className="btn btn-info float-left" data-dismiss="modal" onClick={()=>{this.redirect(`/jobs/${this.state.jobModal.Id}?return=${this.props.location.pathname}`)}}>Job Sheet</button>):
                                    (<React.Fragment></React.Fragment>)}
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>{this.saveBtn(this.state.jobModal.Id)}}>OK</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                                </div>
                            </React.Fragment>
                        ):(
                            <div className="modal-body">
                                <p>Loading...</p>
                            </div>                      
                        )}
                        </div>
                    </div>
                </div>
            </main>         
        )
    }
}

export default LaserJobs;