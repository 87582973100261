import React, { Component } from 'react';


class OperatorViewPrint extends Component {
    state = {
        loading: true,
        Operators: null,
        Jobs: [],
        processes: [
            { id: 0, name: "Drafting", selected: false },
            { id: 1, name: "Material Prep.", selected: false },
            { id: 2, name: "Laser", selected: false },
            { id: 3, name: "Turning", selected: false },
            { id: 4, name: "Milling", selected: false },
            { id: 5, name: "Grinding", selected: false },
            { id: 6, name: "Fitting", selected: false },
            { id: 7, name: "Folding", selected: false },
            { id: 8, name: "Pressing", selected: false },
            { id: 9, name: "Welding", selected: false },
            { id: 10, name: "Supply", selected: false},
            { id: 11, name: "Hypermill", selected: false}
        ],
    }
    ProcessName = (e) => {
        const { processes } = this.state;
        for (let i = 0; i < processes.length; i++) {
            if (processes[i].id === e) {
                return (processes[i].name);
            }
        }
        return "";
    }
    CompanyName = (e)=>{
        const {Companies} = this.state;
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===e){
                return (Companies[i].Name);
            }
        }
        return "";
    }
    ContactName = (e)=>{
        const {Contacts} = this.state;
        for(let i=0; i<Contacts.length; i++){
            if(Contacts[i].Id===e){
                return (Contacts[i].Name);
            }
        }
        return "";
    }
    JobName = (e) =>{
        const {Jobs} = this.state;
        var JobName = "";
        var JobUrgent = false;
        var Client = ""
        var Name = "";
        for(let i=0; i<Jobs.length; i++){
            if(Jobs[i].JobId===e){
                JobUrgent = Jobs[i].IsUrgent===1;
                Name = Jobs[i].Name;
                if(Jobs[i].CompanyId===-2){
                    Client = this.ContactName(Jobs[i].ContactId);
                    JobName = `[${this.ContactName(Jobs[i].ContactId)}] ${Jobs[i].JobName}`;
                }
                else{
                    Client = this.CompanyName(Jobs[i].CompanyId);
                    JobName = `[${this.CompanyName(Jobs[i].CompanyId)}] ${Jobs[i].JobName}`;
                }
            }
        }
        if(JobUrgent){
            return (<span  className="rounded bg-danger font-weight-bold text-white p-1">{JobName}</span>);
        }
        else{
            return (<span  className="font-weight-bold p-1">{JobName}</span>);
        }
        
    }
    componentDidMount = async () => {
        const { databaseRequest, params } = this.props;
        let t = this;

        var Operators = await databaseRequest(`/operators?Id:not=0`, "GET");
        var Jobs = await databaseRequest(`/schedule/operator-processes`, "GET");
        var Companies = await databaseRequest("/companies","GET");
        var Contacts = await databaseRequest("/contacts","GET");
        t.setState({
            Operators: Operators,
            Jobs: Jobs,
            Companies: Companies,
            Contacts: Contacts,
            loading: false,
        })
    }
    table = (e) => {
        return (
            <table className="table table-bordered">
                <thead className="text-black">
                    <tr>
                        <th scope="col" className="text-black">#</th>
                        <th scope="col" className="text-black">Job Name</th>
                        <th scope="col" className="text-black">Process</th>
                        <th scope="col" className="text-black">Hours Spent On Task</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.Jobs.map((row, i) => {
                        if (row.OperatorId === e) {
                            return (
                                <tr key={i}>
                                    <th scope="row">{1 + i}</th>
                                    <td>{this.JobName(row.JobId)}
                                        <br /><p>{row.Description}</p></td>
                                    <td>{this.ProcessName(row.Process)}</td>
                                    <td style={{ width: '25%' }}><h1>____________<small> hour(s)</small></h1> </td>
                                </tr>)
                        }
                        else {
                            return (<React.Fragment key={i}></React.Fragment>)
                        }
                    })}
                </tbody>
            </table>
        );
    }
    OperatorsList = () => {
        const { Operators } = this.state;
        return (
            <React.Fragment>
                {Operators.map((Operator, i) => {
                    return (
                    <React.Fragment key={i}>
                        <div className="page-break"/>
                        <div className="row form-group">
                            <div className="col p-0 pt-3">
                                <h1 className="mb-0 text-black"><i className="fe fe-user-check"></i> {Operator.Name}</h1>
                                <small className="text-black">Printed: {new Date(new Date().getTime()).toLocaleString()}</small>
                            </div>
                            <div className="col-auto p-0">
                                <img src="/logo-full.svg" alt="" width="150" height="70" />
                            </div>
                        </div>
                        <div className="row form-group">
                            {this.table(Operator.Id)}
                        </div>
                        
                    </React.Fragment>
                    );
                })}
            </React.Fragment>)
    }
    render() {

        if (this.state.loading) {
            return (
                <div className="w-100 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">Loading operator schedule...</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        return (
            <div className="p-5">
                {this.OperatorsList()}

            </div>)
    }

}

export default OperatorViewPrint;