import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import queryString from "query-string"
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { ServerConnection } from '../../Components/ServerConnection.js';

import {UpcomingCalendar} from './Calendar.js'

class Home extends Component {
    
    state = {
        loading: true,
        editing: false,
        redirect: "",
        ActiveOperators: 0,
        tableEarlyEntries: [],
        tableLastEntries: [],

        newJobActivity:{
            thisWeekTotal: -1,
            lastWeekTotal: -1,
            percentage: null,
        },
        activeJobs:{
            thisWeekTotal: -1,
            lastWeekTotal: -1,
            percentage: null,
        },
        outstandingJobs:{
            outstanding: -1,
            all: -1,
            percentage: 0,
            dueInNextWeek: -1,
            requiringQuote: -1,
            requiringScheduling: -1
        },        
        options26Weeks: {
            title: {
                text: 'Job Activity Last 12 Months',
                style: {
                    color: '#95aac9',
                    letterSpacing: '0.08em',
                    textTransform: 'uppercase'
                }    
            },
            yAxis: {
                title: {
                    text: 'No. Of Jobs',
                    style: {
                        color: '#95aac9',
                        letterSpacing: '0.08em',
                        textTransform: 'uppercase'
                    }  
                }
            },
            xAxis: { 
                min:Date.UTC(2020, 0, 0),
                max:Date.UTC(2020, 12, 1),
                allowDecimals: false,
                type: 'datetime',
                tickInterval: 24 * 3600 * 1000*7, 
                labels: {
                    rotation : 90
                },
                crosshair: true
            },
            tooltip: {
                formatter: function() {
                    return  '<b>Week:'+ this.point.w+ '</b><br/>' + this.y +' '+ this.series.name.slice(0, -1).toLowerCase()+(this.y>1?"s":"");
                }
            },
            credits: {
                enabled: false
            },
        
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                style: {
                    color: '#95aac9',
                    letterSpacing: '0.08em',
                    textTransform: 'uppercase'
                }  
            },

            series: [],
            data: {
                dateFormat: 'dd/mm/YYYY',
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        
        },
        optionsProcessPie:{
          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            labelFormatter: function () {
                return `${this.name} (${this.y})` ;
            }
          },
          title: {
            text: 'Active Processes',
            style: {
                color: '#95aac9',
                letterSpacing: '0.08em',
                textTransform: 'uppercase'
            }    
         },
         credits: {
            enabled: false
         },
         tooltip: {
             style:{
                 cursor: 'pointer'
             },
            formatter: function() {
                console.log(this);
                return  '<b>'+ this.point.name+ '</b><br/>' + this.y +' process'+(this.y>1?"es":"");
            }
        },
          series: [{
            type: 'pie',
            size: '100%',
            innerSize: '80%',
            showInLegend: true,
            dataLabels: {
                enabled: false
            },
            data: []
          }]
        },
        optionsLeadTime:{      
            chart: {
                type: 'column',
            },

            title: {
                text: 'Lead Time'
            },
            credits: {
                enabled: false
             },
            xAxis: {
                categories: [
                    'Same Day',
                    'Within 1 Day',
                    'Within 1 Week',
                    'Within 2 Weeks',
                    'Within 1 Month',
                    'Within 2 Months',
                    'Within 6 Months',
                    'Within 1 Year'
                ],
                crosshair: true
            },
            yAxis: [{
                title: {
                    text: 'Duration (Days)'
                }
            }],

            plotOptions: {
                column: {
                    borderRadius: 5
                }
            },

            series: [{
                name: "No. Of Jobs",
                data: []
            }]
        }
    }
    toAustraliaDate = (d) => {
        return d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();
    }
    loadCharts =  () =>{
        let t = this;
        const {databaseRequest} = this.props;
        var {options26Weeks, activeJobs, optionsProcessPie, optionsLeadTime} = this.state;
        return new Promise(async function(resolve,reject){    
            var results =  await databaseRequest("/highcharts/last26weeks","GET");

            optionsProcessPie.series[0].data =  await databaseRequest("/highcharts/processlist","GET");

            var leadtime = await databaseRequest("/highcharts/leadtime","GET");

            var leadtimes = [0,0,0,0,0,0,0,0];
            for(let i=0; i<leadtime.length; i++){
                if(leadtime[i].Duration <= 86400000){
                    leadtimes[0]++;
                }
                else if(leadtime[i].Duration > 86400000 && leadtime[i].Duration <= (86400000*2)){
                    leadtimes[1]++;
                }
                else if(leadtime[i].Duration > (86400000*2) && leadtime[i].Duration <= (86400000*7)){
                    leadtimes[2]++;
                }
                else if(leadtime[i].Duration > (86400000*7) && leadtime[i].Duration <= (86400000*14)){
                    leadtimes[3]++;
                }
                else if(leadtime[i].Duration > (86400000*14) && leadtime[i].Duration <= (86400000*31)){
                    leadtimes[4]++;
                }
                else if(leadtime[i].Duration > (86400000*31) && leadtime[i].Duration <= (86400000*62)){
                    leadtimes[5]++;
                }
                else if(leadtime[i].Duration > (86400000*62) && leadtime[i].Duration <= (86400000*183)){
                    leadtimes[6]++;
                }
                else if(leadtime[i].Duration > (86400000*183) && leadtime[i].Duration <= (86400000*366)){
                    leadtimes[7]++;
                }
            }

            optionsLeadTime.series[0].data = leadtimes;
            var createdJobs = [];
            var activeJobs = [];
            var completedJobs = [];
            var categories = [];

            for(let i=0; i<results.length; i++){
                categories.push(results[i].startOfWeek);
            
                createdJobs.push({
                    x: new Date(results[i].startOfWeek),
                    y: results[i].createdJobs,
                    w: results[i].week
                });
                activeJobs.push({
                    x: new Date(results[i].startOfWeek),
                    y: results[i].activeJobs,
                    w: results[i].week
                });
                completedJobs.push({
                    x: new Date(results[i].startOfWeek),
                    y: results[i].completedJobs,
                    w: results[i].week
                });
            }
            options26Weeks.xAxis.min = results[0].startOfWeek;
            options26Weeks.xAxis.max = results[results.length-1].startOfWeek;

            activeJobs.thisWeekTotal = results[results.length-1].activeJobs;
            activeJobs.lastWeekTotal = results[results.length-2].activeJobs;
            activeJobs.percentage = activeJobs.lastWeekTotal!=0?(((activeJobs.thisWeekTotal/activeJobs.lastWeekTotal)-1)*100):100

            options26Weeks.series.push({
                name: 'New Jobs',
                data: createdJobs.concat()
            })
            options26Weeks.series.push({
                name: 'Active Jobs',
                data: activeJobs.concat()
            })
            options26Weeks.series.push({
                name: 'Completed Jobs',
                data: completedJobs.concat()
            })
            t.setState({options26Weeks:options26Weeks,optionsProcessPie:optionsProcessPie,activeJobs:activeJobs,optionsLeadTime:optionsLeadTime});
            resolve();
        });
    }
    
    loadCards=  () =>{
        let t = this;
        const {databaseRequest} = this.props;
        var {newJobActivity, outstandingJobs}  = this.state;
        return new Promise(async function(resolve,reject){    
            var results =  await databaseRequest("/highcharts/newjobactivity","GET");
            var outstanding =  await databaseRequest("/highcharts/outstanding","GET");
            outstandingJobs.outstanding = outstanding.outstanding;
            outstandingJobs.all = outstanding.all;
            outstandingJobs.percentage = outstanding.percentage;
            outstandingJobs.dueInNextWeek = outstanding.dueInNextWeek;
            outstandingJobs.requiringQuote = outstanding.requiringQuote;
            outstandingJobs.requiringScheduling = outstanding.requiringScheduling;
            outstandingJobs.requiringInvoicing = outstanding.requiringInvoicing;

            newJobActivity.thisWeekTotal = results.thisWeekTotal;
            newJobActivity.lastWeekTotal = results.lastWeekTotal;
            newJobActivity.percentage = results.percentage;


            t.setState({newJobActivity:newJobActivity, outstandingJobs:outstandingJobs, TodaysHours: results.todaysHours, ActiveOperators: results.activeOperators});
            resolve();
        });
    }

    loadTables = ()=>{
        let t = this;
        const {databaseRequest} = this.props;
        return new Promise(async function(resolve,reject){   
            var lastEntries =  await databaseRequest("/highcharts/last10Jobs","GET");
            var earlyEntries =  await databaseRequest("/highcharts/earliest10Jobs","GET");
            lastEntries.pop();
            t.setState({tableLastEntries:lastEntries, tableEarlyEntries: earlyEntries});

            resolve();
        });
    }
    async componentDidMount() {
        let t=this;
        const {socketServer, setPage} = this.props;
        setPage("Dashboard");

        /*const socket = io(socketServer);
        socket.on('connect', ()=>{
            socket.on('server-update', ()=>{
                this.setState({redirect: "/redirector?r=home"})
            });
        });*/
        await this.loadCharts();
        await this.loadCards();
        await this.loadTables();
        this.setState({loading: false});
    }
    cardNewJobs = () =>{
        const {newJobActivity} = this.state;
        return (                    
        <div className="col-12 col-md-3 col-md">
            <div className="card cursor-pointer" onClick={()=>{this.setRedirect("/jobs/-1/?return=/home")}}>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6 className="text-uppercase text-muted mb-2">New jobs this week</h6>
                            <span className="h2 mb-0">{newJobActivity.thisWeekTotal>-1?(newJobActivity.thisWeekTotal):"-"}</span>
                            {newJobActivity.percentage!=null?
                                newJobActivity.percentage > 0?
                            (<span className="badge badge-soft-success mt-n1 ml-2">{newJobActivity.percentage.toFixed(1)}% up from last week</span>) 
                                :
                                newJobActivity.percentage < 0?
                                (<span className="badge badge-soft-warning mt-n1 ml-2">{newJobActivity.percentage.toFixed(1)}% down on last week ({newJobActivity.lastWeekTotal>-1?(newJobActivity.lastWeekTotal):"-"})</span>) 
                                :(<span className="badge badge-soft-info mt-n1 ml-2">No change from last week</span>)
                            :(<React.Fragment></React.Fragment>)}
                        </div>
                        <div className="col-auto">
                            <span className="h2 fe fe-activity text-muted mb-0"></span>
                        </div>
                    </div>
                </div>
            </div>                  
        </div>);
    }
    cardActiveJobs = () =>{
        const {activeJobs} = this.state;
        return (                    
        <div className="col-12 col-md-3 col-md">
            <div className="card cursor-pointer" onClick={()=>{this.setRedirect("/jobs-active")}}>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6 className="text-uppercase text-muted mb-2">Currently Active Jobs</h6>
                            <span className="h2 mb-0">{activeJobs.thisWeekTotal>-1?(activeJobs.thisWeekTotal):"-"}</span>
                            {activeJobs.percentage!=null?
                                activeJobs.percentage > 0?
                            (<span className="badge badge-soft-success mt-n1 ml-2">{activeJobs.percentage.toFixed(1)}% up from last week</span>) 
                                :
                                activeJobs.percentage < 0?
                                (<span className="badge badge-soft-warning mt-n1 ml-2">{activeJobs.percentage.toFixed(1)}% down on last week ({activeJobs.lastWeekTotal>-1?(activeJobs.lastWeekTotal):"-"})</span>) 
                                :
                                (<span className="badge badge-soft-info mt-n1 ml-2">No change from last week</span>)  
                            :(<React.Fragment></React.Fragment>)}
                        </div>
                        <div className="col-auto">
                            <span className="h2 fe fe-clipboard text-muted mb-0"></span>
                        </div>
                    </div>
                </div>
            </div>                  
        </div>);
    }
    cardOutstandingJobs = () =>{
        const {outstandingJobs} = this.state;
        return (                    
        <div className="col-12 col-md-3 col-md">
            <div className="card cursor-pointer" onClick={()=>{this.setRedirect("/jobs-outstanding")}}>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6 className="text-uppercase text-muted mb-2">Jobs past due date</h6>
                            <span className="h2 mb-0">{outstandingJobs.outstanding>-1?(outstandingJobs.outstanding):"-"}</span>
                            {outstandingJobs.percentage > 0?(
                                <span className="badge badge-soft-danger mt-n1 ml-2">{outstandingJobs.percentage.toFixed(1)}% of all active jobs ({outstandingJobs.all>-1?(outstandingJobs.all):"-"})</span>
                            ):(
                                <span className="badge badge-soft-success mt-n1 ml-2">No jobs past due date</span>    
                            )}
                           
                        </div>
                        <div className="col-auto">
                            <span className="h2 fe fe-alert-circle text-muted mb-0"></span>
                        </div>
                    </div>
                </div>
            </div>                  
        </div>);
    }
    cardJobsDueNextWeek = () =>{
        const {outstandingJobs} = this.state;
        return (                    
        <div className="col-12 col-md-3 col-md">
            <div className="card">
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6 className="text-uppercase text-muted mb-2 w-100">Jobs due in the next 7 days</h6>
                            <span className="h2 mb-0">{outstandingJobs.dueInNextWeek>-1?(outstandingJobs.dueInNextWeek):"-"}</span>
                        </div>
                        <div className="col-auto">
                            <span className="h2 fe fe-eye text-muted mb-0"></span>
                        </div>
                    </div>
                </div>
            </div>                  
        </div>);
    }
    cardJobsNeedingAction = () =>{
        const {outstandingJobs} = this.state;
        return (                    
        <div className="col-12 col-md-3 col-md">
            <div className="card">
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6 className="text-uppercase text-muted mb-2">Jobs needing actioning</h6>
                            <span className="dashboard-actioning text-primary cursor-pointer" onClick={()=>{this.setRedirect("/jobs-quoting")}}>Quotes: {outstandingJobs.requiringQuote>0?(outstandingJobs.requiringQuote):"-"}</span>
                            <span className="dashboard-actioning text-warning cursor-pointer" onClick={()=>{this.setRedirect("/jobs-scheduling")}}>Scheduling: {outstandingJobs.requiringScheduling>0?(outstandingJobs.requiringScheduling):"-"}</span>
                            <span className="dashboard-actioning text-danger  cursor-pointer" onClick={()=>{this.setRedirect("/jobs-invoicing")}}>Invoicing: {outstandingJobs.requiringInvoicing>0?(outstandingJobs.requiringInvoicing):"-"}</span>
                        </div>
                        <div className="col-auto">
                            <span className="h2 fe fe-eye text-muted mb-0"></span>
                        </div>
                    </div>
                </div>
            </div>                  
        </div>);
    }
    cardActiveOperators = () =>{
        const {ActiveOperators} = this.state;
        return (                    
        <div className="col-12 col-md-3 col-md">
            <div className={ActiveOperators>0?"card cursor-pointer badge-soft-success":"card cursor-pointer"} onClick={()=>{this.setRedirect("/jobs-active")}}>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6 className="text-uppercase text-muted mb-2">Currently Active Operators</h6>
                            <span className="h2 mb-0">{ActiveOperators>0?(ActiveOperators):"-"}</span>
                        </div>
                        <div className="col-auto">
                            <span className="h2 fe fe-users text-muted mb-0"></span>
                        </div>
                    </div>
                </div>
            </div>                  
        </div>);
    }

    cardHoursRecordedToday = () =>{
        const {TodaysHours} = this.state;
        return (                    
        <div className="col-12 col-md-3 col-md">
            <div className="card cursor-pointer" onClick={()=>{this.setRedirect("/jobs-active")}}>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6 className="text-uppercase text-muted mb-2">Operator Hours Recorded Today</h6>
                            <span className="h2 mb-0">{this.secondsToTime(Math.floor(TodaysHours/1000))}</span>
                        </div>
                        <div className="col-auto">
                            <span className="h2 fe fe-clock text-muted mb-0"></span>
                        </div>
                    </div>
                </div>
            </div>                  
        </div>);
    }
    secondsToTime = (time)=>{
        var seconds = time%60;
        var minutes = ((time - seconds)/60)%60;
        var hours = (time-seconds-(minutes*60))/3600;
        

        return `${hours<10?"0"+hours:hours}:${minutes<10?"0"+minutes:minutes}:${seconds<10?"0"+seconds:seconds}`
    }
    setRedirect = (url) =>{
        this.setState({redirect:url})
    }
    render1(){
        if(this.state.redirect.length>1){
            this.props.history.push(this.props.location.pathname);
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        if(this.state.loading){
            return (
                <h1>Loading..</h1>
            )
        }
        const query = queryString.parse(this.props.location.search);
        var fullscreen = false;
        if(typeof query.fullscreen!=='undefined'){
            fullscreen = query.fullscreen==="true"?true:false;
        }

        const {options26Weeks, optionsProcessPie, optionsLeadTime} = this.state;
        return (
            <main role="main" className={fullscreen?"p-5":"p-5 pt-6"}>
                {fullscreen?(<React.Fragment></React.Fragment>):(<h2><i className="fe fe-trending-up"></i> Dashboard</h2>)}
                <div className="row">
                {this.cardNewJobs()}
                {this.cardActiveJobs()}
                {this.cardOutstandingJobs()}
                {this.cardJobsNeedingAction()}
                </div>

                <div className="row">
                    <div className="col-12 col-xl-8">
                        <div className="card">
                            <div className="card-body">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options26Weeks}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="card">
                            <div className="card-body">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={optionsLeadTime}
                            />
                            </div>
                        </div>
                    </div>         
                </div>
                <div className="row">
                    <div className="col-12 col-xl-4">
                        <div className="card">
                            <div className="card-body">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={optionsProcessPie}
                            />
                            </div>
                        </div>
                    </div> 
                    <div className="col-12 col-xl-4">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="text-uppercase text-muted text-center">Last 10 Jobs Entered</h3>
                                <table className="table">
                                    <thead>
                                        <tr><th>Job No.</th><th>Company</th><th>Name</th></tr>
                                    </thead>
                                    <tbody>
                                        {this.state.tableLastEntries.map((row, i)=>{
                                            return (
                                            <tr key={i}>
                                                <td className="p-2"><Link to={`/jobs/${row.Id}`}>{row.Id}</Link></td>
                                                <td className="p-2">{row.Name}</td>
                                                <td className="p-2">{row.JobName}</td>
                                            </tr>
                                            )
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>   
                    <div className="col-12 col-xl-4">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="text-uppercase text-muted text-center">10 Oldest Active Jobs</h3>
                                <table className="table mb-0">
                                    <thead>
                                        <tr><th>Job No.</th><th>Company</th><th>Name</th></tr>
                                    </thead>
                                    <tbody>
                                        {this.state.tableEarlyEntries.map((row, i)=>{
                                            return (
                                            <tr key={i}>
                                                <td className="p-2"><Link to={`/jobs/${row.Id}`}>{row.Id}</Link></td>
                                                <td className="p-2"><Link to={`/jobs/${row.Id}`}>{row.Name}</Link></td>
                                                <td className="p-2"><Link to={`/jobs/${row.Id}`}>{row.JobName}</Link></td>
                                            </tr>
                                            )
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>  
                   
                </div>

                                    {/*
                {this.cardActiveOperators()}
                {this.cardHoursRecordedToday()}
                    */}
            </main>

            
        )
    }
    render(){
        const {databaseRequest, socketServer} = this.props;
        if(this.state.redirect.length>1){
            this.props.history.push(this.props.location.pathname);
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        if(this.state.loading){
            return (
                <h1>Loading..</h1>
            )
        }
        const query = queryString.parse(this.props.location.search);
        var fullscreen = false;
        if(typeof query.fullscreen!=='undefined'){
            fullscreen = query.fullscreen==="true"?true:false;
        }

        const {options26Weeks, optionsProcessPie, optionsLeadTime} = this.state;
        return (
            <main role="main" className={fullscreen?"p-5":"p-5 pt-6"}>
                {<ServerConnection serverURL={socketServer} serverUpdate={<Redirect to="/redirector?r=home" />}/>}
                {fullscreen?(<React.Fragment></React.Fragment>):(<h2><i className="fe fe-trending-up"></i> Dashboard</h2>)}
                <div className="row">
                {this.cardNewJobs()}
                {this.cardActiveJobs()}
                {this.cardOutstandingJobs()}
                {this.cardJobsNeedingAction()}
                </div>

                <div className="row">
                    <div className="col-12 col-xl-8">
                        <div className="card">
                            <div className="card-body">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options26Weeks}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4">
                    <div className="card">
                            <div className="card-body">
                                <h3 className="text-uppercase text-muted text-center">Last 10 Jobs Entered</h3>
                                <table className="table p-0 m-0">
                                    <thead>
                                        <tr><th>Job No.</th><th>Company</th><th>Name</th></tr>
                                    </thead>
                                    <tbody>
                                        {this.state.tableLastEntries.map((row, i)=>{
                                            return (
                                            <tr key={i}>
                                                <td className="p-2"><Link to={`/jobs/${row.Id}`}>{row.Id}</Link></td>
                                                <td className="p-2">{row.Name}</td>
                                                <td className="p-2">{row.JobName}</td>
                                            </tr>
                                            )
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>         
                </div>
                <div className="row">
                   <UpcomingCalendar databaseRequest={databaseRequest}/>
                   
                </div>

                                    {/*
                {this.cardActiveOperators()}
                {this.cardHoursRecordedToday()}
                    */}
            </main>

            
        )
    }
}

export default Home;