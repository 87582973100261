import React, { Component } from 'react';

class State extends Component {
    state = {
        states: [
            {id: 1, text: "Awaiting Quoting", class: "text-warning font-weight-bold"},
            {id: 3, text: "Quote Sent", class: "text-primary font-weight-bold"},
            {id: 4, text: "Awaiting Scheduling", class: "text-warning font-weight-bold"},
            {id: 5, text: "Scheduled", class: "text-warning font-weight-bold"},
            {id: 6, text: "Completed - Not Picked Up", class: "text-info font-weight-bold"},
            {id: 8, text: "Completed - Not Invoiced", class: "text-success font-weight-bold"},
            {id: 9, text: "Post Completion Modification", class: "text-danger font-weight-bold"},
            {id: 10, text: "Other", class: "text-primary font-weight-bold"},
            {id: 11, text: "Completed - Invoiced", class: "text-muted font-weight-bold"},
            {id: 20, text: "Cancelled", class: "text-muted font-weight-bold"},
        ]
    }
    getState = (state) =>{
        for(let i=0; i<this.state.states.length;i++){
            if(this.state.states[i].id===state){
                if(typeof this.props.color !== 'undefined'){
                    return (<div className={this.props.color}>{this.state.states[i].text}</div>);
                }
                return (<div className={this.state.states[i].class}>{this.state.states[i].text}</div>);
            }
        }
        return (<div className="">## State Missing ##</div>);
    }
    getStateOptions = () =>{
        return (
        <React.Fragment>
            {this.state.states.map((row, i) => {
                    if(row.id!==11){
                        return (<option key={i} value={row.id} className={row.class}>{row.text}</option>)
                    }
                    else{
                        return (<React.Fragment key={i}></React.Fragment>)
                    }
                    
            })}

        </React.Fragment>)

    }
    render (){
        if(typeof this.props.options!== 'undefined'){
            if(this.props.options){
                return this.getStateOptions(this.props.state);
            }
        }
        return this.getState(this.props.state);
    }
}

export default State;