import React, { useState, useCallback, useEffect } from 'react';
import io from "socket.io-client";
var socket = null;


export const DesktopConnection = ({isConnected, topic=""}) => {

  const [connectionStatus, setConnectionStatus] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    socket = io('http://127.0.0.1:8081', {reconnection: false, reconnectionDelay: 2000});
    console.log("Socket Established");
    
    setConnectionStatus(socket.connected);

    socket.on("server_error", (data) => {
      alert(data);
    });

    socket.on('connect', ()=>{
      console.log("Socket:"+ socket.connected);
      setConnectionStatus(socket.connected);

      socket.emit("request_features", (features)=>{
        if(typeof features!=='undefined'){
          setVisible(features.indexOf(topic)!=-1)
        }
      });
    });

    socket.on("disconnect", () => {
      console.log("Socket:"+ socket.connected);
      setConnectionStatus(socket.connected);
    });


    return ()=>{
      socket.off();
      socket.disconnect();
      console.log("Socket Killed");
    }

  }, []);
  
 

  function sendMessage(topic, data) {
    socket.emit(topic, data);
  }


  //return <></>
  return (<>{connectionStatus && visible?isConnected(sendMessage):<></>}</>)
};