import React, { Component } from 'react';
import {Link, useLocation} from "react-router-dom";
import OperatorViewPrint from './OperatorViewPrint.js';


import ReactToPrint from 'react-to-print';

class OperatorProcess extends Component {
    state = {
        loading: true,
        Operator: null,
        Jobs: [],
        processes: [
            { id: 0, name: "Drafting", selected: false},
            { id: 1, name: "Material Prep.", selected: false},
            { id: 2, name: "Laser", selected: false},
            { id: 3, name: "Turning", selected: false},
            { id: 4, name: "Milling", selected: false},
            { id: 5, name: "Grinding", selected: false},
            { id: 6, name: "Fitting", selected: false},
            { id: 7, name: "Folding", selected: false},
            { id: 8, name: "Pressing", selected: false},
            { id: 9, name: "Welding", selected: false},
            { id: 10, name: "Supply", selected: false},
            { id: 11, name: "Hypermill", selected: false}
        ],
    }
    ProcessName = (e)=>{
        const {processes} = this.state;
        for(let i=0; i<processes.length; i++){
            if(processes[i].id===e){
                return (processes[i].name);
            }
        }
        return "";
    }
    CompanyName = (e)=>{
        const {Companies} = this.state;
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===e){
                return (Companies[i].Name);
            }
        }
        return "";
    }
    ContactName = (e)=>{
        const {Contacts} = this.state;
        for(let i=0; i<Contacts.length; i++){
            if(Contacts[i].Id===e){
                return (Contacts[i].Name);
            }
        }
        return "";
    }
    JobName = (e)=>{
        const {Jobs} = this.state;
        for(let i=0; i<Jobs.length; i++){
            if(Jobs[i].Id===e){
                return (<React.Fragment>[{this.CompanyName(Jobs[i].CompanyId)}] {Jobs[i].Name}</React.Fragment>);
            }
        }
        return "";
    }
    onHoursChange = (e, i) => {
        let Jobs = this.state.Jobs;
        if(i>=0 && i<Jobs.length){
            Jobs[i].ActualHours = Number(e.target.value);
        }
  
        this.setState({ Jobs: Jobs});  
    };
    saveBtn = () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        this.setState({
            loading: true,
        });
        var Jobs = this.state.Jobs;
        var req = {
            url: `/schedule/operator-processes`,
            method: "PUT"
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(Jobs)
        ).then((data)=>{
            t.setState({
                loading: false,
                loadingMsg: "Loading process...",
                redirect: "/schedule/operator-processes",
                data: data
            });
        });
    }
    
    componentDidMount = async () =>{
        const {databaseRequest,setPage, params}=this.props;
        let t =this;
        
        var Operator = await databaseRequest(`/Operators/${params.OperatorId}`,"GET");
        var Jobs = await databaseRequest(`/schedule/operator-processes?OperatorId=${params.OperatorId}`,"GET");
        
        var Companies = await databaseRequest("/companies","GET");
        var Contacts = await databaseRequest("/contacts","GET");

        setPage(`Operator Processes - ${Operator.Name}`);
        t.setState({
            Companies: Companies,
            Contacts: Contacts,
            Operator:Operator,
            Jobs:Jobs,
            loading: false,
        })
    }
    JobName = (e) =>{
        const {Jobs} = this.state;
        var JobName = "";
        var JobUrgent = false;
        var Client = ""
        var Name = "";
        for(let i=0; i<Jobs.length; i++){
            if(Jobs[i].JobId===e){
                JobUrgent = Jobs[i].IsUrgent===1;
                Name = Jobs[i].Name;
                if(Jobs[i].CompanyId===-2){
                    Client = this.ContactName(Jobs[i].ContactId);
                    JobName = `[${this.ContactName(Jobs[i].ContactId)}] ${Jobs[i].JobName}`;
                }
                else{
                    Client = this.CompanyName(Jobs[i].CompanyId);
                    JobName = `[${this.CompanyName(Jobs[i].CompanyId)}] ${Jobs[i].JobName}`;
                }
            }
        }

        if(JobUrgent){
            return (<span  className="rounded bg-danger font-weight-bold text-white p-1">{JobName}</span>);
        }
        else{
            return (<span  className="font-weight-bold p-1">{JobName}</span>);
        }
        
    }
    table = () => {
        const {Operator} = this.state;

        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading operator schedule...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                );
            }
            else{
                return (
                <React.Fragment>
                    
                    <div className="row form-group">
                        <div className="col p-0 pt-3">
                            <h1 className="mb-0"><i className="fe fe-user-check"></i> {Operator.Name}</h1>
                            <small className="text-muted">As of: {new Date(new Date().getTime()).toLocaleString()}</small>
                        </div>
                        <div className="col p-0">
                           
                           
                            <button type="button" onClick={()=>{this.props.history.goBack()}} className="btn btn-info float-right ml-4">Back</button>
                            <ReactToPrint
                                trigger={() => {
                                    return <button type="button" className="btn btn-secondary float-right ml-4">Print Version</button>
                                }}
                                content={() => this.componentRef}
                                />
                                <div style={{ display: "none" }}><OperatorViewPrint {...this.props} OperatorId={this.props.params.OperatorId} ref={el => (this.componentRef = el)} /></div>
                        </div>
                    </div>

                    <div className="row">

                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Job No.</th>
                        <th scope="col">Job Name</th>
                        <th scope="col">Process</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.Jobs.map((row, i) => {
                        return (
                        <tr key={i} >
                            <th scope="row" ><Link to={`/jobs/${row.JobId}`}>{1+i}</Link></th>
                            <td><Link to={`/jobs/${row.JobId}?return=${this.props.location.pathname}`}>{row.JobId}</Link></td>
                            <td><Link to={`/jobs/${row.JobId}?return=${this.props.location.pathname}`}>{this.JobName(row.JobId)}</Link>
                            <br/><p>{row.Description}</p></td>
                            <td>{this.ProcessName(row.Process)}</td>                       
                        </tr>)
                    })}
                    </tbody>
                    </table>

                    </div>
                </React.Fragment>
                );
            }
    }
    render(){
        return (
            <main role="main" className="container">
                {this.table()}
            </main>

            
        )
    }
}

export default OperatorProcess;