import React, { Component } from 'react';
import {Link} from "react-router-dom";
import State from '../../Components/State.js';
import TimeAgo from '../../Components/TimeAgo.js';
import DaysToGo from '../../Components/DaysToGo.js';
import {PortraitOrientation} from '../../PortraitOrientation.jsx'


class Jobs extends Component {
    state = {
        loading: true,
        search: "",
        data: [],
        Companies: [],
        query: "",
        title: "All"
    }
    async componentDidMount(){
        const {databaseRequest,setPage,view}=this.props;
        let t =this;
        setPage(`Jobs`);
        var query = "";
        var title = "";
        var icon = "";
        var d = new Date().getTime();

        if(view==="all"){query="State:not=-1";title="All";icon=""}
        if(view==="active"){query="State:not=8&state:not=11";title="Active";icon="minus-circle"}
        if(view==="outstanding"){query=`State:not=8&state:not=11&DueDateTime:<=${d}`;title="Outstanding";icon="x-circle"}
        
        if(view==="workshop"){query="State=5";title="Workshop";icon="activity"}
        if(view==="quoting"){query="State=1";title="Requires Quoting";icon="activity"}
        if(view==="scheduling"){query="State=4";title="Requires Scheduling";icon="activity"}
        if(view==="invoicing"){query="State=8";title="Requires Invoicing";icon="activity"}
        if(view==="urgent"){query="State=5&IsUrgent=1";title="Urgent Jobs";icon="bell"}
        if(view==="completed"){query="State=11";title="Completed";icon="check-circle"}

        var companies = await databaseRequest("/companies","GET");
        var contacts = await databaseRequest("/contacts","GET");
        databaseRequest("/jobs?"+query,"GET").then((data)=>{
            if(typeof data.error !== 'undefined'){
                t.setState({
                    Companies: companies,
                    Contacts: contacts,
                    loading: false,
                    query: query,
                    title:title,
                    icon: icon,
                    sort: this.props.sort
                })
            }
            else{
                t.setState({
                    Companies: companies,
                    Contacts: contacts,
                    loading: false,
                    data: data,
                    query: query,
                    title:title,
                    icon: icon,
                    sort: this.props.sort
                })
            }
        });
    }
    sortJobId = ()=>{
        var {data, sort}=this.state;
        if(sort.Id==-1 || sort.Id==0){
            data = data.sort( ( a, b ) => {
                if ( a.Id < b.Id ){
                return -1;
                }
                if ( a.Id > b.Id ){
                return 1;
                }
                return 0;
            });
            sort.Id=1;
            sort.DueDateTime=0;
        }
        else if(sort.Id==1){
            data = data.sort( ( b, a ) => {
                if ( a.Id < b.Id ){
                return -1;
                }
                if ( a.Id > b.Id ){
                return 1;
                }
                return 0;
            });
            sort.Id=-1;
            sort.DueDateTime=0;
        }
        this.setState({data: data, sort: sort})
    }
    sortDueDateTime = ()=>{
        var {data, sort}=this.state;
        if(sort.DueDateTime==-1 || sort.DueDateTime==0){
            data = data.sort( ( a, b ) => {
                if ( a.DueDateTime < b.DueDateTime ){
                return -1;
                }
                if ( a.DueDateTime > b.DueDateTime ){
                return 1;
                }
                return 0;
            });
            sort.Id=0;
            sort.DueDateTime=1;
        }
        else if(sort.DueDateTime==1){
            data = data.sort( ( b, a ) => {
                if ( a.DueDateTime < b.DueDateTime ){
                return -1;
                }
                if ( a.DueDateTime > b.DueDateTime ){
                return 1;
                }
                return 0;
            });
            sort.Id=0;
            sort.DueDateTime=-1;
        }
        this.setState({data: data, sort: sort})
    }
    onSearch = (event) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        var search = event.target.value;
        databaseRequest(`/jobs?${this.state.query}&Name:br-like=%${search}%&CompanyName:or_like=%${search}%&Id:or_like-br=%${search}%`,"GET").then((data)=>{
                this.setState({
                    loading: false,
                    data: data.length>0?data:[]
                })
        });
    }
    getCompanyName = (CompanyId,ContactId)=>{
        var {Companies,Contacts} = this.state;
        if(CompanyId==-2){
            for(let i=0; i<Contacts.length; i++){
                if(Contacts[i].Id===ContactId){
                    return "["+Contacts[i].Name+"]";
                }
            }
        }
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===CompanyId){
                return Companies[i].Name;
            }
        }
        return null;
    }

    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading jobs...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-bordered ">
                    <thead className="thead-dark ">
                        <tr>
                        <th scope="col" className="text-black font-weight-bold">Job No.</th>
                        <th scope="col" className="text-black font-weight-bold">Company</th>
                        <th scope="col" className="text-black font-weight-bold">Name</th>
                        <th scope="col" className="text-right font-weight-bold">Created Date</th>
                        <th scope="col" className="text-right font-weight-bold">Due Date</th>
                        <th scope="col" className="text-right font-weight-bold">State</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        var CompanyName = this.getCompanyName(row.CompanyId,row.ContactId);
                        return (
                        <tr key={i}>
                            <th scope="row" >{row.Id}</th>
                            
                            <td>{CompanyName}</td>
                            <td>{row.Name}</td>
                            <td className="text-right">{new Date(row.CreatedDateTime).toLocaleDateString()}</td>
                            <td className="text-right">{new Date(row.DueDateTime).toLocaleDateString()}</td>
                            <td className="text-right"><State state={row.State}  color="text-black font-weight-bold"/></td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        return (
            <main role="main" className="container">
                <PortraitOrientation/>
                <div className="row form-group mb-0">
                    <div className="col p-0">
                        <h1 className="text-black mb-0"><i className="fe fe-flag text-black"></i> {this.state.title} Jobs List <i className={"fe fe-"+this.state.icon}></i></h1>
                        <small className="text-black">Printed: {new Date().toLocaleString()}</small>
                    </div>          
                    <div className="col-auto p-0">               
                        <img src="/logo-full.svg" alt="" width="150" height="70" />                   
                    </div>
                </div>
                <div className="row mt-2">
                    {this.table()}

                </div>
            </main>

            
        )
    }
}

export default Jobs;