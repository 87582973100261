import React, { useState, useCallback, useEffect } from 'react';
import io from "socket.io-client";
var socket = null;



export const ServerConnection = ({serverURL, serverUpdate, fnc=()=>{}}) => {

  const [connectionStatus, setConnectionStatus] = useState(false);
  const [visible, setVisible] = useState(false);
  const [serverUpdated, setServerUpdated] = useState(false);

  useEffect(() => {
    socket = io(serverURL);
    console.log("Server Socket Established");
    
    setConnectionStatus(socket.connected);

    socket.on("server_error", (data) => {
      alert(data);
    });
    socket.on('server-update', (data) => {
      console.log("Server updated")
      setServerUpdated(true);
      fnc();
    });

    socket.on('connect', ()=>{
      console.log("Server Socket:"+ socket.connected);
      setConnectionStatus(socket.connected);
    });

    socket.on("disconnect", () => {
      console.log("Server Socket:"+ socket.connected);
      setConnectionStatus(socket.connected);
    });


    return ()=>{
      socket.off();
      socket.disconnect();
      console.log("Server Socket Killed");
    }

  }, []);
  
 

  function sendMessage(topic, data) {
    socket.emit(topic, data);
  }


  return (<>{serverUpdated?serverUpdate:<></>}</>)
};