import React, { useState, useCallback, useEffect } from 'react';
import {Link} from "react-router-dom";

const JobList = ({databaseRequest, setPage}) => {
    const [data, setData] = useState([]);
    const [Highlight, SetHighlight] = useState(-1);
    const [MouseoverHighlight, SetMouseoverHighlight] = useState(-1);
    const [Companies, setCompany] = useState([]);
    const [Contacts, setContacts] = useState([]);
    const PROCESS_LIST = [
        { id: 0, name: "Drafting"},
        { id: 1, name: "Material Prep."},
        { id: 2, name: "Laser"},
        { id: 3, name: "Turning"},
        { id: 4, name: "Milling"},
        { id: 5, name: "Grinding"},
        { id: 6, name: "Fitting"},
        { id: 7, name: "Folding"},
        { id: 8, name: "Pressing"},
        { id: 9, name: "Welding"},
        { id: 10, name: "Supply"},
        { id: 11, name: "Hypermill"}
    ];
    useEffect(async () => {
        setPage("Job List")
        var results = await databaseRequest(`/jobs?State:not=8&State:not=11&State:not=20&State:not=10`,"GET");
        var companies = await databaseRequest("/companies","GET");
        var contacts = await databaseRequest("/contacts","GET");
        var processes = await databaseRequest("/jobs/processes?Complete=0","GET");
        setCompany(companies);
        setContacts(contacts);
        for(var i=0;i<results.length; i++){
            results[i].Highlight = false;
            results[i].Processes = [];
            for(var j=0; j<processes.length; j++){
                if(processes[j].JobId==results[i].Id){
                    results[i].Processes.push(processes[j]);
                }
                
            }
        }
        setData(results);   

    },[]);
    
    function getCompanyName(row){
        const {CompanyId,ContactId} = row;
        var str = "";
        if(CompanyId==-2){
            for(let i=0; i<Contacts.length; i++){
                if(Contacts[i].Id===ContactId){
                    str = "["+Contacts[i].Name+"]";
                }
            }
        }
        else{
            for(let i=0; i<Companies.length; i++){
                if(Companies[i].Id===CompanyId){
                    str =  Companies[i].Name;
                }
            }
        }
        str += " - "+row.Name;
        var len = 35;
        str = str.length > len?str.substring(0,len-3)+"...":str;
        return str;
    }

    function ProcessHeadings(){
        var processes = [];
        for(var i=0;i<results.length; i++){
            for(var j=0;j<results[i].Processes.length; j++){
                if(processes.indexOf(results[i].Processes[j].Step)===-1){
                    processes.push(results[i].Processes[j].Step);
                }
            }
        }
        return         (
        <div className='row justify-content-md-center'>
            {PROCESS_LIST.map((row, i)=>{
                var classStr = "col-1 btn m-1 ";
                classStr += Highlight==row.id?"btn-primary":"btn-outline-info";

                if(processes.indexOf(row.id)!==-1){
                    return (<button type="button" className={classStr} onMouseOver={()=>{SetMouseoverHighlight(row.id);}} onMouseOut={()=>{SetMouseoverHighlight(-1);}} onClick={(e)=>{e.currentTarget.blur(); SetHighlight(Highlight!=row.id?row.id:-1);}}>{row.name}</button>);
                }   
            })}
        </div>
        );
    }




    var results = data;
    for(var i=0;i<results.length; i++){
        results[i].Highlight = false;
        for(var j=0;j<results[i].Processes.length; j++){
            if(MouseoverHighlight!=-1)
            {
                if(MouseoverHighlight==results[i].Processes[j].Step){
                    results[i].Highlight = true;
                }
            }
            else{
                if(Highlight==results[i].Processes[j].Step){
                    results[i].Highlight = true;
                }
            }
        }
    }


    function JobClass(row){
        var str = "col-2 float-left list-group-item list-group-item-action p-0 text-left";
        if(row.Highlight){
            str += " badge-soft-success text-dark";
        }

        return str;
    }



    function JobsInRange(minDays, maxDays,style){
        var minDate = new Date();
        minDate.setDate(new Date().getDate() + minDays);

        var maxDate = new Date();
        maxDate.setDate(new Date().getDate() + maxDays);

        return (
        <>
        {results.map((row,i)=>{
            if(row.CreatedDateTime <= minDate && row.CreatedDateTime >= maxDate){
                return (<Link key={i} style={row.Highlight?{}:style} className={JobClass(row)} to={`/jobs/${row.Id}`}><div >{row.Id} {getCompanyName(row)}</div></Link>);
            }
        })}
        </>
        );
    }



    return (
        <main role="main" className="p-5 pt-6">
            {ProcessHeadings()}
            <div className='row mt-2'>
                <h4 className='col-12 text-center'>In the last week</h4>
                {JobsInRange(0, -7, {})}
            </div>
            <div className='row mt-3'>
                <h4 className='col-12 text-center'>1 -2 weeks ago</h4>
                {JobsInRange(-7, -14, {})}
            </div>
            <div className='row mt-3'>
                <h4 className='col-12 text-center'>2 - 4 weeks ago</h4>
                {JobsInRange(-14, -31, {backgroundColor: "rgb(250, 235, 240)"})}
            </div>
            <div className='row mt-3'>
                <h4 className='col-12 text-center'>1 - 3 months ago</h4>
                {JobsInRange(-31, -92, {backgroundColor: "rgb(250, 230, 235)"})}
            </div>
            <div className='row mt-3'>
                <h4 className='col-12 text-center'>3 - 12 months ago</h4>
                {JobsInRange(-92, -365, {backgroundColor: "rgb(250, 215, 221)"})}
            </div>
            <div className='row mt-3'>
                <h4 className='col-12 text-center'>Older than 1 year</h4>
                {JobsInRange(-365, -3650, {backgroundColor: "rgb(250, 215, 221)"})}
            </div>
        </main>
    );
};

export default JobList;