import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import queryString from "query-string"
class Contacts extends Component {
    state = {
        searchURL: "",
        loading: true,
        search: "",
        redirect: "",
        data: [],
        contactModal:{
            Id: -1,
            Number: "",
            Number2: "",
            Loading: true
        }
    }
    onSearch = (search) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true, search: search, searchURL: `system/contacts?q=${search}`});
        this.props.history.push(`/system/contacts?q=${search}`);

        databaseRequest(`/contacts?Name:like=%25${search}%25&IsHidden=0&sort=Name&SupplierId=-1`,"GET").then((Contacts)=>{
            if(typeof Contacts.error !== 'undefined'){
                this.setState({
                    loading: false
                })
            }
            else{       
                this.setState({
                    loading: false,
                    Contacts: Contacts
                });
                this.updateCompanyNames();
            }
        });
    }
    updateCompanyNames = ()=>{
        var {Contacts, Companies} = this.state;
        for(let i=0; i<Contacts.length; i++){
            Contacts[i].CompanyName="Not Allocated";
            for(let y=0; y<Companies.length; y++){
                if(Contacts[i].CompanyId==Companies[y].Id){
                    Contacts[i].CompanyName=Companies[y].Name;
                    Contacts[i].CompanyName=Contacts[i].CompanyName=="Personal"?"-":Contacts[i].CompanyName;
                }
            }
        }
        this.setState({Contacts: Contacts});
    }
    HideContact = async (ContactId) =>{
        console.log(ContactId);
        const {databaseRequest}=this.props;
        let t = this; 
        var req = {
            url: `/contacts/${ContactId}`,
            method: "PUT"
        }
        var {Contacts} = this.state;
        var Contact = {};
        for(let i=0; i<Contacts.length; i++){
            if(Contacts[i].Id===ContactId){
                Contact = Contacts[i];
                Contact.IsHidden = 1;
            }
        }

        var result = await databaseRequest(req.url,req.method,JSON.stringify(Contact));
        this.reload();
    }

    reload = async () =>{
        const {databaseRequest,setPage}=this.props;
        let t =this;
        var query = queryString.parse(this.props.location.search);
        var search = "";
        if(typeof query.q !== 'undefined'){
            search = query.q;
        }
        setPage(`Companies`);
        var Companies = await databaseRequest("/companies","GET");
        var Contacts = await databaseRequest(`/contacts?Name:like=%25${search}%25&IsHidden=0&sort=Name&SupplierId=-1`,"GET");
        
        t.setState({
            Companies: Companies,
            Contacts: Contacts,
            loading: false,
            search: search,
            searchURL: `system/contacts?q=${search}`
        });
        t.updateCompanyNames();
    }
    componentDidMount = async () =>{
       this.reload();
    } 

    loadModal = (ContactId) =>{
        const {Companies, Contacts, contactModal} = this.state;
        for(let i=0; i<Contacts.length; i++){
            if(Contacts[i].Id==ContactId){
                contactModal.Contact = Contacts[i];
                for(let y=0; y<Companies.length; y++){
                    if(Contacts[i].CompanyId==Companies[y].Id){
                        contactModal.Company = Companies[y];
                    }
                }
                contactModal.Loading = false;
                
                this.setState({
                    contactModal: contactModal,
                    
                })

            }
        }
    }
    onChange = (e) => {
        let data = this.state.contactModal;
        data.Contact[e.target.name]=e.target.value;

        data.Contact.Number = this.phoneNumberFormat(data.Contact.Number);
        data.Contact.Number2 = this.phoneNumberFormat(data.Contact.Number2);
        this.setState({ contactModal: data});  
    };
    phoneNumberFormat = (num)=>{
        
        if(num.length > 2){
            if(num.substring(0,2)=="04" && num.length>4){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
                if(num.length > 8){
                    if(num.charAt(8)!=" "){
                        num = num.substring(0,8)+" "+num.substring(8,num.length);
                    }
                }
            }
            if(num.charAt(0)!="0" && num.length>4 ){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
            }
            if(num.substring(0,2)=="03"){
                if(num.charAt(2)!=" "){
                    num = num.substring(0,2)+" "+num.substring(2,num.length);
                }
                if(num.length > 7){
                    if(num.charAt(7)!=" "){
                        num = num.substring(0,7)+" "+num.substring(7,num.length);
                    }
                }
            }
        }
        return num;
    }
    saveBtn = async () =>{
        const {databaseRequest}=this.props;
        let t = this;
        this.setState({loading: true});
        await databaseRequest(`/contacts/${this.state.contactModal.Contact.Id}`,"PUT",JSON.stringify(this.state.contactModal.Contact));
        setTimeout(function(){
            t.setState({
                loading: false,
                loadingMsg: "Loading contacts...",
                redirect: "/redirector?r="+t.state.searchURL
            });
        }, 1000);
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading contacts...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col" style={{width: '20px'}}></th>
                        <th scope="col" style={{width: '20px'}}>#</th>
                        <th scope="col">Contact Name</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Phone</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.Contacts.map((row, i) => {
                        return (
                        <tr key={i}>
                            <td><i className="fe fe-x-circle link" onClick={(e)=>{this.HideContact(row.Id)}}></i></td>
                            <th scope="row">{1+i}</th>
                            <td data-toggle="modal" data-target="#jobModal" onClick={()=>{this.loadModal(row.Id)}} className="link">{row.Name}</td>
                            <td>{row.CompanyId!=-2?(
                                <Link to={`/system/companies/${row.CompanyId}`}>{row.CompanyName}</Link>
                            ):(
                                row.CompanyName
                            )}</td>
                            <td>{row.Number2.length > 0? row.Number+" / "+row.Number2 : row.Number}</td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        if(this.state.redirect.length>1){
            this.props.history.push(this.props.location.pathname)
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-user"></i> All Contacts</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input className="form-control mr-sm-2" type="search" onChange={(event)=>{this.onSearch(event.target.value)}} value={this.state.search} placeholder="Search" aria-label="Search"/>                      
                        </form>
                    </div>
                    <div className="col p-0">
                        <form className="form-inline">
                        <Link to="/system/companies"><button className='btn btn-secondary mr-4 float-right' onClick={()=>{}}>Companies</button> </Link>   
                        <button className='btn btn-dark float-right'>Contacts</button>                
                        </form>
                        
                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>

                <div className="modal" id="jobModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">

                        {!this.state.contactModal.Loading?(
                            <React.Fragment>
                                <div className="modal-header">
                                    <div className="modal-title">
                                        <h2 className="mb-1"> {this.state.contactModal.Contact.Name}</h2>
                                        <h3 className="link" data-dismiss="modal" onClick={()=>{this.setState({redirect: `/system/companies/${this.state.contactModal.Company.Id}`})}}><i className="fe fe-chevron-right"></i> {this.state.contactModal.Company.Name}</h3>
                                    </div>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">  
                                <table className="table">
                                    <tr>
                                        <td style={{width:"125px"}}><label>Name </label></td><td><input type="text" className="form-control" placeholder="Contact Name" name="Name" value={this.state.contactModal.Contact.Name}  onChange={this.onChange}/></td>
                                    </tr>
                                    <tr>
                                        <td><label>Contact Number 1</label></td><td><input type="text" className="form-control" placeholder="Contact Number 1" name="Number" value={this.state.contactModal.Contact.Number}  onChange={this.onChange}/></td>
                                    </tr> 
                                    <tr>
                                        <td><label>Contact Number 2</label></td><td><input type="text" className="form-control" placeholder="Contact Number 2" name="Number2" value={this.state.contactModal.Contact.Number2}  onChange={this.onChange}/></td>
                                    </tr>
                                    <tr>
                                        <td><label>E-mail</label></td><td><input type="text" className="form-control" placeholder="E-mail" name="Email" value={this.state.contactModal.Contact.Email}  onChange={this.onChange}/></td>
                                    </tr>
                                </table>     
                              </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-success" data-dismiss="modal" onClick={()=>{this.saveBtn(this.state.contactModal.Id)}}>Save</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                                </div>
                            </React.Fragment>
                        ):(
                            <div className="modal-body">
                                <p>Loading...</p>
                            </div>                      
                        )}
                        </div>
                    </div>
                </div>
            </main>

            
        )
    }
}

export default Contacts;