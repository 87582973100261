import React, { Component } from 'react';
import {Link} from "react-router-dom";
import TimeAgo from '../Components/TimeAgo.js';
import queryString from "query-string"

class Log extends Component {
    state = {
        page: 0,
        loading: true,
        data: []
    }
    componentDidMount = async () =>{
        const {setPage, params}=this.props;
        setPage(`Log`);
        var page = Number(params.page) || 0;
        page = page < 0?0:page;
        this.setState({
            page: page
        })
        this.reload();
    }
    /*componentWillReceiveProps(nextProps){
        if(this.props.params.page!==nextProps.params.page){
            this.setState({redirect: "/redirector?r=jobs/"+nextProps.params.JobId})
        }
    }*/
    reload = async ()=>{
        const {databaseRequest,setPage,params}=this.props;
        var Companies = await databaseRequest("/companies","GET");
        var Contacts = await databaseRequest("/contacts","GET");
        var Users = await databaseRequest("/users","GET");
        Users.push({
            Id: -10,
            Name: "System"
        })
        this.setState({
            loading: true
        })
        databaseRequest(`/history/log/${this.state.page}`,"GET").then((data)=>{
            this.setState({
                loading: false,
                Companies: Companies,
                Contacts: Contacts,
                Users: Users,
                data: data.length>0?data:[],
            })
        });
    }
    setPage = (page) =>{
        let t = this;
        this.setState({
            page: page,
            loading: true,
        });
        setTimeout(()=>{
            t.reload();
        },500)
        
    }
    getCompanyName = (CompanyId,ContactId)=>{
        var {Companies,Contacts} = this.state;
        if(CompanyId==-2){
            for(let i=0; i<Contacts.length; i++){
                if(Contacts[i].Id===ContactId){
                    return Contacts[i].Name;
                }
            }
        }
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===CompanyId){
                return Companies[i].Name;
            }
        }
        return null;
    }
    UsersName = (e)=>{
        const {Users} = this.state;
        for(let i=0; i<Users.length; i++){
            if(Users[i].Id===e){
                return (Users[i].Name);
            }
        }
        return "";
    }
    loading = () =>{
        return (
            <div className="w-100 mt-5 text-center">
                <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
    page = () =>{
        const query = queryString.parse(this.props.location.search);
        var view = "";
        if(typeof query.return !=='undefined'){
            view = query.return;
        }
        return (
            <main role="main" className="container">
            <div className="p-5">
               
                <div className="row form-group">
                    <div className="col p-0">
                        <h2><i className="fe fe-clock"></i> JSS - Log</h2>
                    </div>
                    <div className="col p-0">
                       
                    </div>
                </div>
                <div className="row">
                        {this.table()}
                </div>
            </div>
            </main>
        );
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading history...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <React.Fragment>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                            <li className={this.state.page<=0?"page-item disabled":"page-item"}>
                                <Link to={"/system/log/"+(this.state.page<=0?1:this.state.page-1)}><div className="page-link" onClick={()=>{this.setPage(this.state.page<=0?1:this.state.page-1)}} tabindex="-1">Previous</div></Link>
                            </li>
                            <li className="page-item">
                                {this.state.page>1?(
                                    <Link to={"/system/log/1"}> 
                                        <div className="page-link" onClick={()=>{this.setPage(0)}} >0...</div>
                                    </Link>
                                ):(<React.Fragment></React.Fragment>)}
                            </li>

                            <li className="page-item">
                                {this.state.page>0?(
                                    <Link to={"/system/log/"+(this.state.page-1)}> 
                                        <div className="page-link" onClick={()=>{this.setPage(this.state.page-1)}} >{this.state.page-1}</div>
                                    </Link>
                                ):(<React.Fragment></React.Fragment>)}
                            </li>

                                    
                            <li className="page-item active">
                                <Link to={"/system/log/"+(this.state.page)}> 
                                    <div className="page-link" onClick={()=>{this.setPage(this.state.page)}} >{this.state.page}</div>
                                </Link>
                            </li>

                            <li className="page-item">
                                {this.state.data.length==100?(
                                    <Link to={"/system/log/"+(this.state.page+1)}> 
                                        <div className="page-link" onClick={()=>{this.setPage(this.state.page+1)}} >{this.state.page+1}</div>
                                    </Link>
                                ):(<React.Fragment></React.Fragment>)}
                            </li>

                            <li className="page-item">
                                {this.state.data.length==100?(
                                    <Link to={"/system/log/"+(this.state.page+2)}> 
                                        <div className="page-link" onClick={()=>{this.setPage(this.state.page+2)}} >{this.state.page+2}</div>
                                    </Link>
                                ):(<React.Fragment></React.Fragment>)}
                            </li>

                            <li className="page-item">
                                {this.state.data.length==100?(
                                    <Link to={"/system/log/"+(this.state.page+1)}> 
                                        <div className="page-link" onClick={()=>{this.setPage(this.state.page+1)}}>Next</div>
                                    </Link>
                                ):(<React.Fragment></React.Fragment>)}                               
                            </li>
                        </ul>
                    </nav>
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">Job</th>
                        <th scope="col">Who</th>
                        <th scope="col">When</th>
                        <th scope="col">What</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        return (
                        <tr key={i}>
                            <td><Link to={"/system/jobs/"+row.Id}>[{this.getCompanyName(row.CompanyId,row.ContactId)}] {row.Name}</Link></td>
                            <td>{this.UsersName(row.UserId)}</td>
                            <td><TimeAgo time={row.Datetime} /><br/>({(new Date(row.Datetime)).toLocaleString()})</td>                  
                            <td>{row.Comment}</td>                     
                        </tr>)
                    })}
                    </tbody>
                    </table>
                    </React.Fragment>
                )
            }
    }
    render() {
        return this.page();
    }
}

export default Log;