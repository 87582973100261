import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
class ServerNotActive extends Component {
    timeout = null;
    state = {
        timeout: null,
        redirect: ""
    }
    componentWillUnmount = () =>{
        let t = this;
        clearTimeout(t.timeout);
    }
    componentDidMount = () => {
        let t = this;
        const {databaseRequest,onSignIn,setPage, onSetServerState}=this.props;
        t.timeout = setInterval(async ()=>{
            var data = await databaseRequest(`/login/${localStorage.getItem('token')}`);
            if(data.length==1){
                onSetServerState(true);
                
                t.setState({redirect: "/home"})
            }
        },1000)
    }
    render (){
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (<React.Fragment>
            <h3 className="p-3">Server not active...</h3>
        </React.Fragment>)
    }
  }
  export default ServerNotActive;
  