import React, { Component } from 'react';
import md5 from 'md5';
import Copyright from '../Copyright.js';
import './Login.css';
import queryString from "query-string";

class Login extends Component {
    state = {
        username: "",
        password: "",
        loading: true,
        failed: false,
        isMounted: false,
        timeout: null
    }
    onSubmit = (event) => {
        let t = this;
        const {databaseRequest,onSignIn}=this.props;
        event.preventDefault();
        t.setState({loading:true});

        databaseRequest("/login","POST",
            JSON.stringify({
                Username: this.state.username,
                Password: this.state.password
            })
        ).then((data)=>{
            if(data.length===1){
                if(data[0].Login<0){
                  t.setState({failed:true, loading:false});
                  setTimeout(function(){
                    t.setState({failed:false});
                  }, 100);
                }
                else{
                    localStorage.setItem('token', data[0].Token);
                    localStorage.setItem('user', data[0].UserId);
                    localStorage.setItem('entity', data[0].EntityId);
                    localStorage.setItem('access', data[0].Access);
                  setTimeout(function(){
                    onSignIn(true);
                  }, 100);
                }
              }       
        })
    }
    componentDidMount = () => {
        let t = this;
        t.timeout = setTimeout(()=>{
            t.setState({loading:false}); 
        },1000);
    }
    componentWillUnmount = () =>{
        let t = this;
        clearTimeout(t.timeout);
    }
    componentDidMount = async () => {
        let t = this;
        const {databaseRequest,onSignIn,setPage}=this.props;
        setPage("Login");
        var query = queryString.parse(window.location.search);
        if(query.print_token=="download"){
            localStorage.setItem('token', "download");
            localStorage.setItem('user', 999);
            localStorage.setItem('access', 999);
            onSignIn(true);
        }
        else{
        if(localStorage.getItem('token')=="download"){
                localStorage.clear();
        }
        databaseRequest(`/login/${localStorage.getItem('token')}`,'GET').then((data)=>{  
            if(data.length===1){
                if(data[0].Login!==-1){
                    onSignIn(true);
                    localStorage.setItem('token', data[0].Token);
                    localStorage.setItem('user', data[0].UserId);
                    localStorage.setItem('access', data[0].Access);
                }
                else
                {
                    onSignIn(false);
                    localStorage.clear();
                    t.setState({loading:false});
                }
            }
        });


    }
        

    }
    render (){
      let container;  
      if(this.state.loading){
        container = (
            <div>
                <h1 className="h3 mb-3 font-weight-normal">Loading...</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
      }
      else{
        container = (
            <div>
                <label htmlFor="username" className="sr-only">Username</label>
                <input type="text" onChange={(e) => { this.setState({ username: (e.target.value) }) }} id="username" className="form-control" placeholder="Username" required autoFocus ></input>
                <label htmlFor="password" className="sr-only">Password</label>
                <input type="password" id="password" onChange={(e) => { this.setState({ password: md5(e.target.value) }) }} className="form-control" placeholder="Password" required></input>
                <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
            </div>
        );
      }
      return (
          <div className="signIn text-center">
              <form className="form-signin" onSubmit={this.onSubmit}>
                    <div className="text-center">        
                        <img className="mx-auto d-block" src="/logo-full.svg" alt="" width="300" height="200" />
                   </div>  
                   {container}
                  <Copyright/>
              </form>
          </div>
      );
    }
  }
  export default Login;
  