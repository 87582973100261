import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import Holidays from 'date-holidays';

export const UpcomingCalendar = ({databaseRequest})=>{
    const [data, setData] = useState([]);
    const [Companies, setCompany] = useState([]);
    const [Contacts, setContacts] = useState([]);

    //const currentDate = new Date(2024, 5, 10, 1,1,1);
    const currentDate = new Date();

    var hd = new Holidays();
    hd.init('AU', 'VIC');


    var dow = currentDate.getDay()-1;
    dow = dow<0?dow+7:dow;

    var days = [0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    days[dow] = currentDate.getDate();
    var dayData = [];

    var minDate = currentDate.getTime();
    
    for(var i=0; i<days.length; i++){
        var d = new Date(currentDate.getTime());
        d.setDate(d.getDate() + (i-dow));
        days[i] = d.getDate()+"/"+(d.getMonth()+1);
        minDate = d.getTime()<minDate?d.getTime():minDate;

        var jobs = [];
        var h = hd.isHoliday(d);
        for(var j=0; j<h.length; j++){
            jobs.push({...h[j], isHoliday: true});
        }


        
        for(var j=0; j<data.length; j++){
            var dueDate = new Date(data[j].DueDateTime);


            if(dueDate.getFullYear()==d.getFullYear() && dueDate.getMonth()==d.getMonth() && dueDate.getDate()==d.getDate()){
                jobs.push({...data[j], isHoliday: false});
            }
        }
        dayData.push(jobs);
    }
    useEffect(async () => {
        var results = await databaseRequest(`/highcharts/upcoming-jobs/${minDate}/${minDate+1209600000}`,"GET");
        /*for(var i=0; i<results.length; i++){
            results[i].steps = await databaseRequest(`/jobs/processes/${results[i].Id}`,"GET");
        }
        var companies = await databaseRequest("/companies","GET");
        var contacts = await databaseRequest("/contacts","GET");
        setCompany(companies);
        setContacts(contacts);*/
        setData(results);
    }, []);

    
    function renderDay(index){
        
        return (
        <div className="list-group">
            {
            dayData[index].map((row, i)=>{
                const MAX_LEN = 28;

                if(row.isHoliday){
                    return (<p className='p-2 m-0 text-left font-weight-bold'>{row.name}</p>)
                }
                else{
                    var company = row.Company;
                    company = row.CompanyId==-2?"["+row.Contact+"]":company;
                    company = company.length > MAX_LEN+3?company.substring(0,MAX_LEN)+"...":company;


                    const process = [{ id: 0, name: "Drafting"},
                    { id: 1, name: "Material Prep."},
                    { id: 2, name: "Laser"},
                    { id: 3, name: "Turning"},
                    { id: 4, name: "Milling"},
                    { id: 5, name: "Grinding"},
                    { id: 6, name: "Fitting"},
                    { id: 7, name: "Folding"},
                    { id: 8, name: "Pressing"},
                    { id: 9, name: "Welding"},
                    { id: 10, name: "Supply"},
                    { id: 11, name: "Hypermill"}];
                    var list = "";

                    
                    for(var j=0; j<row.Processes.length; j++){
                        for(var k=0; k<process.length; k++){
                            if(row.Processes[j]==process[k].id){
                                var name = process[k].name.split(" ");
                                for(var l=0; l<name.length; l++){
                                    list += name[l].substring(0,1);
                                }
                                list += ".";
                            }
                        }
                    }
    
                    var lineClass = "list-group-item list-group-item-action p-0 m-0 text-left";
                    lineClass += !(row.State<=5)?" list-group-item-success text-decoration-line-through":"";
                    return (<Link key={i} to={`/jobs/${row.Id}`}><p href="#"  className={lineClass} >{row.Id}[{list}] - {company}</p></Link>);
                }

            })
            }
            

        </div>)

    }
    function HolidayClass(index){
        var holidayFound = false;
        for(var i=0; i<dayData[index].length; i++){
            if(dayData[index][i].isHoliday){
                holidayFound = true;
            }
        }
        return holidayFound?{backgroundColor:"#dbdbdb"}:{}
    }

    function getCompanyName(CompanyId,ContactId){
        if(CompanyId==-2){
            for(let i=0; i<Contacts.length; i++){
                if(Contacts[i].Id===ContactId){
                    
                    return "["+Contacts[i].Name+"]";
                }
            }
        }
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===CompanyId){
                
                return Companies[i].Name;
            }
        }
        return null;
    }

     
    return <>
        <div className='col'>
            <div className='card'>
                <table className='table m-0'>
                    <thead>
                        <tr className='text-center'>
                            <th style={{width: "16%", backgroundColor: dow==0?"#ccf7e5":""}}><small>{days[0]}</small> Mon</th>
                            <th style={{width: "16%", backgroundColor: dow==1?"#ccf7e5":""}}><small>{days[1]}</small> Tues</th>
                            <th style={{width: "16%", backgroundColor: dow==2?"#ccf7e5":""}}><small>{days[2]}</small> Wed</th>
                            <th style={{width: "16%", backgroundColor: dow==3?"#ccf7e5":""}}><small>{days[3]}</small> Thurs</th>
                            <th style={{width: "16%", backgroundColor: dow==4?"#ccf7e5":""}}><small>{days[4]}</small> Fri</th>
                            <th style={{width: "10%", backgroundColor: dow==5?"#ccf7e5":""}}><small>{days[5]}</small> Sat</th>
                            <th style={{width: "10%", backgroundColor: dow==6?"#ccf7e5":""}}><small>{days[6]}</small> Sun</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='text-muted'>
                            <td style={HolidayClass(0)}>{renderDay(0)}</td>
                            <td>{renderDay(1)}</td>
                            <td>{renderDay(2)}</td>
                            <td>{renderDay(3)}</td>
                            <td>{renderDay(4)}</td>
                            <td>{renderDay(5)}</td>
                            <td>{renderDay(6)}</td>
                        </tr>
                    </tbody>   
                </table>
                <table className='table m-0'>
                    <thead>
                        <tr className='text-center'>
                            <th style={{width: "16%"}}><small>{days[7]}</small> Mon</th>
                            <th style={{width: "16%"}}><small>{days[8]}</small> Tues</th>
                            <th style={{width: "16%"}}><small>{days[9]}</small> Wed</th>
                            <th style={{width: "16%"}}><small>{days[10]}</small> Thurs</th>
                            <th style={{width: "16%"}}><small>{days[11]}</small> Fri</th>
                            <th style={{width: "10%"}}><small>{days[12]}</small> Sat</th>
                            <th style={{width: "10%"}}><small>{days[13]}</small> Sun</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='text-muted'>
                            <td>{renderDay(7)}</td>
                            <td>{renderDay(8)}</td>
                            <td>{renderDay(9)}</td>
                            <td>{renderDay(10)}</td>
                            <td>{renderDay(11)}</td>
                            <td>{renderDay(12)}</td>
                            <td>{renderDay(13)}</td>
                        </tr>
                    </tbody>   
                </table>
            </div>         
        </div>
    </>;

}