import React, { Component} from 'react';
import {Link, Redirect } from "react-router-dom";
import State from '../../Components/State.js';
import TimeAgo from '../../Components/TimeAgo.js';

class CompaniesEdit extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading companies...",
        redirect: "",
        entities: [],
        data: null,
        message: "",
        messageState: "text-danger",
        buttonState: false,
        newContactInput: false,
    }
    phoneNumberFormat = (num)=>{
        if(num.length > 4){
            if(num.substring(0,2)=="04"){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
                if(num.length > 8){
                    if(num.charAt(8)!=" "){
                        num = num.substring(0,8)+" "+num.substring(8,num.length);
                    }
                }
            }
            if(num.charAt(0)!="0"){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
                if(num.length > 7){
                    if(num.charAt(7)!=" "){
                        num = num.substring(0,7)+" "+num.substring(7,num.length);
                    }
                }
            }
        }
        return num;
    }
    onChange = (e) => {
        let data = this.state.data;
        data[e.target.name]=e.target.value;
        this.setState({ data: data});  
    };
    onContactChange = (e, id)=>{
        var {Contacts} = this.state;
        for(let i=0; i<Contacts.length; i++){
            if(Contacts[i].Id===id){
                Contacts[i][e.target.name]=e.target.value;
                Contacts[i].Number = this.phoneNumberFormat(Contacts[i].Number);
            }
        }
        this.setState({Contacts: Contacts});        
    }
    HideContact = async (ContactId) =>{
        const {databaseRequest}=this.props;
        let t = this; 
        var req = {
            url: `/contacts/${ContactId}`,
            method: "PUT"
        }
        var {Contacts} = this.state;
        var Contact = {};
        for(let i=0; i<Contacts.length; i++){
            if(Contacts[i].Id===ContactId){
                Contact = Contacts[i];
                Contact.IsHidden = 1;
            }
        }

        if(ContactId!==-1){
            var result = await databaseRequest(req.url,req.method,JSON.stringify(Contact));
        }
        
        this.reload();
    }
    NewContact = async () =>{
        var {Contacts} = this.state;
        
        Contacts.push({Id:-1,Name: "", Number: "", Number2: "", Email: ""});
        this.setState({Contacts: Contacts, newContactInput: true})
    }
    SaveNewContact = async () =>{
        var {Contacts} = this.state;
        const {databaseRequest,params}=this.props;
        var Contact = {
            Name: "",
            Number: "",
            Number2: "",
            Email: "",
            CompanyId: "",
            SupplierId: -1,
            IsHidden: 0
        };
        for(var i=0; i<Contacts.length; i++){
            if(Contacts[i].Id===-1){
                Contact.Name = Contacts[i].Name;
                Contact.Number = Contacts[i].Number;
                Contact.Number2 = Contacts[i].Number2;
                Contact.Email = Contacts[i].Email;
            }
        }
        Contact.CompanyId = params.CompanyId;
        await databaseRequest(`/contacts/`,"POST",JSON.stringify(Contact));
        this.reload();
    }
    saveBtn = async () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: `Saving ${this.state.data.Name}...`
        });
        var {data,Contacts} = this.state;
        var data = await databaseRequest(`/companies/${params.CompanyId}`,"PUT",JSON.stringify(data));

        for(let i=0; i<Contacts.length; i++)
        {
            Contacts[i].Number = this.phoneNumberFormat(Contacts[i].Number);
            await databaseRequest(`/contacts/${Contacts[i].Id}`,"PUT",JSON.stringify(Contacts[i]));
        }

        this.reload();
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    async componentDidMount(){
        this.reload();
    }
    reload = async ()=>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        setPage(`Companies`);
        if(params.CompanyId==="-1"){
            t.setState({
                data:{
                    Name: "",
                },
                loadingMsg: "Loading companies...",
                loading: false
            });
        }
        else{
            var Contacts = await databaseRequest(`/contacts?CompanyId=${params.CompanyId}&IsHidden=0`,"GET");
            var Jobs = await databaseRequest(`/jobs?CompanyId=${params.CompanyId}&sort=Id%20DESC`,"GET");
            databaseRequest(`/companies/${params.CompanyId}`,"GET").then((data)=>{
                    setPage(`${data.Name}`);
                    t.setState({
                        Jobs: Jobs,
                        Contacts: Contacts,
                        loading: false,
                        newContactInput: false,
                        data: data
                    });
            });
        }
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    } 
    page = () =>{
        const {props} = this;
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            this.props.history.push(this.props.location.pathname)
            return (       
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-dollar-sign"></i> Companies</h2>
                <div className="row form-group">
                    <div className="col p-0">
                    <h1 className="border-bottom">[{this.data("Id")}] - {this.data("Name").length==0?"--New Company--":this.data("Name")}</h1>
                    </div>
                    <div className="col p-0">
                        <button type="button" className="btn btn-info float-right" onClick={()=>{this.props.history.goBack()}}>Back</button>
                    </div>
                </div>
                <div className="row">
                    <form className="w-100">

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label>Company Name</label>
                            <input type="text" className="form-control" placeholder="Full Name" name="Name" value={this.data("Name")} onChange={this.onChange}/>
                        </div>                      
                    </div>
                    <div className="form-row">
                        <div className="form-group col w-100">
                            <label>Contacts</label>
                            <table className="table table-striped">
                            <thead className="thead-dark">
                                <tr><th scope="col" style={{width: '20px'}}></th><th style={{width: '20px'}}>#</th><th>Name</th><th style={{width: '15%'}}>Number</th><th style={{width: '15%'}}>Number 2</th><th>Email</th>
                                
                                {this.state.newContactInput?
                                (<th scope="col"><button className='btn btn-success float-right' onClick={(e)=>{this.SaveNewContact()}}><i className="fe fe-plus-circle"></i> Save</button></th>):
                                (<th scope="col"><h1><i className="fe fe-plus-circle float-right link-header" onClick={(e)=>{this.NewContact()}}></i></h1></th>)}
                                </tr>

                            </thead>
                            <tbody>
                            {this.state.Contacts.map((row,i)=>{
                                return (
                                <tr key={i}>
                                    <td><i className="fe fe-x-circle link" onClick={(e)=>{this.HideContact(row.Id)}}></i></td>
                                    <td >{(i+1)}</td>
                                    <td><input type="text" className="form-control" placeholder="Contact Name" name="Name" value={row.Name} onChange={(e)=>{this.onContactChange(e,row.Id)}}/></td>
                                    <td><input type="text" className="form-control" placeholder="Contact Number" name="Number" value={row.Number} onChange={(e)=>{this.onContactChange(e,row.Id)}}/></td>
                                    <td><input type="text" className="form-control" placeholder="Contact Number 2" name="Number2" value={row.Number2} onChange={(e)=>{this.onContactChange(e,row.Id)}}/></td>
                                    <td colspan="2"><input type="text" className="form-control" placeholder="Contact Email" name="Email" value={row.Email} onChange={(e)=>{this.onContactChange(e,row.Id)}}/></td>
                                </tr>)
                            })}
                            </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col w-100">
                            <label>Previous Jobs</label>
                            <table className="table table-striped">
                            <thead className="thead-dark">
                                <tr><th>#</th><th>Name</th><th>Created</th><th className="text-right">State</th></tr>
                            </thead>
                            <tbody>
                            {this.state.Jobs.map((row,i)=>{
                                return (
                                <tr key={i}>
                                    <td style={{width: '20px'}}><Link to={`/jobs/${row.Id}?return=${this.props.location.pathname}`}>{(i+1)}</Link></td>
                                    <td style={{width: '40%'}}><Link to={`/jobs/${row.Id}?return=${this.props.location.pathname}`}>{row.Name}</Link></td>
                                    <td><TimeAgo time={row.CreatedDateTime}/> ({new Date(row.CreatedDateTime).toLocaleDateString()})</td>
                                    <td className="text-right"><State state={row.State}/></td>
                                </tr>)
                            })}
                            </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            {this.props.params.CompanyId!=="-1"?(
                            <button type="button" className="btn btn-success" onClick={this.saveBtn}>Save</button>
                            ):(
                            <button type="button" className="btn btn-success" disabled={!this.state.buttonState} onClick={this.saveBtn}>Create</button>
                            )}
                            {this.props.params.CompanyId!=="-1"?(
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}`}>
                                <button type="button" className="btn btn-dark ml-3">History</button>
                            </Link>
                            ):(<div></div>)}
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default CompaniesEdit;