import React, { Component } from 'react';
import {Redirect} from "react-router-dom";
import queryString from "query-string"

import ReactToPrint from 'react-to-print';
import JobSheetPrint from './JobSheetPrint.js'

class OperatorsAll extends Component {
    state = {
        loading: true,
        redirect: "",
        socket: null,
        processes: [
            { id: 0, name: "Drafting", selected: false},
            { id: 1, name: "Material Prep.", selected: false},
            { id: 2, name: "Laser", selected: false},
            { id: 3, name: "Turning", selected: false},
            { id: 4, name: "Milling", selected: false},
            { id: 5, name: "Grinding", selected: false},
            { id: 6, name: "Fitting", selected: false},
            { id: 7, name: "Folding", selected: false},
            { id: 8, name: "Pressing", selected: false},
            { id: 9, name: "Welding", selected: false},
            { id: 10, name: "Supply", selected: false},
            { id: 11, name: "Hypermill", selected: false}
        ],
        jobModal:{
            Id: -1,
            Loading: false,
            OperatorName: "",
            JobName: "",
            steps: [],
            details: {
                ProcessGuid: ""
            }
        }
    }
    redirect = (url)=>{
        this.setState({redirect: url});
    }
    loadModal = async (operator, job)=>{
        const {databaseRequest}=this.props;
        var {jobModal} = this.state;
        jobModal.Id=job;
        jobModal.JobName = this.JobName(job);
        jobModal.OperatorName = this.OperatorName(operator);
        jobModal.Loading = true;
        this.setState({jobModal:jobModal});
        jobModal.steps = await databaseRequest(`/jobs/processes/${job}`,"GET");
        for(var k=0; k<jobModal.steps.length; k++){
            jobModal.steps[k].descShown=jobModal.steps[k].description.length>0
        }
        
        jobModal.details = await databaseRequest(`/schedule/${job}/${operator}`,"GET");

        jobModal.Loading = false;
        this.setState({jobModal:jobModal});
    }
    stepDescription=(i)=>{
        const {steps} = this.state.jobModal;
        if (i > -1 && i<steps.length) {
            return steps[i].description;
        }
    }
    toggleCompletion = (i)=>{
        var {jobModal} = this.state;
        if (i > -1 && i<jobModal.steps.length) {
            jobModal.steps[i].complete=!jobModal.steps[i].complete;
        }
        this.setState({
            jobModal:jobModal
        });
    }
    saveBtn = (JobId) =>{
        const {databaseRequest}=this.props;
        let t = this;
        this.setState({
            loading: true
        });
        var req = {
            url: `/jobs/processes/${JobId}`,
            method: "PUT"
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(t.state.jobModal.steps)
        ).then((data)=>{
            t.reload(true);
        });

    }
    steps = ()=>{
        const {jobModal} = this.state;
        const {steps}=jobModal;
        return (
                <div className="">
                {steps.map((row, i) => {
                       return (

                        <div key={i} className={row.complete?"modal-item bg-success text-dark":row.guid===jobModal.details.ProcessGuid?("modal-item bg-warning text-dark"):("modal-item bg-secondary")}>
                            <div className="row">
                                <div className="col"><h3 className={row.guid===jobModal.details.ProcessGuid || row.complete?("text-dark mb-0 m-3"):("text-white mb-0 m-3")}>{i+1} - {this.ProcessName(row.process)}</h3></div>
                                <div className="col">
                                    <h3 className="text-right mb-0 m-3">
                                    <i className={row.complete?"fe fe-minus-circle hover-dark mr-2":row.guid===jobModal.details.ProcessGuid?("fe fe-check-circle hover-dark mr-2"):("fe fe-check-circle hover mr-2")} onClick={()=>{this.toggleCompletion(i)}}></i>
                                    </h3>
                                </div>
                            </div> 
                            {row.descShown || row.description.length>0?(
                            <div className="row">
                            <div className="col pl-4">
                                <i className="fe fe-chevron-right"></i> {this.stepDescription(i)}
                            </div>
                            </div>     
                            ):(<React.Fragment></React.Fragment>)}                 
                        </div>
                        )                      
                })}
                </div>
            );
    }        
    reload = async (loading)=>{
        const {setPage, databaseRequest}=this.props;
        let t =this;
        setPage(`Schedule`);
        this.setState({loading: loading});
        var Companies = await databaseRequest("/companies","GET");
        var Operators = await databaseRequest("/operators?Id:not=0&OrderBy:not=-1","GET");
        for(let i=Operators.length-1; i>=0; i--){   
            if(Operators[i].Processes.indexOf("2") > -1){
                Operators.splice(i, 1);
            }

        }
        var Jobs = await databaseRequest("/jobs?CompletedDateTime=-1","GET");
        var Contacts = await databaseRequest("/contacts","GET");
        var Timesheets = await databaseRequest("/timesheet/active", "GET");
        databaseRequest(`/schedule`,"GET").then((data)=>{
            if(typeof data.error !== 'undefined'){
                t.setState({
                    loading: false,
                    Operators:Operators,
                    Companies:Companies,
                    Contacts:Contacts,
                    Timesheets:Timesheets,
                    Jobs:Jobs
                })
            }
            else{
                t.setState({
                    loading: false,
                    data: data,
                    Operators:Operators,
                    Companies:Companies,
                    Contacts:Contacts,
                    Timesheets:Timesheets,
                    Jobs:Jobs
                })
            }
        });
    }
    componentDidMount() {
        let t=this;
        const {socketServer, setPage} = this.props;
        setPage("Operator Jobs");
        /*t.socket = io(socketServer);
        t.socket.on('connect', ()=>{
            t.socket.on('server-update', ()=>{
                if(!t.state.editing){
                    t.reload(false);
                }  
            });
        });*/
        this.reload(true);
    }
    componentWillUnmount(){
        let t=this;
        if(t.socket!=null){
            t.socket.disconnect();
        }
    }
    OperatorName = (e)=>{
        const {Operators} = this.state;
        for(let i=0; i<Operators.length; i++){
            if(Operators[i].Id===e){
                return (Operators[i].Name);
            }
        }
        return "";
    }
    OperatorFirstName = (e)=>{
        const {Operators} = this.state;
        for(let i=0; i<Operators.length; i++){
            if(Operators[i].Id===e){
                return (Operators[i].Name).split(' ')[0];
            }
        }
        return "";
    }
    CompanyName = (e)=>{
        const {Companies} = this.state;
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===e){
                return (Companies[i].Name);
            }
        }
        return "";
    }
    ContactName = (e)=>{
        const {Contacts} = this.state;
        for(let i=0; i<Contacts.length; i++){
            if(Contacts[i].Id===e){
                return (Contacts[i].Name);
            }
        }
        return "";
    }
    JobName = (e)=>{
        const {Jobs} = this.state;
        for(let i=0; i<Jobs.length; i++){
            if(Jobs[i].Id===e){
                return (<React.Fragment>[{this.CompanyName(Jobs[i].CompanyId)}] {Jobs[i].Name}</React.Fragment>);
            }
        }
        return "";
    }
    JobNameShort = (e, OperatorId) =>{
        const {Jobs,Timesheets} = this.state;
        var JobName = "";
        var JobUrgent = false;
        var Client = ""
        var Name = "";
        var Guid = "";
        var len=45;
        for(let i=0; i<Jobs.length; i++){
            if(Jobs[i].Id===e){
                JobUrgent = Jobs[i].IsUrgent;
                Guid = Jobs[i].Guid;
                Name = Jobs[i].Name;
                if(Jobs[i].CompanyId===-2){
                    Client = this.ContactName(Jobs[i].ContactId);
                    JobName = `[${this.ContactName(Jobs[i].ContactId)}] ${Jobs[i].Name}`;
                }
                else{
                    Client = this.CompanyName(Jobs[i].CompanyId);
                    JobName = `[${this.CompanyName(Jobs[i].CompanyId)}] ${Jobs[i].Name}`;
                }
            }
        }
        var AbbClient = "";
        var ClientSplit = Client.split(" ");
        if(ClientSplit.length==1){
            AbbClient = ClientSplit[0];
        }
        else{
            for(let i=0; i<ClientSplit.length; i++){
                AbbClient += ClientSplit[i].charAt(0);
            }
        }
        //JobName = `[${AbbClient.toUpperCase()}] ${Name}`;


        var ActiveJob = false;
        for(let i=0; i<Timesheets.length; i++){
            if(Timesheets[i].JobId==e && Timesheets[i].OperatorId==OperatorId){
                ActiveJob=true;
            }
        }



        if(JobName.length > len){
            JobName = JobName.substr(0,len-3)+"...";
        }
        if(ActiveJob){
            return (<span  className="rounded bg-success font-weight-bold text-white p-1">{JobName}, </span>);
        }
        if(JobUrgent){
            return (<span  className="rounded bg-danger font-weight-bold text-white p-1">{JobName}, </span>);
        }
        else{
            return (<span  className="font-weight-bold p-1">{JobName}, </span>);
        }
        
    }
    ProcessName = (e)=>{
        const {processes} = this.state;
        for(let i=0; i<processes.length; i++){
            if(processes[i].id===e){
                return (processes[i].name);
            }
        }
        return "";
    }
    arrayUnique = (array)=>{
        var a = array.concat();
        for(var i=0; i<a.length; ++i) {
            for(var j=i+1; j<a.length; ++j) {
                if(a[i] === a[j])
                    a.splice(j--, 1);
            }
        }
    
        return a;
    }
    table = () => {
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading schedule...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                var Operators = this.state.Operators;
                var Processes = this.state.data;
                
                
                for(let i=0; i<Operators.length; i++){
                    Operators[i].Jobs = [];
                    for(let j=0; j<Processes.length; j++){
                        for(let k=0; k<Processes[j].Operators.length; k++){
                            if(Processes[j].Operators[k].OperatorId===Operators[i].Id){
                                Operators[i].Jobs = Operators[i].Jobs.concat(Processes[j].Operators[k].Jobs.Urgent);
                                Operators[i].Jobs = Operators[i].Jobs.concat(Processes[j].Operators[k].Jobs.NextInLine);
                                Operators[i].Jobs = Operators[i].Jobs.concat(Processes[j].Operators[k].Jobs.Muted);
                            }
                        }
                    }
                }

            

                
                for(let i=0; i<Operators.length; i++){
                    Operators[i].Jobs = this.arrayUnique(Operators[i].Jobs);                 
                }
                return (
                    <table className="table table-striped ">
                    <tbody>
                    {Operators.map((Operator, i) => {    
                    return (
                        <React.Fragment  key={i}>
                        <tr><td className="text-left" style={{width: "6.00%"}}><i className="blockquote-footer text-dark">{this.OperatorFirstName(Operator.Id)}</i></td>
                        <td>
                        {
                            Operator.Jobs.map((JobId, j)=>{
                                return(
                                <span key={j} className="link" data-toggle="modal" data-target="#jobModal" onClick={()=>{this.loadModal(Operator.Id, JobId)}} >
                                    {this.JobNameShort(JobId, Operator.Id)}
                                </span>
                                )
                            
                            })
                        }                
                        
                        </td>
                        </tr>
                        </React.Fragment>);
                    
                    })}
                    </tbody>
                    </table>
                )
            }
    }
    render(){
        if(this.state.redirect.length>1){
            this.props.history.replace(this.props.location.pathname)
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        const query = queryString.parse(this.props.location.search);
        var fullscreen = false;
        if(typeof query.fullscreen!=='undefined'){
            fullscreen = query.fullscreen==="true"?true:false;
        }
        return (
            <main role="main" className={fullscreen?"p-5":"p-5 pt-6"}>
                
                {fullscreen?(<React.Fragment></React.Fragment>):(
                    
                <div className="row">
                    <div className="col-mx-auto">
                    <h2><i className="fe fe-list"></i> Operator Schedule</h2>
                    </div>
                    <div className="col">
                    <ReactToPrint
                        trigger={() => {
                            return <button type="button" className="btn btn-secondary float-right ml-2 mb-3">Print</button>
                        }}
                        content={() => this.componentRef}
                    />
                    <div style={{ display: "none" }}><JobSheetPrint {...this.props} ref={el => (this.componentRef = el)} /></div>
                    </div>
                
                </div>
                )}
               
                <div className="row">
                    {this.table()}

                </div>
                <div className="modal" id="jobModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title">{this.state.jobModal.OperatorName} - [{this.state.jobModal.Id}] {this.state.jobModal.JobName}</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {!this.state.jobModal.Loading?(
                            <React.Fragment>
                                <div className="modal-body">                                
                                    {this.steps()}                               
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-info float-left" data-dismiss="modal" onClick={()=>{this.redirect(`/jobs/${this.state.jobModal.Id}?return=${this.props.location.pathname}`)}}>Job Sheet</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>{this.saveBtn(this.state.jobModal.Id)}}>OK</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                                </div>
                            </React.Fragment>
                        ):(
                            <div className="modal-body">
                                <p>Loading...</p>
                            </div>                      
                        )}
                        </div>
                    </div>
                </div>
            </main>

            
        )
    }
}

export default OperatorsAll;