import React, { Component } from 'react';
import {Link} from "react-router-dom";
class OperatorsAll extends Component {
    state = {
        loading: true,
        search: "",
        data: [],
        entities: [],
        processes: [
            { id: 0, name: "Drafting", selected: false},
            { id: 1, name: "Material Prep.", selected: false},
            { id: 2, name: "Laser", selected: false},
            { id: 3, name: "Turning", selected: false},
            { id: 4, name: "Milling", selected: false},
            { id: 5, name: "Grinding", selected: false},
            { id: 6, name: "Fitting", selected: false},
            { id: 7, name: "Folding", selected: false},
            { id: 8, name: "Pressing", selected: false},
            { id: 9, name: "Welding", selected: false},
            { id: 10, name: "Supply", selected: false},
            { id: 11, name: "Hypermill", selected: false}
        ],
    }
    onSearch = (event) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        var search = event.target.value;
        databaseRequest(`/operators?Name:like=%25${search}%25&Id:not=0`,"GET").then((data)=>{
            if(typeof data.error !== 'undefined'){
                this.setState({
                    loading: false
                })
            }
            else{
                this.setState({
                    loading: false,
                    data: data
                })
            }

        });
    }
    componentDidMount = () =>{
        const {databaseRequest,setPage}=this.props;
        let t =this;
        setPage(`Operators`);
        databaseRequest("/operators?Id:not=0","GET").then((data)=>{
            if(typeof data.error !== 'undefined'){
                this.setState({
                    loading: false
                })
            }
            else{
                this.setState({
                    loading: false,
                    data: data
                })
            }
        });
    }
    componentWillUnmount = () =>{
        clearInterval(this.state.interval);
    }
    toProcessList = (str)=>{
        const {processes} = this.state;
        var json = str.toString();
        var retStr = "";
        try{
            var data = JSON.parse(json);
            for(let k=0; k<processes.length; k++){
                for(let i=0; i<data.length; i++){
                    if(processes[k].id===data[i]){
                        retStr += processes[k].name+", ";
                    }
                }
            }
            retStr = retStr.substring(0,retStr.length-2);
            if(retStr.length > 50){
                retStr = retStr.substring(0,47)+"...";
            }
            return retStr;
        }
        catch(e){
            return "-";
        }
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading operators...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col" style={{width: '20px'}}>#</th>
                        <th scope="col" style={{width: '20px'}}></th>
                        <th scope="col">Operator Name</th>
                        <th scope="col">Processes</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        return (
                        <tr key={i}>
                            <th scope="row" ><Link to={`/system/operators/${row.Id}`}>{1+i}</Link></th>
                            <td><Link to={`/timesheet/${row.Id}?return=${this.props.location.pathname}`}><i className="fe fe-clock link"></i></Link></td>
                            <td><Link to={`/system/operators/${row.Id}`}>{row.Name}</Link></td>
                            <td>{this.toProcessList(row.Processes)}</td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-user-check"></i> All Operators</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input className="form-control mr-sm-2" type="search" onChange={this.onSearch} placeholder="Search" aria-label="Search"/>                      
                        </form>
                    </div>
                    <div className="col p-0">
                        <Link to="/system/operators/-1"><button type="button" className="btn btn-success float-right">New Operator</button></Link>
                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
            </main>

            
        )
    }
}

export default OperatorsAll;