import React, { Component } from 'react';
import { Link } from "react-router-dom";
import NavItem from './NavItem.js'
import NavDropdown from './NavDropdown.js';
import NavDropdownItem from './NavDropdownItem.js';

class NavBar extends Component {
    componentDidMount = () =>{
        const {setPage}=this.props;
        setPage("Home");
    }
    inUserList = (arr) =>{
        var UserId = localStorage.getItem('user');
        //UserId = 8; Test users here
        var found = false;
        for(let i=0; i<arr.length; i++){
            if(arr[i]==UserId || arr[i]==-1){
                found=true;
            }
        }
        return found;
    }
    AdminView = ()=>{
        const {props}=this;
        const access = localStorage.getItem("access");
        
        return (
            <React.Fragment>
                {this.inUserList([-1])?(  
                  <NavItem name="Dashboard" to="/home" icon="trending-up"/>
                ):<></>}   

                       
                <NavDropdown name="Schedule" to="/schedule" icon="list" {...props}>
                {this.inUserList([2,3,7])?(
                    <>
                    <NavDropdownItem name="Urgent Job Sheet" to="/schedule/job-sheet/urgent"  icon="list"/>
                    <NavDropdownItem name="Job Sheet" to="/schedule/job-sheet"  icon="list"/>
                    <NavDropdownItem name="Laser Jobs" to="/schedule/laser-jobs"  icon="list"/>
                    <NavDropdownItem name="Operator Job List" to="/schedule/operator-processes"  icon="list"/>
                    </>
                ):<></>}
                    <NavDropdownItem name="Machining List" to="/schedule/process-list/machining"  icon="list"/>
                    <NavDropdownItem name="Fabrication List" to="/schedule/process-list/fabrication"  icon="list"/>
                    <NavDropdownItem name="Welding List" to="/schedule/process-list/welding"  icon="list"/>
                    <NavDropdownItem name="Job List" to="/schedule/job-list" icon="list"/>
                </NavDropdown>
                 

                {this.inUserList([2,3,7])?(  
                <NavItem name="Urgent Jobs" to="/urgent" icon="bell"/> 
                ):<></>}
                {this.inUserList([-1])?(  
                <NavDropdown name="Jobs" to="/jobs" icon="flag" {...props}>
                    <NavDropdownItem name="Active Jobs" to="/jobs-active"  icon="minus-circle"/>

                    <NavDropdownItem name="New Job" to="/jobs/-1?view=active"  icon="plus-circle"/>
                    <NavDropdownItem name="Outstanding Jobs" to="/jobs-outstanding"  icon="x-circle"/>
                   

                    <NavDropdownItem name="Requires Quoting" to="/jobs-quoting"  icon="activity"/>
                    <NavDropdownItem name="Requires Scheduling" to="/jobs-scheduling"  icon="activity"/>
                    <NavDropdownItem name="Workshop Jobs" to="/jobs-workshop"  icon="activity"/>


                    <NavDropdownItem name="Requires Invoicing" to="/jobs-invoicing"  icon="activity"/>
                    <NavDropdownItem name="Completed Jobs" to="/jobs-completed"  icon="check-circle"/>

                </NavDropdown>

                ):<></>}
                {this.inUserList([-1])?(  
                <NavItem name="Search" to="/search" icon="search"/>  
                ):<></>}
                {this.inUserList([2,3,6,7])?(  
                <NavDropdown name="System" to="/system" icon="cpu" {...props}>
                    <NavDropdownItem name="Operators" to="/system/operators"  icon="users"/>
                    <NavDropdownItem name="Companies" to="/system/companies"  icon="edit"/>
                    <NavDropdownItem name="Log" to="/system/log/0"  icon="clock"/>
                    {/*<NavDropdownItem name="Design" to="/issues/registry"  icon="edit"/>
                    <NavDropdownItem name="Processes" to="/issues/locations"  icon="shuffle"/>
                    <NavDropdownItem name="Material" to="/issues/transfer"  icon="layers"/>
                    <NavDropdownItem name="Equipment" to="/issues/transfer"  icon="server"/>
                    <NavDropdownItem name="Operators" to="/operators"  icon="users"/>*/}
                </NavDropdown>
                ):<></>}
                {this.inUserList([-1])?(    
                <NavItem name="Phone Book" to="/phonebook" icon="phone"/>  
                 ):<></>}   
                {this.inUserList([2,3,4,6,7])?(   
                <NavItem name="Suppliers" to="/suppliers" icon="shopping-cart"/>  
                ):<></>}
                {this.inUserList([2,3,4,6,7])?(
                <NavDropdown name="Purchasing" to="/purchasing" icon="shopping-cart" {...props}>
                    <NavDropdownItem name="Purchase Orders" to="/purchasing"  icon="dollar-sign"/>
                    <NavDropdownItem name="Suppliers" to="/suppliers" icon="shopping-cart"/>  

                </NavDropdown>
                ):<></>}


                
                {this.inUserList([2,7])?(
                <NavDropdown name="Timesheet" to="/timesheet" icon="clock" {...props}>
                    <NavDropdownItem name="Enter Timesheet" to="/timesheet/manual"  icon="file-plus"/>
                    <NavDropdownItem name="Search" to="/timesheet/search"  icon="search"/>
                </NavDropdown>
                ):<></>}

                {this.inUserList([2])?(<NavDropdown name="Users" to="/users" icon="users" {...props}>
                    <NavDropdownItem name="All Users" to="/users/all"  icon="users"/>
                    <NavDropdownItem name="Account" to="/users/account"  icon="user-check"/>
                </NavDropdown>):<></>}



                {this.inUserList([2, 3, 5, 6, 7])?(
                <NavDropdown name="Laser" to="/laser/complete" icon="sunset" {...props}>
                    {this.inUserList([2, 5])?(<NavDropdownItem name="For Processing" to="/laser/processing"  icon="shuffle"/>):<></>}
                    <NavDropdownItem name="Quote" to="/laser/quote"  icon="dollar-sign"/>
                    <NavDropdownItem name="Complete" to="/laser/complete"  icon="check-circle"/>
                    {this.inUserList([2, 5])?(<NavDropdownItem name="Cut | Not Priced" to="/laser/not-priced"  icon="archive"/>):<></>}
                    {this.inUserList([2, 5])?(<NavDropdownItem name="Search Nests" to="/nests"  icon="search"/>):<></>}
                    {this.inUserList([2,3,5])?(<NavDropdownItem name="Price List" to="/laser/settings"  icon="settings"/>):<></>}
                </NavDropdown>
                ):<></>}



            </React.Fragment>
        )
    }
    AccessNavBar = ()=>{
        const access = localStorage.getItem("access");
        return this.AdminView();
        /*switch(access){
            case "15":
                return this.LaserView();
            break;
            case "20":
                return this.SchedulerView();
            break;
            case "50":
                return this.ManagementView();
            break;
            case "100":
                return this.AdminView();
            break;
            case "200":
                return this.AlexView();
            break;
        }*/
    }
    render (){
        const {props}=this;
        
        if(!this.props.visible && (window.location.pathname==="/" || window.location.pathname==="/home")){
            return (<div/>)
        }
        return (
        <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-dark bd-navbar">
        <Link className="navbar-brand" to="/"><img src="/logo-lange.svg" alt="" width="60" height="40" /></Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav mr-auto">
                {this.AccessNavBar()}
            </ul>
            <ul className="navbar-nav">
                <NavItem name="Logout" to="/logout" icon="log-out"/>
            </ul>
        </div>
        </nav>
        );
    }
}
export default NavBar;