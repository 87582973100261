import React, { Component } from 'react';
import {Redirect} from "react-router-dom";
import TimeAgo from '../../Components/TimeAgo.js';

class OperatorsAll extends Component {
    state = {
        loading: true,
        redirect: "",
        data: [],
    }
    componentDidMount = async () =>{
        const {databaseRequest,setPage}=this.props;
        let t =this;
        setPage(`Controllers`);
        var Operators = await databaseRequest("/operators?Id:not=0", "GET");
        Operators.unshift({
            Id: -1,
            Name: "Not Allocated",
        })
        databaseRequest("/c/controllers","GET").then((data)=>{
            if(typeof data.error !== 'undefined'){
                this.setState({
                    Operators: Operators,
                    loading: false
                })
            }
            else{
                this.setState({
                    loading: false,
                    Operators: Operators,
                    data: data
                })
            }
        });
    }
    OperatorChange = async (e, Id) =>{
        const {databaseRequest, params}=this.props;
        var {data} = this.state;
        let t = this;

        for(let i=0; i<data.length;i++){
            if(data[i].Id==Id){
                data[i].OperatorId=e.target.value;
            }
        }
        
        t.setState({
            loading: true,
            data: data,
            loadingMsg: `Updating operator...`
        });
        var req = {
            url: `/c/controllers/${Id}/operator/${e.target.value}`,
            method: "PUT"
        }
        await databaseRequest(req.url,req.method,JSON.stringify({}));
        setTimeout(()=>{
            t.setState({
                loading: false,
                data: data,
                redirect: '/redirector?r=controllers',
                loadingMsg: `Updating operator...`
            });
        }, 500);
    }
    componentWillUnmount = () =>{
        clearInterval(this.state.interval);
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading operators...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col" style={{width: '20px'}}>#</th>
                        <th scope="col">MAC Address</th>
                        <th scope="col">Operator</th>
                        <th scope="col">Last Accessed</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        var now = new Date().getTime();
                        return (
                        <tr key={i}>
                            <th scope="row">{1+i}</th>
                            <td>{row.MAC}</td>
                            <td>
                                <select className="form-control" value={row.OperatorId} onChange={(e)=>{this.OperatorChange(e, row.Id)}}>
                                    {this.state.Operators.map((Operator,i) => {
                                        return (<option key={i} value={Operator.Id}>{Operator.Name}</option>)
                                    })}
                                    
                                </select>
                            </td>
                            <td><TimeAgo time={row.LastAccessed} lowRes={true} /></td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-hard-drive"></i> All Controllers</h2>
                <div className="row">
                    {this.table()}

                </div>
            </main>

            
        )
    }
}

export default OperatorsAll;