import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import queryString from "query-string"

class Timesheet extends Component {
    interval = null;

    state = {
        loading: true,
        loadingMsg: "Loading users...",
        redirect: "",
        Error: "",
        JobFound: false,
        JobStarted: false,
        JobPaused: false,
        JobInitiated: false,
        JobGuid: "",
        JobDuration: 0,
        Job: null,
        data:{ 
            JobId: -1,
        }
    }
    onChange = (e) => {
        let data = this.state.data;
        data[e.target.name]=e.target.value;
        this.setState({ data: data});  
    };

    
    onSearchClick = async (e)=>{
        const {databaseRequest}=this.props;
        var Job = await databaseRequest(`/jobs/${this.state.data.JobId}`,"GET");
        if(typeof Job.Id!=='undefined'){
            var Company = await databaseRequest(`/companies/${Job.CompanyId}`,"GET");
            var Contact = await databaseRequest(`/contacts/${Job.ContactId}`,"GET");
            this.setState({ 
                JobFound: true, 
                Job: Job,
                Company: Company,
                Contact: Contact
            });         
        }
        else{
            this.setState({ JobFound: false}); 
        }

    }
    timesheetStart = async () =>{
        const {databaseRequest, params}=this.props;
        var error = await databaseRequest(`/timesheet/${params.OperatorId}/${this.state.Job.Id}/start`, "POST");
        this.setState({JobStarted: true, JobPaused: false})
    }
    timesheetPause = async () =>{
        const {databaseRequest, params}=this.props;
        var error = await databaseRequest(`/timesheet/${params.OperatorId}/${this.state.Job.Id}/pause`, "POST");
        this.setState({JobPaused: true})
    }
    timesheetStop = async () =>{
        const {databaseRequest, params}=this.props;
        var error = await databaseRequest(`/timesheet/${params.OperatorId}/${this.state.Job.Id}/stop`, "POST");
        this.setState({
            JobStarted: false, 
            JobPaused: false,
            redirect: "/redirector?r=timesheet/"+params.OperatorId
        
        })
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    
    async componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        var OperatorId = params.OperatorId;
        var Operator = await databaseRequest(`/operators/${OperatorId}`,"GET");
        if(Operator.status==200){
            if(Operator.TimesheetJobId!==-1){
                var Job = await databaseRequest(`/jobs/${Operator.TimesheetJobId}`,"GET");
                var Company = await databaseRequest(`/companies/${Job.CompanyId}`,"GET");
                var Contact = await databaseRequest(`/contacts/${Job.ContactId}`,"GET");

                var Timesheet = await databaseRequest(`/timesheet/${OperatorId}/${Operator.TimesheetJobId}/${Operator.TimesheetGuid}`,"GET");
                if(Timesheet.length > 0){
                    var LastRecord = Timesheet[Timesheet.length-1];
                    var now = new Date().getTime();
                    var Duration = 0;
                    for(let i=0; i<Timesheet.length; i++){
                        if(Timesheet[i].Duration > 0){
                            Duration += Math.floor(Timesheet[i].Duration/1000);
                        }
                    }

                    if(Operator.TimesheetStarted===1 && Operator.TimesheetPaused!==1){
                        Duration += Math.floor((now - LastRecord.CreatedDateTime)/1000);
                    }
                    t.setState({
                        JobFound: true, 
                        Job: Job,
                        Timesheet: Timesheet,
                        Company: Company,
                        Contact: Contact,
                        JobDuration: Duration,
                        JobStarted: Operator.TimesheetStarted===1,
                        JobPaused: Operator.TimesheetPaused===1,
                        JobInitiated: true,
                        JobGuid: Operator.TimesheetGuid,

                        data: {
                            JobId: Job.Id
                        }
                    });
                }
            }
            t.setState({
                Operator: Operator,
                loading: false,
                CheckDate: new Date().getTime(),
                CheckDateString: new Date().toLocaleString()
            });

            t.interval = setInterval(()=>{
                if(!t.state.JobInitiated && t.state.JobStarted){
                    t.setState({JobInitiated:true, JobStartTime: new Date().getTime()});
                }

                if(t.state.JobInitiated && t.state.JobStarted && !t.state.JobPaused){
                    let JobDuration = t.state.JobDuration;
                    JobDuration++;
                    t.setState({JobDuration:JobDuration});
                }

            }, 1000);
        }
        else{
            t.setState({loading: false, Error:"Operator not found"});
        }
    }
    secondsToTime = (time)=>{
        var seconds = time%60;
        var minutes = ((time - seconds)/60)%60;
        var hours = (time-seconds-(minutes*60))/3600;
        

        return `${hours<10?"0"+hours:hours}:${minutes<10?"0"+minutes:minutes}:${seconds<10?"0"+seconds:seconds}`
    }
    componentWillUnmount(){
        clearInterval(this.interval);    
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    error = ()=>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.Error}</h1>
                    <div>
                        <Link to="/home"><button type="button" className="btn btn-secondary">Home</button></Link>
                    </div>
                </div>
            </main>
        )
    }
    page = () =>{
        const {props} = this;
        const query = queryString.parse(this.props.location.search);
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.Error.length>1){
            return this.error();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }console.log(this.state);
        return (
            
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-user"></i> {this.state.Operator.Name}</h2>
                <div className="row form-group">
                    <div className="col col-md-2">
                        <input type="text" className="form-control" autoComplete="nofill" placeholder="Job Id" name="JobId" disabled={this.state.JobStarted} value={this.data("JobId")==-1?"":this.data("JobId")} onChange={this.onChange}/>    
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-success" onClick={this.onSearchClick} disabled={this.state.JobStarted}>Search</button>
                    </div>
                    {this.state.JobFound?(
                    <React.Fragment>
                        <div className="col">
                            <h1 className="text-center">[{this.state.Job.Name}]</h1>
                            <h2 className="text-center">{this.state.Company.Id==-2?this.state.Contact.Name:this.state.Company.Name}</h2>
                        </div>
                        <div className="col">
                            <h1 className="text-right" style={{fontSize:'5rem'}}>{this.secondsToTime(this.state.JobDuration)}</h1>
                        </div>
                    </React.Fragment>
                    ):(
                        <React.Fragment></React.Fragment>
                    )}
                </div>
                <div className="row form-group">
                    <div className="col">
                        <button type="button" className="btn btn-success" disabled={!this.state.JobFound || (this.state.JobStarted && !this.state.JobPaused)} onClick={this.timesheetStart}>Start</button>
                        <button type="button" className="btn btn-warning ml-2" disabled={!this.state.JobFound || (!this.state.JobStarted || this.state.JobPaused)} onClick={this.timesheetPause}>Pause</button>
                        <button type="button" className="btn btn-danger ml-2" disabled={!this.state.JobFound || !this.state.JobStarted} onClick={this.timesheetStop}>Stop</button>
                    </div>
                    <div className="col">
                        {
                        typeof query.return === 'undefined'?
                        (
                            <Link to="/home"><button type="button" className="btn btn-info float-right">Back</button></Link> 
                        ):
                        (
                            <Link to={query.return}><button type="button" className="btn btn-info float-right">Back</button></Link> 
                        )}
                        
                    </div>
                </div>
            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default Timesheet;