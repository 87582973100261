import React, { useState, useCallback, useEffect } from 'react';
import {Link, Redirect} from "react-router-dom";
import { DatabaseRequest } from '../../Components/DatabaseRequest';
import Pricing from './Pricing';

export const PricingEdit = () => {
    const HISTORY_GUID = "6b47366b-a689-dc94-84b9-1c3b575b9ff8";
    const [data, setData] = useState([]);
    const [focusField, setFocusField] = useState(-1);
    const [editField, setEditField] = useState(0);

    useEffect(async () => {
        await Pricing.loadPricing();
        setData(Pricing.Material);
    }, []);

    function onFieldFocus(e, field, value){
        setFocusField(field); 
        setEditField(value);
        setTimeout(()=>{
            e.target.select(); 
        }, 100);
    }
    function modifyMaterialRate(key){
        var newValue = Number(editField);
        var newData = data;
        if(!isNaN(newValue)){
            for(var i=0; i<newData.length; i++){
                if(newData[i].Key==key){
                    newData[i].PricePerKg = newValue;
                    saveCuttingRates(newData);
                }
            }
        }
    }
    function modifyCuttingRate(key,thickness){
        var newValue = Number(editField);
        var newData = data;

        if(!isNaN(newValue)){
            for(var i=0; i<newData.length; i++){
                if(newData[i].Key==key){
                    for(var j=0; j<newData[i].CuttingRates.length; j++){
                        if(newData[i].CuttingRates[j].t==thickness){
                            newData[i].CuttingRates[j].r = newValue;
                            saveCuttingRates(newData);
                        }
                    }
                }
            }
        }
    }
    async function saveCuttingRates(newData){
        await DatabaseRequest("/laser", "POST", JSON.stringify(newData));
        await Pricing.loadPricing();
        setData(Pricing.Material);
    }

    return (<>
             <main role="main" className="container">
                <div className="p-5">
                <h2 className='mb-5'>
                    <i className="fe fe-settings"></i> Laser Price List                
                <Link to={`/history/${HISTORY_GUID}/${btoa(window.location.pathname)}`}>
                    <button type="button" className="btn btn-dark float-right">History</button>
                </Link>
                </h2>                            

                <div className="row form-group">
                    <table className='table'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Material</th>
                                <th>Density (kg/m³)</th>
                                <th className="text-right">Rate</th>
                                <th className="text-right">Standard Sheet (mm x mm)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row,i)=>{
                                var f1 = (i*100);
                                var value = f1==focusField?editField:"$ "+row.PricePerKg.toFixed(2)+" /kg";
                                return (
                                <>
                                <tr style={{backgroundColor: 'rgb(233, 235, 237)'}}>
                                    <td>{row.Name}</td>
                                    <td>{row.Density}</td>
                                    <td className="text-right"><input className='form-control text-right float-right' style={{width: "6rem"}} value={value} onChange={(e)=>{setEditField(e.target.value)}} onBlur={()=>{setFocusField(-1); modifyMaterialRate(row.Key);}} onFocus={(e)=>{onFieldFocus(e, f1, row.PricePerKg.toFixed(2))}}/></td>
                                    <td className="text-right">{row.StandardSheet.width} x {row.StandardSheet.height}</td>
                                </tr>
                                <tr>
                                    <th colSpan={4} className="text-center">Cutting Rates</th>
                                </tr>
                                {row.CuttingRates.map((rate,j)=>{
                                    var f2 = (i*100)+(j+1);
                                    var value = f2==focusField?editField:"$ "+rate.r.toFixed(2)+" /min";
                                    return (<tr>
                                        <td colSpan={2} className="text-right pt-4">Up to {rate.t.toFixed(1)}mm</td>
                                        <td className="text-right">
                                            <input className='form-control text-right float-right' style={{width: "6rem"}} value={value} onChange={(e)=>{setEditField(e.target.value)}} onBlur={()=>{setFocusField(-1); modifyCuttingRate(row.Key, rate.t)}} onFocus={(e)=>{onFieldFocus(e, f2, rate.r.toFixed(2));}}/>
                                        </td>
                                    </tr>)
                                })}
                                </>);
                            })}
                        </tbody>
                    </table>                   
                </div>
                </div>
            </main>

    </>);

}