import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import {ContactForm} from '../Contacts/ContactForm.js'

class UsersEdit extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading supplier...",
        redirect: "",
        processes: [],
        SupplierContacts: [],
        data: null
    }
    onChange = (e) => {
        let data = this.state.data;
        data[e.target.name]=e.target.value;
        data.Number = this.phoneNumberFormat(data.Number);
        data.Number2 = this.phoneNumberFormat(data.Number2);
        this.setState({ data: data});  
    };
    saveBtn = async () =>{
        const {databaseRequest,params,setPage}=this.props;
        const {SupplierContacts} = this.state;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: `Saving ${this.state.data.Name}...`
        });
        var data = this.state.data;
        var req = {
            url: `/suppliers/${params.SupplierId}`,
            method: "PUT"
        }
        if(params.SupplierId==="-1"){
            req.url="/suppliers";
            req.method="POST";
        }

        databaseRequest(req.url,req.method, 
            JSON.stringify(data)
        ).then((data)=>{
            setTimeout(async function(){
                setPage(`${data.Name}`);
                for(var i=0;i<SupplierContacts.length; i++){
                    

                    //if Id valid update, if not create
                    var req = {
                        url: `/contacts`,
                        method: "POST"
                    }
                    if(SupplierContacts[i].Id > -1){
                        req.url = `/contacts/${SupplierContacts[i].Id}`;
                        req.method = `PUT`;
                    }
                    var newContact = {...SupplierContacts[i]};
                    delete newContact.Id;
                    delete newContact.Checked;
                    newContact.IsHidden = 0;
                    newContact.CompanyId = -1;
                    newContact.SupplierId = data.Id;
                    var returnedContact = await databaseRequest(req.url,req.method,JSON.stringify(newContact));
                    console.log(returnedContact);
                }

                t.setState({
                    loading: false,
                    loadingMsg: "Loading suppliers...",
                    redirect: "/system/suppliers",
                    data: data
                });
            }, 250);
        });
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    deleteBtn = () =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("Name")+"..."
        });
        databaseRequest(`/suppliers/${params.SupplierId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading supplier...",
                    redirect: "/system/suppliers"
                });
            }, 1000);
        });

    }
    addContactPerson = ()=>{
        var {SupplierContacts} = this.state;
        SupplierContacts.push({Name: ""});
        this.setState({SupplierContacts: SupplierContacts});
    }
    OnContactUpdated = (i, data)=>{
        var {SupplierContacts} = this.state;
        if(typeof SupplierContacts[i] !== 'undefined'){
            SupplierContacts[i] = {...SupplierContacts[i], ...data};
        }
        this.setState({SupplierContacts: SupplierContacts});
    }
    HideContact = async (ContactId) =>{
        const {databaseRequest}=this.props;
        let t = this; 
        var req = {
            url: `/contacts/${ContactId}`,
            method: "PUT"
        }
        var {SupplierContacts} = this.state;
        var Contact = {};
        for(let i=0; i<SupplierContacts.length; i++){
            if(SupplierContacts[i].Id===ContactId){
                Contact = SupplierContacts[i];
                Contact.IsHidden = 1;
            }
        }

        if(ContactId!==-1){
            var result = await databaseRequest(req.url,req.method,JSON.stringify(Contact));
        }
        
        this.reload();
    }
    reload = async ()=>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        setPage(`Suppliers`);
        if(params.SupplierId==="-1"){
            t.setState({
                data:{
                    Name: "",
                    Number: "",
                    Number2: "",
                    Address: ""
                },
                loadingMsg: "Loading suppliers...",
                loading: false
            });
        }
        else{
            var contacts = await databaseRequest("/contacts?SupplierId="+params.SupplierId+"&IsHidden=0","GET");

            databaseRequest(`/suppliers/${params.SupplierId}`,"GET").then((data)=>{
                    setPage(`${data.Name}`);
                    t.setState({
                        loading: false,
                        data: data,
                        SupplierContacts: contacts
                    });
            });
        }
    }

    componentDidMount(){
        this.reload();
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    phoneNumberFormat = (num)=>{
        
        if(num.length > 2){
            if(num.substring(0,2)=="04" && num.length>4){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
                if(num.length > 8){
                    if(num.charAt(8)!=" "){
                        num = num.substring(0,8)+" "+num.substring(8,num.length);
                    }
                }
            }
            if(num.charAt(0)!="0" && num.length>4 ){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
            }
            if(num.substring(0,2)=="03"){
                if(num.charAt(2)!=" "){
                    num = num.substring(0,2)+" "+num.substring(2,num.length);
                }
                if(num.length > 7){
                    if(num.charAt(7)!=" "){
                        num = num.substring(0,7)+" "+num.substring(7,num.length);
                    }
                }
            }
        }
        return num;
    }
    page = () =>{
        const {props} = this;
        const {SupplierContacts} = this.state;
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-shopping-cart"></i> Suppliers</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">{this.data("Name").length==0?"--New Supplier--":this.data("Name")}</h1>
                    </div>
                    <div className="col p-0">
                        <button type="button" className="btn btn-info float-right" onClick={()=>{this.props.history.goBack()}}>Back to all Suppliers</button>
                    </div>
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label>Name</label>
                            <input type="text" className="form-control" placeholder="Name" name="Name" value={this.data("Name")} onChange={this.onChange}/>
                        </div>    


                        <div className="form-group col-md-2">
                            <label>Phone Number</label>
                            <input type="text" className="form-control" placeholder="Phone Number" name="Number" value={this.data("Number")} onChange={this.onChange}/>
                        </div>                
                        <div className="form-group col-md-2">
                            <label>Additional Number</label>
                            <input type="text" className="form-control" placeholder="Additional Number" name="Number2" value={this.data("Number2")} onChange={this.onChange}/>
                        </div>        
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-5">
                            <label>Address</label>
                            <input type="text" className="form-control" placeholder="Address" name="Address" value={this.data("Address")} onChange={this.onChange}/>
                        </div>    
                        <div className="form-group col d-flex align-items-end flex-column align-self-end">
                                <button type="button" className="btn btn-info"  onClick={this.addContactPerson}><i className="fe fe-user-plus"></i> Add Contact Person</button>
                            </div>
                    </div>
                    {SupplierContacts.map((row, i)=>{
                            return (
                            <div className="form-row" key={i}>
                                <ContactForm inputData={row} labels={i==0} index={i} onSave={this.OnContactUpdated}/>
                                    <div className="form-group col-md-1 align-self-end">
                                        <button type="button" className="btn btn-danger" onClick={()=>{this.HideContact(row.Id)}}><i className="fe fe-x"></i></button>
                                    </div>
                            </div>
                            
                        );
                    })}


                    <div  className="row">
                        <div className='col'>

                        
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            {this.props.params.UserId!=="-1"?(
                            <button type="button" className="btn btn-success" onClick={this.saveBtn}>Save</button>
                            ):(
                            <button type="button" className="btn btn-success" disabled={!this.state.buttonState} onClick={this.saveBtn}>Create</button>
                            )}
                            {this.props.params.UserId!=="-1"?(
                            <button type="button" className="btn btn-danger ml-2" data-toggle="modal" data-target="#myModal" >Delete</button>
                            ):(<div/>)}
                            {this.props.params.UserId!=="-1"?(
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}`}>
                                <button type="button" className="btn btn-dark ml-3">History</button>
                            </Link>
                            ):(<div></div>)}
                        </div>
                    </div>
                    </form>
                </div>
                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete User</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.data("Name")}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default UsersEdit;