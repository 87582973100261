import React, { useState, useCallback, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { Guid } from '../../Components/Guid';
import { DatabaseRequest } from '../../Components/DatabaseRequest';




const _initData = {
    Id: -1,
    CompanyId: -1,
    SupplierId: -1,
    Name: "",
    Number: "",
    Number2: "",
    Email: ""
};


export const ContactForm = ({inputData=_initData, onSave=()=>{}, disabled, labels=true, index=-1}) => {
    const AS_ID = Guid();
    const [_index, setIndex] = useState(index);
    const [suggestions, setSuggestions] = useState([]);

    const [Contacts, setContacts] = useState([]);
    const [data, setData] = useState(_initData);


    useEffect(async ()=>{
        setData({..._initData, ...inputData});
        //setSuggestions([]);
        //setContacts(await DatabaseRequest("/contacts","GET"))
    }, [])


    function phoneNumberFormat(num){        
        if(num.length > 2){
            if(num.substring(0,2)=="04" && num.length>4){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
                if(num.length > 8){
                    if(num.charAt(8)!=" "){
                        num = num.substring(0,8)+" "+num.substring(8,num.length);
                    }
                }
            }
            if(num.charAt(0)!="0" && num.length>4 ){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
            }
            if(num.substring(0,2)=="03"){
                if(num.charAt(2)!=" "){
                    num = num.substring(0,2)+" "+num.substring(2,num.length);
                }
                if(num.length > 7){
                    if(num.charAt(7)!=" "){
                        num = num.substring(0,7)+" "+num.substring(7,num.length);
                    }
                }
            }
        }
        return num;
    }

    function onChange(e){
        const {name, value} = e.target;
        
        

        if(typeof data[name]!=='undefined'){
            var obj = {};
            obj[name] = value;
            

            if(name=="Number" || name=="Number2"){
                obj[name] = phoneNumberFormat(value);
            }
            var newData = {    
                ...data,              
                ...obj
            };

            if(name=="Name" && newData.Name.length==0){
                newData = {...newData, ..._initData};
                
            }
            onSave(_index, newData);
            setData(newData);
            
        }

    }
    function onContactNameChange (event, { newValue }){
        if(event.type=="click"){
            Contacts.forEach((Contact)=>{
                if(Contact.Name==newValue){
                    var newData = {    
                        ...data,              
                        Id: Contact.Id,
                        Name: Contact.Name,
                        Number: Contact.Number,
                        Number2: Contact.Number2,
                        Email: Contact.Email
                    };
                    setData(newData);
                    onSave(_index, newData);

                }
            })
            
        }
        else{
            var newData = {...data,_initData}
            /*if(newValue.length>0){
                newData.Name = newValue;
            }*/
            setData(newData);
        }
     
    };

    function onContactNameFetchRequested({value}){

        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var results =  inputLength === 0 ? Contacts : Contacts.filter(lang =>
            lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
        if(results.length > 10){
            setSuggestions([]);
        }
        else{
            setSuggestions(results);
        }
        
    }
    function onContactNameClearRequested(){
        setSuggestions([]);
    }
    function shouldRenderSuggestions(){
        return true;
    }
    function getContactName(suggestion){
        return  suggestion.Name;
    }
    function renderSuggestion(suggestion){
        return (<div>{suggestion.Name}</div>);
    }
    var inputProps = {
        placeholder: 'Contact Name',
        value: data.Name,
        disabled: disabled,
        className: "form-control",
        onChange: onContactNameChange,
        autoComplete: "nofill",
    };


    /*                {data.Id === -1 && false? (
                    <Autosuggest
                        id={AS_ID}
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onContactNameFetchRequested}
                        onSuggestionsClearRequested={onContactNameClearRequested}
                        shouldRenderSuggestions={shouldRenderSuggestions}
                        getSuggestionValue={getContactName}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
                ) : ( )}*/

    return (
        <>
            <div className="form-group col-md-3">
            {labels?(<label>Contact Name</label>):<></>}
                    <input type="text" className={data.Name.length>0?"form-control":"form-control is-invalid"} id="contact_name" placeholder="Contact Name" disabled={disabled} name="Name" value={data.Name} onChange={onChange} />

            </div>
            <div className="form-group col-md-2">
                {labels?(<label>Contact Number</label>):<></>}
                <input type="tel" pattern="[0-9]" noValidate className="form-control" autoComplete="nofill" placeholder="Contact Number" disabled={disabled} name="Number" value={data.Number} onChange={onChange} />
            </div>
            <div className="form-group col-md-2">
            {labels?( <label>Additional Number</label>):<></>}
                <input type="tel" pattern="[0-9]" noValidate className="form-control" autoComplete="nofill" placeholder="Contact Number" disabled={disabled} name="Number2" value={data.Number2} onChange={onChange} />
            </div>
            <div className="form-group col-md-4">
            {labels?(<label>E-mail</label>):<></>}
                <input type="text" className="form-control" autoComplete="nofill" placeholder="E-mail" disabled={disabled} name="Email" value={data.Email} onChange={onChange} />
            </div>
        </>

);
}
