import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import queryString from "query-string"
class Breadcrumb extends Component {
    state = {
        states: [
            {id: 1, text: "Awaiting Quoting", class: "text-warning font-weight-bold"},
            {id: 3, text: "Quote Sent", class: "text-primary font-weight-bold"},
            {id: 4, text: "Awaiting Scheduling", class: "text-warning font-weight-bold"},
            {id: 5, text: "Fabrication Underway", class: "text-warning font-weight-bold"},
            {id: 6, text: "Completed - Not Picked Up", class: "text-info font-weight-bold"},
            {id: 8, text: "Completed - Job Closed", class: "text-success font-weight-bold"},
            {id: 9, text: "Post Completion Modification", class: "text-danger font-weight-bold"},
            {id: 10, text: "Other", class: "text-primary font-weight-bold"}
        ]
    }
    getState = (state) =>{
        for(let i=0; i<this.state.states.length;i++){
            if(this.state.states[i].id===state){
                return (<div className={this.state.states[i].class}>{this.state.states[i].text}</div>);
            }
        }
        return (<div className="">## State Missing ##</div>);
    }
    getStateOptions = () =>{
        return (
        <React.Fragment>
            {this.state.states.map((row, i) => {
                    return (<option key={i} value={row.id} className={row.class}>{row.text}</option>)
            })}

        </React.Fragment>)

    }
    render (){
        const {JobId,Active, Disabled} = this.props;
        var query = queryString.parse(this.props.location.search);
        var returnString = "";
        if(typeof query.return !== 'undefined'){
            returnString = "?return="+query.return;
        }
        if(Disabled){
            return (<></>);
        }
        if(JobId!=="-1"){
            return (
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className={"breadcrumb-item"+(Active==="Job"?" font-weight-bold":"")}><Link to={"/jobs/"+JobId+returnString}>Job</Link></li>    
                        <li className={"breadcrumb-item"+(Active==="Processes"?" font-weight-bold":"")}><Link to={"/jobs/"+JobId+"/processes"+returnString}>Processes</Link></li>
            {/*<li className={"breadcrumb-item"+(Active==="Material"?" font-weight-bold":"")}><Link to={"/jobs/"+JobId+"/material?view="+query.view}>Material</Link></li>
                        <li className={"breadcrumb-item"+(Active==="Operators"?" font-weight-bold":"")}><Link to={"/jobs/"+JobId+"/operators?view="+query.view}>Operators</Link></li>*/}
                        <li className={"breadcrumb-item"+(Active==="Time Sheet"?" font-weight-bold":"")}><Link to={"/jobs/"+JobId+"/timesheet"+returnString}>Time Sheet</Link></li>
                        <li className={"breadcrumb-item"+(Active==="Laser"?" font-weight-bold":"")}><Link to={"/jobs/"+JobId+"/laser"+returnString}>Laser</Link></li>
                        {/*<li className={"breadcrumb-item text-muted"+(Active==="Completion Report"?" font-weight-bold":"")}>Completion Report</li>*/}
                    </ol>
                </nav>
            );
        }
        else{
            return (
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className={"breadcrumb-item text-muted"+(Active==="Job"?" font-weight-bold":"")}>Job</li>    
                        <li className={"breadcrumb-item text-muted"+(Active==="Processes"?" font-weight-bold":"")}>Processes</li>
                        {/*<li className={"breadcrumb-item text-muted"+(Active==="Material"?" font-weight-bold":"")}>Material</li>
                        <li className={"breadcrumb-item text-muted"+(Active==="Operators"?" font-weight-bold":"")}>Operators</li>*/}
                        <li className={"breadcrumb-item text-muted"+(Active==="Time Sheet"?" font-weight-bold":"")}>Time Sheet</li>
                        {/*<li className={"breadcrumb-item text-muted"+(Active==="Completion Report"?" font-weight-bold":"")}>Completion Report</li>*/}
                    </ol>
                </nav>
            )
        }
    }
}

export default Breadcrumb;