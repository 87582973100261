import React, { Component } from 'react';
import {Redirect } from "react-router-dom";
import queryString from "query-string";

class Redirector extends Component {
    state = {
        loading: true,
        timeout: null
    }
    componentDidMount= (event) =>{
        let t = this;
        t.timeout = setTimeout(function(){ 
                t.setState({loading:false});
        }, 50);
    }
    componentWillUnmount = () =>{
        let t = this;
        clearTimeout(t.timeout);
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">Please wait...</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    render (){
        const query = queryString.parse(this.props.location.search);
        console.log(query.r);
        if(this.state.loading){
            return this.loading();
        }
        else{
            return (
                <Redirect to={"/"+query.r}/>
            );
        }

        
    }
}
export default Redirector;