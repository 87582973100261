import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import md5 from 'md5';
import Dropzone from 'react-dropzone';
import Autosuggest from 'react-autosuggest';
import DatePicker, {registerLocale} from "react-datepicker";
import enAU from 'date-fns/locale/en-AU';
import "react-datepicker/dist/react-datepicker.css";
import State from '../../Components/State.js';
import TimeAgo from '../../Components/TimeAgo.js';
import Breadcrumb from '../../Components/Breadcrumb.js';
import queryString from "query-string"

class Page extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading job processes...",
        redirect: "",
        steps: [],
        processes: [
            { id: 0, name: "Drafting", selected: false},
            { id: 1, name: "Material Prep.", selected: false},
            { id: 2, name: "Laser", selected: false},
            { id: 3, name: "Turning", selected: false},
            { id: 4, name: "Milling", selected: false},
            { id: 5, name: "Grinding", selected: false},
            { id: 6, name: "Fitting", selected: false},
            { id: 7, name: "Folding", selected: false},
            { id: 8, name: "Pressing", selected: false},
            { id: 9, name: "Welding", selected: false},
            { id: 10, name: "Supply", selected: false},
            { id: 11, name: "Hypermill", selected: false}
        ],
        data:{
            Id: -1
        },       
        OperatorName: "",
        OperatorSuggestions: [],
        Operators: [],
        OperatorErrShown: true
    }
    onChange = (e) => {
        let data = this.state.data;
        data[e.target.name]=e.target.value;
    };
    saveBtn = (e,redirect="") =>{
        const {databaseRequest,params,setPage}=this.props;
        const query = queryString.parse(this.props.location.search);
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: `Saving ${this.state.Job.Name}...`
        });
        var req = {
            url: `/jobs/processes/${params.JobId}`,
            method: "PUT"
        }
        if(redirect==""){
            redirect = "/redirector?r=jobs/"+t.state.Job.Id+"/processes?view="+query.view;
        }
        else{
            redirect = "/redirector?r="+redirect;
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(this.state.steps)
        ).then((data)=>{
            setTimeout(function(){
                setPage(`${data.Name}`);
                t.setState({
                    loading: false,
                    loadingMsg: "Loading jobs...",
                    redirect: redirect,
                    data: data
                });
            }, 250);
        });

    }
    onOperatorNameChange = (event, i, newValue ) => {
        var {steps, OperatorErrShown}=this.state;
        var operatorFound = -1;
        var operators = this.state.Operators; 
        for(let i=0; i<operators.length; i++){
            if(newValue===operators[i].Name){
                operatorFound=i;
            }            
        }
        if(i > -1 && i<steps.length){
            steps[i].OperatorId=operatorFound>-1?operators[operatorFound].Id:-1;
            steps[i].OperatorName=newValue;
        }
        OperatorErrShown=false;
        for(var k=0; k<steps.length; k++){
            if(steps[k].OperatorId===-1){
                OperatorErrShown=true;
            }
        }
        this.setState({
            OperatorErrShown:OperatorErrShown,
            steps:steps
        });
    }; 
    getOperatorName = suggestion => suggestion.Name;
    getOperatorSuggestions = (value, process) => {

        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var defaultResults = [];
        if(inputLength === 0){
            for(let i=0; i<this.state.Operators.length; i++){
                if(this.state.Operators[i].Processes.indexOf(process.toString())>-1){
                    defaultResults.push(this.state.Operators[i]);
                }
            }
        }
        return inputLength === 0 ? defaultResults : this.state.Operators.filter(lang =>
          lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };
    shouldRenderSuggestions = (value) =>{
        return true;
    }
    renderSuggestion = suggestion => (
        <div>
          {suggestion.Name}
        </div>
    );
    onOperatorNameFetchRequested = (value,process) => {
        this.setState({
            OperatorSuggestions: this.getOperatorSuggestions(value,process)
        });
    };
    onOperatorNameClearRequested = () => {
        this.setState({
            OperatorSuggestions: []
        });
    };
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    async componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        var {processes,steps} = this.state;
        const {data} = this.state;
        let t = this;
        setPage("Process Allocation");
        if(typeof params.JobId ==='undefined' || params.JobId==-1){
            t.setState({redirect:"/jobs/-1"});
        }
        else{
            data.Id = params.JobId;
            var Job = await databaseRequest(`/jobs/${params.JobId}`,"GET");
            if(typeof Job.Id !=='undefined'){
                var Company = await databaseRequest(`/companies/${Job.CompanyId}`,"GET");
                var Contact = await databaseRequest(`/contacts/${Job.ContactId}`,"GET");
                steps = await databaseRequest(`/jobs/processes/${params.JobId}`,"GET");
                var Operators = await databaseRequest(`/operators`,"GET");
                
                for(var k=0; k<steps.length; k++){
                    steps[k].descShown=steps[k].description.length>0
                }
                for(var k=0; k<steps.length; k++){
                    if(steps[k].process==2){
                        steps[k].OperatorId=0;
                    }
                    steps[k].OperatorName="";
                    steps[k].descShown=steps[k].description.length>0
                    for(var j=0; j<Operators.length; j++){
                        if(steps[k].OperatorId===Operators[j].Id){
                            steps[k].OperatorName=Operators[j].Name;
                        }
                    }
                }

                setPage(`[${Company.Name}] - ${Job.Name} | Processes`);
                data.Name=Job.Name;
                data.CompanyName=Company.Name;
                data.Scope=Job.Scope;
                data.Guid = Job.Guid;
                for(var j=0; j<processes.length; j++){
                    var found=false;
                    for(var k=0; k<steps.length; k++){
                        if(steps[k].process===j){
                            found=true;
                        }
                    } 
                    processes[j].selected=found;     
                }
        
                t.setState({
                    loading: false,
                    Job: Job,
                    Company: Company,
                    Contact: Contact,
                    Operators: Operators,
                    data:data,
                    processes: processes,
                    steps: steps
                });
            }
        }
        
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    onProcessClick = (i) =>{
        var {processes,steps} = this.state; 
        steps.push({
            process: i,
            complete:false,
            description: "",
            descShown:false,
            OperatorId: i==2?0:-1,
            OperatorName: ""
        });
        for(var j=0; j<processes.length; j++){
            var found=false;
            for(var k=0; k<steps.length; k++){
                if(steps[k].process===j){
                    found=true;
                }
            } 
            processes[j].selected=found;     
        }

        this.setState({
            processes: processes
        })
    }
    toggleCompletion = (i)=>{
        var {steps} = this.state;
        if (i > -1 && i<steps.length) {
            steps[i].complete=!steps[i].complete;
        }
        this.setState({
            steps:steps
        });
    }
    showDescription = (i) =>{
        var {steps} = this.state;
        if (i > -1 && i<steps.length) {
            steps[i].descShown=!steps[i].descShown;
        }
        this.setState({
            steps:steps
        });
    }
    removeStep = (i) =>{
        const {steps,processes} = this.state;
        var step = steps[i];
        if (i > -1 && i<steps.length) {
            steps.splice(i, 1);
        }


        
        for(var j=0; j<processes.length; j++){
            var found=false;
            for(var k=0; k<steps.length; k++){
                if(steps[k].process===j){
                    found=true;
                }
            } 
            processes[j].selected=found;     
        }

        this.setState({
            steps:steps,
            processes: processes
        });
    }
    moveUp = (e)=>{
        const {steps} = this.state;
        if(e > 0){
            var f = steps.splice(e, 1)[0];
            steps.splice(e-1, 0, f);
            this.setState({
                steps:steps
            });
        }
    }
    moveDown = (e)=>{
        const {steps} = this.state;
        var f = steps.splice(e, 1)[0];
        steps.splice(e+1, 0, f);
        this.setState({
            steps:steps
        });
    }
    processList = () =>{
        const {processes} = this.state;
        return (
        <div className="row justify-content-center border-bottom border-dark pb-2">
            {processes.map((row, i) => {
                    if(row.selected){
                        return (<button key={i} type="button" disabled={this.state.Job.State===11 || this.state.Job.State===8} className="btn tag-selected" onClick={()=>{this.onProcessClick(i)}}><i className="fe fe-plus"></i> {row.name}</button>)
                    }
                    else{
                        return (<button key={i} type="button" disabled={this.state.Job.State===11 || this.state.Job.State===8} className="btn tag" onClick={()=>{this.onProcessClick(i)}}><i className="fe fe-plus"></i> {row.name}</button>)
                    }
                    
            })}
        </div>);
    }
    ProcessName = (e)=>{
        const {processes} = this.state;
        for(let i=0; i<processes.length; i++){
            if(processes[i].id===e){
                return (processes[i].name);
            }
        }
        return "";
    }
    stepDescription=(i)=>{
        const {steps,} = this.state;
        if (i > -1 && i<steps.length) {
            return steps[i].description;
        }
    }
    onDescriptionChange = (e,i) => {
        var {steps} = this.state;
        if (i > -1 && i<steps.length) {
            steps[i].description=e.target.value
        }
        this.setState({
            steps:steps
        });
    };
    steps = ()=>{
        const {steps,processes} = this.state;
        const { OperatorSuggestions } = this.state;
        return (
                <div className="process-list">
                {steps.map((row, i) => {
                        var operatorNameIP = {
                            placeholder: 'Operator Name',
                            value: row.OperatorName,
                            className: "form-control",
                            onChange: (e, { newValue })=>{this.onOperatorNameChange(e,i, newValue)},
                            autoComplete: "nofill",
                            disabled: this.state.Job.State===11 || this.state.Job.State===8,
                            readOnly: true
                        };
                       return (

                        <div key={i} className={row.complete?"process-item bg-success text-dark":"process-item bg-secondary"}>
                            <div className="row">
                                <div className="col"><h3 className={row.complete?"text-dark mb-0 m-3":"text-white mb-0 m-3"}>{i+1} - {this.ProcessName(row.process)}</h3></div>
                                {row.process!==2?(
                                <div className="col">
                                <Autosuggest
                                        id={"OperatorName-"+i}
                                        suggestions={OperatorSuggestions}
                                        onSuggestionsFetchRequested={({ value })=>{this.onOperatorNameFetchRequested(value, row.process)}}
                                        onSuggestionsClearRequested={this.onOperatorNameClearRequested}
                                        getSuggestionValue={this.getOperatorName}
                                        shouldRenderSuggestions={this.shouldRenderSuggestions}
                                        renderSuggestion={this.renderSuggestion}
                                        inputProps={operatorNameIP}
                                    />
                                </div>
                                ):(<React.Fragment></React.Fragment>)}
                                <div className="col">
                                    <h3 className="text-right mb-0 m-3">

                                    {this.state.Job.State===11 || this.state.Job.State===8?(
                                    <React.Fragment>

                                    </React.Fragment>     
                                    ):(
                                    <React.Fragment>
                                    <i className={row.complete?"fe fe-minus-circle hover-dark mr-2":"fe fe-check-circle hover mr-2"} onClick={()=>{this.toggleCompletion(i)}}></i>
                                    <i className={row.complete?"fe fe-edit hover-dark ml-2":"fe fe-edit hover ml-2"} onClick={()=>{this.showDescription(i)}}></i> 
                                       {i>0?<i className={row.complete?"fe fe-chevron-up ml-2  hover-dark":"fe fe-chevron-up ml-2  hover"} onClick={()=>{this.moveUp(i)}}></i>:<React.Fragment></React.Fragment>}
                                       {i<steps.length-1?<i className={row.complete?"fe fe-chevron-down ml-2  hover-dark":"fe fe-chevron-down ml-2  hover"} onClick={()=>{this.moveDown(i)}}></i>:<React.Fragment></React.Fragment>}
                                        <i className={row.complete?"fe fe-x hover-dark ml-2":"fe fe-x hover ml-2"} onClick={()=>{this.removeStep(i)}}></i>
                                    </React.Fragment>
                                    )}
                                    
                                    </h3>

                                </div>
                            </div> 
                            {row.descShown || row.description.length>0?(
                            <div className="row mt-2">
                            <div className="col">
                                <input type="text" disabled={this.state.Job.State===11 || this.state.Job.State===8} autoComplete="off" className="form-control" placeholder="Description" name="Description" value={this.stepDescription(i)} onChange={(e)=>{this.onDescriptionChange(e,i)}}/>
                            </div>
                            </div>     
                            ):(<React.Fragment></React.Fragment>)}                 
                        </div>
                        )                      
                })}
                </div>
            );
    }
    page = () =>{
        const {props} = this;
        const query = queryString.parse(this.props.location.search);
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-shuffle"></i> Processes</h2>
                <div className="row form-group">
                    <Breadcrumb {...props} JobId={this.state.data.Id} Active="Processes"/>
                </div>
                <div className="row form-group w-100">
                    <div className="col p-0 mx-auto">
                        <h1 className="border-bottom mb-1">[{this.state.data.Id==-1?" - ":this.state.data.Id}] {this.data("Name")} - <i>{this.data("CompanyName")}</i></h1>
                        <small className="text-muted">Created: <TimeAgo time={this.state.Job.CreatedDateTime}/> ({new Date(this.state.Job.CreatedDateTime).toLocaleDateString()})</small>
                        <br/>
                        <small className="text-muted">Last Updated: <TimeAgo time={this.state.Job.UpdatedDateTime}/> ({new Date(this.state.Job.UpdatedDateTime).toLocaleDateString()})</small>
                        <h5 className="w-100 font-weight-bold mt-3"><u>Job Description</u></h5>
                        <p className="w-100 font-italic">
                            {this.data("Scope").split("\\n").map(function(item, i) {
                            return (
                                <span key={i}>
                                    {item}
                                    <br/>
                                </span>)
                            })}
                        </p>
                    </div>
                    <div className="col p-0 col-md-2">
                    
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}?return=${query.return}`}>
                                <button type="button" className="btn btn-dark float-right">History</button>
                            </Link>
                            {/*<button type="button" className="btn btn-success ml-3" onClick={(e)=>{this.saveBtn(e, "jobs/"+this.state.data.Id+"?view="+query.view)}}>Save</button>*/}
                    </div>
                </div>
                {this.processList()}

                <div className="row form-group mt-4 d-flex justify-content-center">
                {this.steps()}
                </div>

                <div className="form-row justify-content-between">
                        <div className="form-group col-mx-auto">
                            <button type="button" className="btn btn-secondary float-right" onClick={(e)=>{this.saveBtn(e, "jobs/"+this.state.data.Id+"?return="+query.return)}}>Back</button>
                        </div>
                        <div className="form-group col-mx-auto">
                            {query.view==="schedule"?(
                                <React.Fragment>
                                <button type="button" disabled={this.state.Job.State===11 || this.state.Job.State===8 || this.state.Job.State===20} className="btn btn-success ml-2 float-right" onClick={(e)=>{this.saveBtn(e, "jobs/"+this.state.data.Id+"?return="+query.return)}}>Save</button>    
                                <button type="button" className="btn btn-info ml-2 float-right" onClick={(e)=>{this.saveBtn(e, "schedule/operator-view")}}>Back To Schedule</button>  
                                </React.Fragment>
                            ):(
                                <button type="button" disabled={this.state.Job.State===11 || this.state.Job.State===8 || this.state.Job.State===20} className="btn btn-success ml-2 float-right" onClick={(e)=>{this.saveBtn(e, "jobs/"+this.state.data.Id+"?return="+query.return)}}>Save</button>
                            )
                            }
                            
                        </div>
                 </div>
            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default Page;