import React, { Component } from 'react';
import {Link} from "react-router-dom";
import queryString from "query-string"
import { ReactSketchCanvas } from "react-sketch-canvas";

class Sketch extends Component {
    state = {
        penColor: 'black'
    }
    
    componentDidMount = () =>{

    }
    constructor(props) {
        super(props);
        this.canvas = React.createRef();
    }
    findTag = (str, key, replace)=>{
        var keyFull = key+"=";
        var pos = str.indexOf(keyFull);
        console.log(keyFull.length);
        var posEnd = str.indexOf('"',pos+keyFull.length+1);
        var newStr = str.substr(0,pos);
        newStr += replace
        newStr += str.substr(posEnd+1);
        return newStr;
    }
    page = () =>{
        const styles = {
            border: "0.0625rem solid #9c9c9c",
            borderRadius: "0.25rem",
          };
        return (
            <React.Fragment>
            <ReactSketchCanvas
                    style={styles}
                    ref={this.canvas}
                    width="100%"
                    height="100%"
                    strokeWidth={1}
                    strokeColor={this.state.penColor}
                    onChange={console.log}
                    />
                    <div className="row justify-content-between w-100" style={{position: "fixed", bottom: "5px", right: "15px" }}> 

                        <div className="col">
                            <button className="btn m-2" style={{background: '#000000', color: '#fff'}} onClick={()=>{this.setState({penColor: '#000000'})}}><i className="fe fe-edit-2"></i></button>
                            <button className="btn m-2" style={{background: '#c60000', color: '#fff'}} onClick={()=>{this.setState({penColor: '#c60000'})}}><i className="fe fe-edit-2"></i></button>
                            <button className="btn m-2" style={{background: '#00c600', color: '#fff'}} onClick={()=>{this.setState({penColor: '#00c600'})}}><i className="fe fe-edit-2"></i></button>
                            <button className="btn m-2" style={{background: '#0000c6', color: '#fff'}} onClick={()=>{this.setState({penColor: '#0000c6'})}}><i className="fe fe-edit-2"></i></button>
                        </div>    
                        <div className="col d-flex flex-row-reverse">

                        <button className="btn btn-danger m-2"  onClick={()=>{
                            this.canvas.current.clearCanvas();
                            this.props.close();
                            }}><i className="fe fe-x"></i></button>

                        <button className="btn btn-warning m-2"  onClick={()=>{this.canvas.current.clearCanvas();}}><i className="fe fe-trash-2"></i></button>
                            <button className="btn m-2 btn-success" onClick={()=>{
                                console.log(this.canvas.current);
                                this.canvas.current.exportPaths()
                                .then(data => {
                                    var min = {
                                        x: 9999,
                                        y: 9999
                                    };
                                    var max = {
                                        x: 0,
                                        y: 0
                                    }
                                    for(var i=0; i<data.length; i++){
                                        
                                        for(var j=0; j<data[i].paths.length; j++){
                                            var point = data[i].paths[j];

                                            min.x = point.x < min.x?point.x:min.x;
                                            min.y = point.y < min.y?point.y:min.y;
                                            
                                            max.x = point.x > max.x?point.x:max.x;
                                            max.y = point.y > max.y?point.y:max.y;
                                        }
                                    }
                                    var height = max.y - min.y;
                                    var width = max.x - min.x;
                                    this.canvas.current
                                    .exportSvg()
                                    .then(svg => {
                                        //console.log(svg);
                                        var padding = 50;
                                        padding=width<400?35:padding;
                                        padding=width<200?15:padding;
                                        padding=width<100?5:padding;
                                        var newSvg = this.findTag(svg, "viewBox", `viewBox="${(min.x-padding)} ${(min.y-padding)} ${(width+2*padding)} ${(height + 2*padding)}"`);
                 
                                        //console.log(newSvg);
                                        this.props.save(newSvg);
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    });
                                })


                                
                                }}><i className="fe fe-save"></i></button>
                            <button className="btn btn-info m-2"  onClick={()=>{this.canvas.current.undo();}}><i className="fe fe-rotate-ccw"></i></button>
                            <button className="btn btn-info m-2"  onClick={()=>{this.canvas.current.redo();}}><i className="fe fe-rotate-cw"></i></button>
                            
                        </div> 
                       

                    </div>
            </React.Fragment>
        );
    }
  
    render() {
        return this.page();
    }
}

export default Sketch;