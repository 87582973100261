import React, { Component } from 'react';
import {LandscapeOrientation} from '../../LandscapeOrientation.jsx'

class LaserJobs extends Component {
    state = {
        loading: true,
        editing: false,
        socket: null,
        redirect: "",
        processes: [
            { id: 0, name: "Drafting", selected: false},
            { id: 1, name: "Material Prep.", selected: false},
            { id: 2, name: "Laser", selected: false},
            { id: 3, name: "Turning", selected: false},
            { id: 4, name: "Milling", selected: false},
            { id: 5, name: "Grinding", selected: false},
            { id: 6, name: "Fitting", selected: false},
            { id: 7, name: "Folding", selected: false},
            { id: 8, name: "Pressing", selected: false},
            { id: 9, name: "Welding", selected: false},
            { id: 10, name: "Supply", selected: false},
            { id: 11, name: "Hypermill", selected: false}
        ],
        jobModal:{
            Id: -1,
            Loading: false,
            OperatorName: "",
            JobName: "",
            steps: [],
            details: {
                ProcessGuid: ""
            }
        }
    }
    redirect = (url)=>{
        this.setState({redirect: url});
    }
    loadModal = async (operator, job)=>{
        const {databaseRequest}=this.props;
        var {jobModal} = this.state;
        jobModal.Id=job;
        jobModal.JobName = this.JobName(job);

        jobModal.Loading = true;
        this.setState({jobModal:jobModal});
        jobModal.steps = await databaseRequest(`/jobs/processes/${job}`,"GET");
        for(var k=0; k<jobModal.steps.length; k++){
            jobModal.steps[k].descShown=jobModal.steps[k].description.length>0
        }
        
        jobModal.details = await databaseRequest(`/schedule/${job}/${operator}`,"GET");

        jobModal.Loading = false;
        this.setState({jobModal:jobModal});
    }
    stepDescription=(i)=>{
        const {steps} = this.state.jobModal;
        if (i > -1 && i<steps.length) {
            return steps[i].description;
        }
    }
    toggleCompletion = (i)=>{
        var {jobModal} = this.state;
        if (i > -1 && i<jobModal.steps.length) {
            jobModal.steps[i].complete=!jobModal.steps[i].complete;
        }
        this.setState({
            jobModal:jobModal
        });
    }
    saveBtn = (JobId) =>{
        const {databaseRequest}=this.props;
        let t = this;
        this.setState({
            loading: true
        });
        var req = {
            url: `/jobs/processes/${JobId}`,
            method: "PUT"
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(this.state.jobModal.steps)
        ).then((data)=>{
            this.reload();
        });
    }
    saveTarget = () =>{
        const {databaseRequest}=this.props;
        let t = this;
        this.setState({
            loading: true
        });
        var req = {
            url: `/jobs/processes`,
            method: "PUT"
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(this.state.ProcessList)
        ).then(()=>{
            this.reload();
            this.setState({editing: false});
        });
    }
    editMode = (e)=>{
        this.setState({editing: e});
        this.reload(true);
    } 
    reload = async (loading)=>{
        const {setPage, databaseRequest}=this.props;
        let t =this;
        setPage(`Schedule`);
        this.setState({loading: loading});
        var Companies = await databaseRequest("/companies","GET");
        var Operators = await databaseRequest("/operators?Id=0","GET");
        var Contacts = await databaseRequest("/contacts","GET");
        var Jobs = await databaseRequest("/jobs?CompletedDateTime=-1","GET");
        var ProcessList = await databaseRequest("/jobs/processes?OperatorId=0&Complete=0","GET");

        databaseRequest(`/schedule`,"GET").then((data)=>{
            if(typeof data.error !== 'undefined'){
                t.setState({
                    loading: false,
                    Operators:Operators,
                    Companies:Companies,
                    Contacts:Contacts,
                    Jobs:Jobs,
                    ProcessList:ProcessList
                })
            }
            else{
                t.setState({
                    loading: false,
                    data: data,
                    Operators:Operators,
                    Companies:Companies,
                    Contacts:Contacts,
                    Jobs:Jobs,
                    ProcessList:ProcessList
                })
            }
        });
    }
    componentDidMount() {
        let t=this;
        const {socketServer, setPage} = this.props;
        setPage("Laser Jobs");
        this.reload(true);
    }
    componentWillUnmount(){
        let t=this;
        if(t.socket!=null){
            t.socket.disconnect();
        }
    }
    TargetProcess = (e) =>{
        const {ProcessList} = this.state;
        for(let i=0; i<ProcessList.length; i++){
            if(ProcessList[i].JobId===e){
                return ProcessList[i].TargetProcess;
            }
        }
        return 0;
    }
    toggleTargetProcess = (OperatorId, JobId) =>{
        var {ProcessList} = this.state;
        for(let i=0; i<ProcessList.length; i++){
            if(ProcessList[i].JobId===JobId && ProcessList[i].OperatorId===OperatorId){
                ProcessList[i].TargetProcess = !ProcessList[i].TargetProcess;
            }
        }
        this.setState({ProcessList: ProcessList});
    }
    ContactName = (e)=>{
        const {Contacts} = this.state;
        for(let i=0; i<Contacts.length; i++){
            if(Contacts[i].Id===e){
                return (Contacts[i].Name);
            }
        }
        return "";
    }
    CompanyName = (e)=>{
        const {Companies} = this.state;
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===e){
                return (Companies[i].Name);
            }
        }
        return "";
    }
    OperatorName = (e)=>{
        const {Operators} = this.state;
        for(let i=0; i<Operators.length; i++){
            if(Operators[i].Id===e){
                return (Operators[i].Name);
            }
        }
        return "";
    }
    OperatorFirstName = (e)=>{
        const {Operators} = this.state;
        for(let i=0; i<Operators.length; i++){
            if(Operators[i].Id===e){
                return (Operators[i].Name).split(' ')[0];
            }
        }
        return "";
    }
    ProcessName = (e)=>{
        const {processes} = this.state;
        for(let i=0; i<processes.length; i++){
            if(processes[i].id===e){
                return (processes[i].name);
            }
        }
        return "";
    }
    JobName = (e) =>{
        const {Jobs} = this.state;
        var JobName = "";
        var JobUrgent = false;
        for(let i=0; i<Jobs.length; i++){
            if(Jobs[i].Id===e){
                JobUrgent = Jobs[i].IsUrgent;
                if(Jobs[i].CompanyId===-2){
                    JobName = `[${this.ContactName(Jobs[i].ContactId)}] ${Jobs[i].Name}`;
                }
                else{
                    JobName = `[${this.CompanyName(Jobs[i].CompanyId)}] ${Jobs[i].Name}`;
                }
                
            }
        }
        var len = 1000;
        if(JobName.length > len){
            JobName = JobName.substr(0,len-3)+"...";
        }
        var TargetProcess = this.TargetProcess(e);
        if(TargetProcess){
            return (<td className="bg-grey font-weight-bold text-white" style={{fontSize:'1.15rem'}}>{this.state.editing?(<i className="fe fe-edit mr-2"></i>):(<React.Fragment></React.Fragment>)}{e} - {JobName}</td>);
        }
        if(JobUrgent){
            return (<td className="bg-danger font-weight-bold text-white p-2" style={{fontSize:'1.15rem'}}>{this.state.editing?(<i className="fe fe-edit mr-2"></i>):(<React.Fragment></React.Fragment>)}{e} - {JobName}</td>);
        }
        else{
            return (<td className="font-weight-bold p-2" style={{fontSize:'1.15rem'}}>{this.state.editing?(<i className="fe fe-edit mr-2"></i>):(<React.Fragment></React.Fragment>)}{e} - {JobName}</td>);
        }
        
    }
    table = () =>{
        if(this.state.loading){
            return (
                <div className="w-100 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">Loading schedule...</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
        else{
            var Operators = this.state.Operators;
            var Processes = this.state.data;
            
            for(let i=0; i<Operators.length; i++){
                Operators[i].Jobs = [];
                for(let j=0; j<Processes.length; j++){
                    for(let k=0; k<Processes[j].Operators.length; k++){
                        if(Processes[j].Operators[k].OperatorId===Operators[i].Id){
                            Operators[i].Jobs = Operators[i].Jobs.concat(Processes[j].Operators[k].Jobs.Urgent);
                            Operators[i].Jobs = Operators[i].Jobs.concat(Processes[j].Operators[k].Jobs.NextInLine);
                            Operators[i].Jobs = Operators[i].Jobs.concat(Processes[j].Operators[k].Jobs.Muted);
                        }
                    }
                }
            }

        

            
            for(let i=0; i<Operators.length; i++){
                Operators[i].Jobs = this.arrayUnique(Operators[i].Jobs);                 
            }
            //copy jobs for laser only
            var jobs = Operators[0].Jobs;
            var OperatorId = Operators[0].Id;


            var table = [];
            
            var tableIndex = 0;
            table[tableIndex] = [];
            for(let i=0; i<jobs.length; i++){
                if(table[tableIndex].length > 2){
                    tableIndex++;
                    table[tableIndex] = [];
                }
                table[tableIndex].push(jobs[i]);
            }
            return (
                <table className="mt-2 table w-100 table-bordered">
                <tbody>
                   {table.map((row, i)=>{
                            return ( 
                            <tr key={i}>
                                

                            {row.map((JobId, j)=>{
                                return(
                                <React.Fragment key={j}>
                                    {this.JobName(JobId)}
                                </React.Fragment>
                                )                       
                            })}
                                
                                </tr>);
                        })
                        }                
                    
 
     
                </tbody>
                </table>
            );
        }
    }
    arrayUnique = (array)=>{
        var a = array.concat();
        for(var i=0; i<a.length; ++i) {
            for(var j=i+1; j<a.length; ++j) {
                if(a[i] === a[j])
                    a.splice(j--, 1);
            }
        }
    
        return a;
    }

    render(){
        const access = localStorage.getItem("access");
        return (
            <main role="main" className="p-5">
                <LandscapeOrientation/>
                <div className="">
                    <div className="row form-group mb-0">
                        <div className="col p-0 pt-3">
                            <h5 className="mb-0 text-black"><i className="fe fe-list"></i> Laser Jobs</h5>
                            <small className="text-black">Printed: {new Date().toLocaleString()}</small>
                        </div>

                        <div className="col-auto p-0">               
                            <img src="/logo-full.svg" alt="" width="70" height="35" />                      
                        </div>
                    </div>
                    <div className="row">
                        {this.table()}

                    </div>
                </div>
            </main>  
        )
    }
}

export default LaserJobs;