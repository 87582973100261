import React, { Component, useRef } from 'react';
import {Link, Redirect} from "react-router-dom";
import Autosuggest from 'react-autosuggest';
import ReactToPrint from 'react-to-print';
import PurchaseOrderEditPrint from './PurchaseOrderEditPrint.js'
import TimeAgo from '../../Components/TimeAgo.js';
import { DesktopConnection, isSocketConnected } from '../../Components/DesktopConnection.js';
import {ContactForm} from '../Contacts/ContactForm.js'


class PurchaseOrderEdit extends Component {
    state = {
        loading: true,
        modalLoading: false,
        loadingMsg: "Loading purchase order...",
        redirect: "",
        buttonState: false,
        processes: [],
        disabled: false,
        Supplier: null,
        Suppliers: [],
        SupplierSuggestions: [],
        JobIdSuggestions: [],
        Companies: [],
        Contacts: [],
        SupplierContacts: [],
        Jobs: [],
        Users: [],
        ItemData: [
            {
            AssocJobs: [],
            Description: "",
            Price: "",
            Quantity: ""
        }],
        data: {
            PurchaseOrderId: -1,
            SupplierId: -1,
            SupplierName: "",
            Number: "",
            Number2: "",
            Address: "",
        },
        RowDescription: "",
        RowJobSearch: "",
        RowJobs: [],
        RowPrice: "",
        RowQuantity: "",
        EditingRow: -1,
    }
    getCompanyName = (CompanyId,ContactId)=>{
        var {Companies,Contacts} = this.state;
        if(CompanyId==-2){
            for(let i=0; i<Contacts.length; i++){
                if(Contacts[i].Id===ContactId){
                    
                    return "["+Contacts[i].Name+"]";
                }
            }
        }
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===CompanyId){
                
                return Companies[i].Name;
            }
        }
        return null;
    }
    getJobNameFromJob = (JobId)=>{
        var {Jobs} = this.state;
        for(var i=0; i<Jobs.length; i++){
            if(Jobs[i].Id==JobId){
                return JobId + " - " + this.getCompanyName(Jobs[i].CompanyId, Jobs[i].ContactId) + " | "+Jobs[i].Name;
            }
        }
    }
    onChange = (e) => {
        let data = this.state.data;
        data[e.target.name]=e.target.value;
        data.Number = this.phoneNumberFormat(data.Number);
        data.Number2 = this.phoneNumberFormat(data.Number2);
        this.setState({ data: data});  
    };

    downloadPDF = ()=>{
        const {restServer, params}=this.props;
        let t = this;
        t.setState({loadingMsg: "Creating purchase order PDF, be patient I'm slow...", loading: true})
        fetch(restServer + "/api/pdf/purchase-order/" + params.PurchaseOrderId, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/pdf',
                'x-auth-token': localStorage.getItem('token')
                },
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                'download',
                `PO_${String(params.PurchaseOrderId).padStart(6-(String(params.PurchaseOrderId)).length, '0')}.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();
                t.setState({loadingMsg: "", loading: false})
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }
    sendEmail = (sendMessage)=>{
        const UserId = localStorage.getItem('user')
        const {SupplierName, PurchaseOrderId} = this.state.data;
        const {SupplierContacts, Users} = this.state;
        var attentionName = SupplierName;
        var attentionEmail = "";
        var from = "Lange Engineering";

        for(var i=0;i<Users.length; i++){
            if(Users[i].Id==UserId){
                from=Users[i].Name;
            }
        }
        for(var i=0; i<SupplierContacts.length; i++){
            if(SupplierContacts[i].Checked){
                attentionName = SupplierContacts[i].Name;
                attentionEmail= SupplierContacts[i].Email;
            }
        }
        var email = {
            to:attentionEmail, 
            subject: "PO"+PurchaseOrderId +" - "+SupplierName, 
            body: "Hello "+attentionName+", <br/><br/>Please see attached purchase order. <br/><br/>Kind Regards, <br/><br/>"+from,
        }

        const {restServer, params}=this.props;
        let t = this;
        t.setState({loadingMsg: "Creating purchase order PDF, be patient I'm slow...", modalLoading: true})
        fetch(restServer + "/api/pdf/purchase-order/" + params.PurchaseOrderId, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/pdf',
                'x-auth-token': localStorage.getItem('token')
                },
            })
            .then((response) => response.blob())
            .then((blob) => {
                var file = new Blob([blob]);
                var email = {
                    to:attentionEmail, 
                    subject: "PO"+PurchaseOrderId +" - "+SupplierName, 
                    body: "Hello "+attentionName+", <br/><br/>Please see attached purchase order. <br/><br/>Kind Regards, <br/><br/>"+from,
                    attachment: file
                }
                sendMessage("send_email", email);
                t.setState({loadingMsg: "", modalLoading: false})
            });

        
    }
    saveBtn = async () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        var {RowDescription, RowPrice, RowQuantity, ItemData, data, Supplier, SupplierContacts} = this.state;

        

        ItemData[ItemData.length-1].Description = RowDescription;
        ItemData[ItemData.length-1].Price = RowPrice;
        ItemData[ItemData.length-1].Quantity = RowQuantity;

        if(data.SupplierName !== Supplier.Name || data.Number!== Supplier.Number || data.Number2!==Supplier.Number2 || data.Address!==Supplier.Address){
            Supplier.Name = data.SupplierName;
            Supplier.Number = data.Number;
            Supplier.Number2 = data.Number2;
            Supplier.Address = data.Address;
            var req = {
                url: `/suppliers/${Supplier.Id}`,
                method: "PUT"
            }
            //Update supplier details
            var supplier = await databaseRequest(req.url,req.method, 
                JSON.stringify(Supplier)
            );
        }
        for(var i=0;i<SupplierContacts.length; i++){

            //if Id valid update, if not create
            var req = {
                url: `/contacts`,
                method: "POST"
            }
            if(SupplierContacts[i].Id > -1){
                req.url = `/contacts/${SupplierContacts[i].Id}`;
                req.method = `PUT`;
            }
            var newContact = {...SupplierContacts[i]};
            delete newContact.Id;
            delete newContact.Checked;
            newContact.IsHidden = 0;
            newContact.CompanyId = -1;
            newContact.SupplierId = Supplier.Id;
            var returnedContact = await databaseRequest(req.url,req.method,JSON.stringify(newContact));
            console.log(returnedContact);
        }

        

        var Last = ItemData.pop();
        if(Last.Description !== ""){
            ItemData.push(Last);
        }
        var PurchaseOrder = {
            SupplierId: data.SupplierId,
            Order: ItemData
        }
        this.setState({
            loading: true,
            loadingMsg: `Saving purchase order for ${this.state.data.SupplierName}...`
        });
        var req = {
            url: `/purchase-orders/${params.PurchaseOrderId}`,
            method: "PUT"
        }
        if(params.PurchaseOrderId==="-1"){
            req.url="/purchase-orders";
            req.method="POST";
        }
        PurchaseOrder.ContactId = -1;

        for(var i=0; i<SupplierContacts.length; i++){
            if(SupplierContacts[i].Checked){
                PurchaseOrder.ContactId = SupplierContacts[i].Id;
            }
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(PurchaseOrder)
        ).then((data)=>{
            setTimeout(function(){
                setPage(`${data.Name}`);
                t.setState({
                    loading: false,
                    loadingMsg: "Loading purchase orders...",
                    redirect: "/redirector?r=purchasing/"+data.Id,
                });
            }, 250);
        });
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    deleteBtn = () =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("SupplierName")+"..."
        });
        databaseRequest(`/purchase-orders/${params.PurchaseOrderId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading purchase order...",
                    redirect: "/purchasing"
                });
            }, 1000);
        });

    }
    addContactPerson = ()=>{
        var {SupplierContacts} = this.state;
        SupplierContacts.push({Checked: SupplierContacts.length==0, Name: ""});
        this.setState({SupplierContacts: SupplierContacts});
    }
    OnContactUpdated = (i, data) =>{
        var {SupplierContacts} = this.state;
        if(typeof SupplierContacts[i] !== 'undefined'){
            SupplierContacts[i] = {...SupplierContacts[i], ...data};
        }
        this.setState({SupplierContacts: SupplierContacts});
    }
    onCheckToggle = (i,e) =>{
        var {SupplierContacts} = this.state;
        for(var j=0; j<SupplierContacts.length; j++){
            SupplierContacts[j].Checked = false;
        }
        if(typeof SupplierContacts[i] !== 'undefined'){
            SupplierContacts[i].Checked = e.target.checked;
        }
        this.setState({SupplierContacts: SupplierContacts});


    }
    componentDidMount = async () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        setPage(`Suppliers`);
        var Suppliers = await databaseRequest("/suppliers","GET");
        var Jobs = await databaseRequest(`/jobs?State:not=8&State:not=11&State:not=20`, 'GET');
        var companies = await databaseRequest("/companies","GET");
        var contacts = await databaseRequest("/contacts","GET");
        var Users = await databaseRequest("/users","GET");

        if(params.PurchaseOrderId==="-1"){
            setPage("Purchase Order - New")
            t.setState({
                Suppliers: Suppliers,
                Companies: companies,
                Contacts: contacts,
                Users: Users,
                Jobs: Jobs,
                data:{
                    PurchaseOrderId: -1,
                    SupplierId: -1,
                    SupplierName: "",
                    Number: "",
                    Number2: "",
                    Address: "",
                    Email: "",
                    ContactName: ""
                },
                loadingMsg: "Loading suppliers...",
                loading: false
            });
        }
        else{
            var data = this.state.data;
            var ItemData = this.state.ItemData;
            


            databaseRequest(`/purchase-orders/${params.PurchaseOrderId}`,"GET").then((new_data)=>{
                for(var i=0; i<Suppliers.length; i++){
                    if(Suppliers[i].Id==new_data.SupplierId){

                        var SupplierContacts = [];
                        for(var j=0; j<contacts.length; j++){
                            if(contacts[j].SupplierId==new_data.SupplierId){
                                var newContact = contacts[j];
                                newContact.Checked=newContact.Id===new_data.ContactId;
                                SupplierContacts.push(newContact);
                            }
                        }



                        t.setState({Supplier: Suppliers[i], SupplierContacts:SupplierContacts});
                        var data = {
                            UserId: new_data.UserId,
                            PurchaseOrderId: new_data.Id,
                            SupplierId: new_data.SupplierId,
                            SupplierName: Suppliers[i].Name,
                            Number: Suppliers[i].Number,
                            Number2: Suppliers[i].Number2,
                            Address: Suppliers[i].Address,
                            Guid: new_data.Guid,
                            CreatedDateTime: new_data.CreatedDateTime
                        };
                    }
                }
                var Initial = ItemData.pop();

                
                for(var i=0; i<new_data.ItemData.length; i++){
                    ItemData.push({
                        AssocJobs: JSON.parse(new_data.ItemData[i].JobId.replaceAll("\\","")),
                        Description: new_data.ItemData[i].Description.toString().replaceAll("\\n","\n").replaceAll("\\\"","\""),
                        Price: new_data.ItemData[i].Price,
                        Quantity: new_data.ItemData[i].Quantity
                    });
                }
                ItemData.push(Initial);
                    setPage(`Purchase Order - ${data.SupplierName}`);

                    t.setState({
                        ItemData: ItemData,
                        Companies: companies,
                        Contacts: contacts,
                        Users: Users,
                        Jobs: Jobs,
                        Suppliers: Suppliers,
                        loading: false,
                        data: data
                    });
            });
        }
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    phoneNumberFormat = (num)=>{
        
        if(num.length > 2){
            if(num.substring(0,2)=="04" && num.length>4){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
                if(num.length > 8){
                    if(num.charAt(8)!=" "){
                        num = num.substring(0,8)+" "+num.substring(8,num.length);
                    }
                }
            }
            if(num.charAt(0)!="0" && num.length>4 ){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
            }
            if(num.substring(0,2)=="03"){
                if(num.charAt(2)!=" "){
                    num = num.substring(0,2)+" "+num.substring(2,num.length);
                }
                if(num.length > 7){
                    if(num.charAt(7)!=" "){
                        num = num.substring(0,7)+" "+num.substring(7,num.length);
                    }
                }
            }
        }
        return num;
    }
    onSuggestionSelected = (event, {suggestionValue}) =>{
        this.onSupplierNameChange(event, {newValue: suggestionValue});
    }
    onSupplierNameChange = (event, { newValue }) => {
        var {data, Contacts}=this.state;
        var supplierFound = -1;
        var Suppliers = this.state.Suppliers; 
        var SupplierContacts = [];
        if(data.SupplierId !==-1){
            for(let i=0; i<Suppliers.length; i++){
                if(data.SupplierId===Suppliers[i].Id){
                    supplierFound=i;
                }            
            }
        }
        
        if(event.type=="click" && supplierFound==-1){
            for(let i=0; i<Suppliers.length; i++){
                if(newValue===Suppliers[i].Name){
                    supplierFound=i;
                }            
            }
        }
        if(newValue.length==0){
            supplierFound = -1;
        }
    
        
        data.SupplierId = supplierFound>-1?Suppliers[supplierFound].Id:-1;
        
        if(supplierFound>-1){
        this.setState({Supplier: Suppliers[supplierFound]});
        data.Number = Suppliers[supplierFound].Number;
        data.Number2 = Suppliers[supplierFound].Number2;
        data.Address = Suppliers[supplierFound].Address;


        
        for(var j=0; j<Contacts.length; j++){
            if(Contacts[j].SupplierId==data.SupplierId){
                var newContact = Contacts[j];
                newContact.Checked=false;
                SupplierContacts.push(newContact);
            }
        }
        console.log(SupplierContacts);
        }
        data.SupplierName = newValue;
        this.setState({
          data: data,
          SupplierName: newValue,
          SupplierContacts: SupplierContacts
        });
    };
    getSupplierName = suggestion => suggestion.Name;

    getSupplierSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.Suppliers.filter(lang =>
          lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };
    shouldRenderSuggestions = (value) =>{
        return true;
    }
    renderSuggestion = suggestion => (
        
        <div>
          {suggestion.Name}
        </div>
    );
    getUser = (UserId)=>{
        const {Users} = this.state;
        for(var i=0; i<Users.length; i++){
            if(Users[i].Id==UserId){
                return Users[i];
            }
        }
        return {
            Name: "null"
        }
    }
    onSupplierNameFetchRequested = ({ value }) => {
        this.setState({
            SupplierSuggestions: this.getSupplierSuggestions(value)
        });
    };
    onSupplierNameClearRequested = () => {
        this.setState({
            SupplierSuggestions: []
        });
    };
    clearSupplierDetails = () =>{
        var {data} = this.state;
        data.SupplierId=-1;
        data.SupplierName="";
        data.Number="";
        data.Number2="";
        data.Address="";
        this.setState({
            data:data,
        });
    }
    createNewSupplier = ()=>{
        const {databaseRequest,setPage}=this.props;
        let t = this;
        let {data} = this.state;

        this.setState({
            loading: true,
            loadingMsg: `Creating new supplier`
        });

        var req = {
            url: `/suppliers`,
            method: "POST"
        }
        
        databaseRequest(req.url,req.method, 
            JSON.stringify({
                Name: data.SupplierName,
                Number: data.Number,
                Number2: data.Number2,
                Address: data.Address
            })
        ).then(async (supplier_data)=>{
            data.SupplierId =  supplier_data.Id;
            data.SupplierName = supplier_data.Name;
            data.Number = supplier_data.Number;
            data.Number2 = supplier_data.Number2;
            data.Address = supplier_data.Address;

            console.log(data);
            //setTimeout(function(){
            setPage(`${data.SupplierName}`);
            t.setState({
                Supplier: supplier_data,
                data:data,
                loading: false,
                loadingMsg: "Loading suppliers...",
            });
            //}, 250);*/
        });
    }


    onJobIdChange = (event, { newValue }) => {
        var {data, ItemData}=this.state;
        if(event.type=="click"){
            if(this.state.EditingRow==-1){
                ItemData[ItemData.length-1].AssocJobs.push(newValue.toString());
            }
            else{
                ItemData[this.state.EditingRow].AssocJobs.push(newValue.toString());
            }
        }
        this.setState({
          data: data,
          RowJobSearch: event.type=="click"?"":newValue.toString()
        });
    };
    getJobId = suggestion => suggestion.Id;
    renderJobIdSuggestion = suggestion => (
        
        <div>
          {suggestion.Id} - {this.getCompanyName(suggestion.CompanyId, suggestion.ContactId)} | {suggestion.Name}
        </div>
    );
    getJobIdSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.Jobs.filter(lang =>
          lang.Id.toString().slice(0, inputLength) === inputValue
        );
    };

    onJobIdFetchRequested = ({ value }) => {
        this.setState({
            JobIdSuggestions: this.getJobIdSuggestions(value)
        });
    };
    onJobIdClearRequested = () => {
        this.setState({
            JobIdSuggestions: []
        });
    };

    editRow = (e)=>{
        var {ItemData} = this.state;
        

        var Item = ItemData[e];
        this.setState({
            EditingRow: e,
            RowDescription: Item.Description, 
            RowPrice: Item.Price, 
            RowQuantity: Item.Quantity});

    }
    saveRow = (e)=>{
        var {RowDescription, RowPrice, RowQuantity, ItemData} = this.state;
    
        for(var i=0; i<ItemData.length; i++){
            if(e==i){
                ItemData[i].Description = RowDescription;
                ItemData[i].Price = RowPrice;
                ItemData[i].Quantity = RowQuantity;
            }
        }
        RowDescription = "";
        RowPrice = "";
        RowQuantity = "";
        this.setState({
            RowDescription: RowDescription,
            RowPrice: RowPrice,
            RowQuantity: RowQuantity,
            ItemData: ItemData,
            EditingRow: -1
        });
    }
    removeJob = (e,job)=>{
        var {ItemData} = this.state;
        
        e.preventDefault();
        this.AutoSuggest.focus();
        var pos = this.state.EditingRow==-1?ItemData.length-1:this.state.EditingRow;

        const index = ItemData[pos].AssocJobs.indexOf(job);
        if (index > -1) { 
            ItemData[pos].AssocJobs.splice(index, 1); 
            
        }
        this.setState({
            ItemData: ItemData
        })
    }
    addNewRow = () =>{
        var {RowDescription, RowPrice, RowQuantity, ItemData} = this.state;
        ItemData[ItemData.length-1].Description = RowDescription;
        ItemData[ItemData.length-1].Price = RowPrice;
        ItemData[ItemData.length-1].Quantity = RowQuantity;

        ItemData.push({
            AssocJobs: [],
            Description: "",
            Price: "",
            Quantity: ""
        });
        RowDescription = "";
        RowPrice = "";
        RowQuantity = "";
        this.setState({
            RowDescription: RowDescription,
            RowPrice: RowPrice,
            RowQuantity: RowQuantity,
            ItemData: ItemData,
        });
    }
    deleteRow = (pos) =>{
        var {ItemData} = this.state;
        if(pos==-1){
            var RowDescription = ItemData[ItemData.length-2].Description; 
            var RowPrice = ItemData[ItemData.length-2].Price; 
            var RowQuantity = ItemData[ItemData.length-2].Quantity;

            ItemData.pop(); 
            
            this.setState({
                RowDescription: RowDescription,
                RowPrice: RowPrice,
                RowQuantity: RowQuantity,
                ItemData: ItemData});
        }
        else{
            ItemData.splice(pos, 1); 
            this.setState({ItemData: ItemData});
        }
    }
    moveUp = (e) =>{
        const {ItemData} = this.state;
        if(e > 0){
            var f = ItemData.splice(e, 1)[0];
            ItemData.splice(e-1, 0, f);
            this.setState({
                ItemData:ItemData
            });
        }
    }
    moveDown = (e) =>{
        const {ItemData} = this.state;
        var f = ItemData.splice(e, 1)[0];
        ItemData.splice(e+1, 0, f);
        this.setState({
            ItemData:ItemData
        });
    }
    formatPrice = (price)=>{
        if(typeof price === 'undefined')return (<React.Fragment><i className='text-left'>$</i><span className='text-right float-right'> - </span></React.Fragment>);
        if(price == 0)return (<React.Fragment><i className='text-left'>$</i><span className='text-right float-right'> - </span></React.Fragment>);


        var decimals = price%1;
        price = price-decimals;


        var value =  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +(decimals.toFixed(2).toString().substring(1));

        return (<React.Fragment><i className='text-left'>$</i><span className='text-right float-right'>{value}</span></React.Fragment>);
        //
    }
    modal = ()=>{
        return (

<>
<div className="modal show" id="exampleModal" style={{display: "block"}}tabindex="-1">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-body">
        {this.state.loadingMsg}
      </div>
    </div>
  </div>
</div>




        <div className="modal-backdrop show"></div>
      </>);
    }
    AutoSuggest = null;
    page = () =>{
        const {props} = this;
        const {SupplierName, SupplierId, PurchaseOrderId} = this.state.data;
        const {SupplierContacts} = this.state;
        var SupplierSuggestions = this.state.SupplierSuggestions;
        var JobIdSuggestions = this.state.JobIdSuggestions;
        var ItemData = this.state.ItemData;


        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        var supplierNameIP = {
            placeholder: 'Supplier Name',
            value: SupplierName,
            className: "form-control",
            onChange: this.onSupplierNameChange,
            disabled: this.state.disabled,
            autoComplete: "nofill"
          };
          var AssociateJobsIP = {
            placeholder: 'Assoc. Jobs',
            value: this.state.RowJobSearch,
            className: "form-control mb-2",
            onChange: this.onJobIdChange,
            disabled: this.state.data.SupplierId=== -1,
            autoComplete: "nofill",
            ref: (input) => { this.AutoSuggest = input; } 
          };
        var date = new Date().getTime();
        
        var User = this.getUser(localStorage.getItem('user'));
        if(this.state.data.PurchaseOrderId!==-1){
            date = this.state.data.CreatedDateTime;
            User = this.getUser(this.state.data.UserId);
        }
          //<input className="form-control"  placeholder='Description'  disabled={this.state.data.SupplierId=== -1} onChange={(e)=>{this.setState({RowDescription: e.target.value})}} value={this.state.RowDescription}/>
        return (
            <main role="main" className="container">
            <div className="p-5">
                
                <h2><i className="fe fe-shopping-cart"></i> Purchase Order</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">{this.state.data.PurchaseOrderId!==-1?`[PO${this.state.data.PurchaseOrderId}]`:""} | {this.data("SupplierName").length==0?"--New Purchase Order--":this.data("SupplierName")}</h1>
                        <h4><b>{User.Name}</b><p><TimeAgo time={date} /> -  ({new Date(date).toLocaleDateString()})</p></h4>
                    </div>
                    <div className="col p-0">
                        <button type="button" className="btn btn-info float-right" onClick={()=>{this.props.history.goBack()}}>Back to all Purchase Orders</button>
                    </div>
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label>Name</label>

                            {!(this.state.data.SupplierId>-1)?(
                            <Autosuggest
                                id="AS_SupplierName"
                                suggestions={SupplierSuggestions}
                                onSuggestionsFetchRequested={this.onSupplierNameFetchRequested}
                                onSuggestionsClearRequested={this.onSupplierNameClearRequested}
                                onSuggestionSelected={this.onSuggestionSelected}
                                getSuggestionValue={this.getSupplierName}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={supplierNameIP}
                            />
                            ):(
                                <input type="text" className="form-control"  placeholder="Supplier Name"  disabled={this.state.disabled} name="SupplierName" value={this.data("SupplierName")} onChange={(e)=>{this.onSupplierNameChange(e, {newValue: e.target.value})}}/>
                            )}

                        </div>    


                        <div className="form-group col-md-2">
                            <label>Phone Number</label>
                            <input type="text" className="form-control" placeholder="Phone Number" name="Number" value={this.data("Number")} onChange={this.onChange}/>
                        </div>    
                        <div className="form-group col-md-2">
                            <label>Additional Number</label>
                            <input type="text" className="form-control" placeholder="Additional Number" name="Number2" value={this.data("Number2")} onChange={this.onChange}/>
                        </div>     
                        <div className="form-group col-md text-right">
                                {this.state.data.Id==-1?(
                                    <React.Fragment>
                                        <button type="button" disabled className="btn btn-secondary float-right ml-2">Print (Save first)</button>
                                    </React.Fragment>):(
                                    <React.Fragment>
                                        <ReactToPrint
                                            trigger={() => {
                                                return <button type="button" className="btn btn-secondary float-right ml-2">Print</button>
                                            }}
                                            content={() => this.componentRef}
                                        />
                                        <div style={{ display: "none" }}><PurchaseOrderEditPrint {...this.props} ref={el => (this.componentRef = el)} /></div>
                                    </React.Fragment>
                                )}
                                {this.state.data.SupplierId>-1?(
                                    <>
                                <button type="button" onClick={this.downloadPDF} className="btn btn-primary float-right ml-2">Download</button>
                                    {<DesktopConnection
                                        topic="send_email"
                                        isConnected={(sendMessage) => {
                                            return (<button type="button" onClick={() => {this.sendEmail(sendMessage)}} className="btn btn-warning float-right ml-2">Email</button>)
                                        }}
                                    />}
                                    </>):<></>}

                               
                                </div>                
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-5">
                            <label>Address</label>
                            <input type="text" className="form-control" placeholder="Address" name="Address" value={this.data("Address")} onChange={this.onChange}/>
                        </div>    
                        {(this.state.data.SupplierId > -1)?

                        (<>
                            <div className="form-group col-md-1 align-self-end">
                                <button type="button" className="btn btn-danger" onClick={this.clearSupplierDetails}><i className="fe fe-x"></i></button>
                                
                            </div>
                            <div className="form-group col-md-1 align-self-end">
                                <Link to={`/system/suppliers/${this.state.data.SupplierId}`}>
                                    <button type="button" className="btn btn-info" onClick={this.clearSupplierDetails}><i className="fe fe-search"></i></button>
                                </Link>
                            </div>

                            
                            <div className="form-group col d-flex align-items-end flex-column align-self-end">
                                 
                                <button type="button" className="btn btn-info"  onClick={this.addContactPerson}><i className="fe fe-user-plus"></i> Add Contact Person</button>
                            </div>
                        </>
                        )
                        :
                            (this.state.data.SupplierName.length > 0)?(<div className="form-group col-md-1 align-self-end">
                            <button type="button" className="btn btn-success" onClick={this.createNewSupplier}>Create Supplier</button>
                        </div>
                                ):(<React.Fragment></React.Fragment>)}

                    </div>
                    

                    <div  className="row">
                        <div className='col'>
                                {SupplierContacts.map((row, i)=>{
                                    return (
                                    <div className="form-row" key={i}>
                                        <div className="form-group col-md-1">
                                            {i==0?(<label>Selected</label>):<></>}
                                            <div className="d-flex align-items-center">
                                                <input className="align-self-center m-3" checked={row.Checked} onChange={(e)=>{this.onCheckToggle(i,e)}} style={{width: "20px", height: "20px"}} type="checkbox" aria-label="Checkbox for following text input" />
                                            </div>
                                        </div>
                                        <ContactForm inputData={row} labels={i==0} index={i} onSave={this.OnContactUpdated}/>

                                    </div>
                                    
                                );
                                })}
                        
                        </div>
                    </div>

                        {this.state.data.SupplierId > -1 || true?(
                            <div className="form-row">
                                <table  className="table table-striped ">
                                    <thead  className="thead-dark ">
                                        <tr>
                                            
                                            <th>#</th>
                                            <th style={{width: '15px'}}></th>
                                            <th>Description</th>
                                            
                                            <th style={{width: '20rem'}}>Assoc. Jobs</th>
                                            <th style={{width: '8rem'}}>Quantity</th>
                                            <th style={{width: '8rem'}}>Price</th>
                                            <th style={{width: '15px'}}></th>
                                            <th style={{width: '30px'}}></th>
                                        </tr>
                                        
                                    </thead>
                                    <tbody>
                                        {
                                            ItemData.map((row, i)=>{
                                                if(i==this.state.EditingRow){
                                                    return (
                                                        <tr  key={i}>
                                                        <td className='pt-4 font-weight-bold'>{i+1}</td>
                                                        <td></td>
                                                        <td>
                                                        <textarea 
                                                            className="form-control"  
                                                            placeholder='Description'  
                                                            disabled={this.state.data.SupplierId=== -1} 
                                                            onChange={(e)=>{this.setState({RowDescription: e.target.value})}}
                                                            value={this.state.RowDescription}>
                                                                
                                                        </textarea>
                                                        </td>
                            
                                                        
                                                        <td className=''>
                                                        <Autosuggest
                                                                id="AS_SupplierName"
                                                                suggestions={JobIdSuggestions}
                                                                onSuggestionsFetchRequested={this.onJobIdFetchRequested}
                                                                onSuggestionsClearRequested={this.onJobIdClearRequested}
                                                                getSuggestionValue={this.getJobId}
                                                                renderSuggestion={this.renderJobIdSuggestion}
                                                                inputProps={AssociateJobsIP}
                                                            />
                                                            {
                                                            ItemData[i].AssocJobs.map((jobs, i)=>{
                                                                return (<button key={i} className="form-control btn-primary float-left mr-2 mb-2" disabled={this.state.data.SupplierId=== -1} onClick={(e)=>{ this.removeJob(e,jobs);}} style={{width: '5rem'}}>{jobs}<i className='fe fe-x'></i></button>);
                                                            })
                                                            }
            
                                                        </td>
                                                        <td><input className="form-control text-right"  disabled={this.state.data.SupplierId=== -1} placeholder='Quantity' onChange={(e)=>{if(!isNaN(e.target.value)){this.setState({RowQuantity: e.target.value})}}}   value={this.state.RowQuantity}/></td>
                                                        <td><input className="form-control text-right" disabled={this.state.data.SupplierId=== -1} placeholder='Price' onChange={(e)=>{if(!isNaN(e.target.value)){this.setState({RowPrice: e.target.value})}}} value={this.state.RowPrice}/></td>
                                                        <td className='pt-4'><i className="fe fe-save link p-2" onClick={()=>{this.saveRow(i)}}></i></td>
                                                        <td></td>
                                                    </tr>      
                                                    )
                                                }
                                                else{    
                                            if(i < ItemData.length-1){
                                                return (<tr key={i}>
                                                    
                                                    <td className='pt-4 font-weight-bold'>{i+1}</td>
                                                    <td></td>
                                                    <td>
                                                        <h3 className='mt-2 font-italic' data-toggle="tooltip" data-placement="top" title="Tooltip on top" dangerouslySetInnerHTML={{__html: row.Description.replaceAll("\n","<br/>")}}></h3>
                                                        

                                                        
                                                        </td>

                                                    <td><h4 className='mt-2'>{
                                                        row.AssocJobs.map((Job, j)=>{

                                                            return (<p>{this.getJobNameFromJob(Job)}</p>)

                                                        })

                                                    }</h4></td>
                                                    <td><h4 className='mt-2'>{row.Quantity}</h4></td>
                                                    <td><h4 className='mt-2'>{this.formatPrice(row.Price)}</h4></td>
                                                    <td className='pt-4'>
                                                    {this.state.EditingRow==-1?(<i className="fe fe-edit link p-2" onClick={()=>{this.editRow(i)}}></i>):(<React.Fragment></React.Fragment>)}
                                                        {this.state.data.SupplierId!==-1?(
                                                        <i className="fe fe-x-circle p-2 text-danger" style={{cursor: "pointer"}} onClick={(e)=>{this.deleteRow(i);}}></i>
                                                        ):(<React.Fragment></React.Fragment>)}
                                                        </td>
                                                    <td>
                                                        {i>0?<i className="fe fe-chevron-up ml-2  hover-dark" onClick={()=>{this.moveUp(i)}}></i>:<React.Fragment></React.Fragment>}
                                                        {i<(ItemData.length-2)?<i className="fe fe-chevron-down ml-2  hover-dark" onClick={()=>{this.moveDown(i)}}></i>:<React.Fragment></React.Fragment>}
                                                        
                                                    </td>
                                                </tr>)
                                            }
                                        }
                                            })
                                        }

                                        {(this.state.EditingRow==-1)?(
                                        <tr>
                                            <td className='pt-4 font-weight-bold'>{ItemData.length}</td>
                                            <td></td>
                                            <td>
                                            <textarea 
                                                className="form-control"  
                                                placeholder='Description'  
                                                disabled={this.state.data.SupplierId=== -1} 
                                                onChange={(e)=>{this.setState({RowDescription: e.target.value})}}
                                                value={this.state.RowDescription}>

                                            </textarea>
                                            </td>
                                            
                                            <td className=''>
                                            <Autosuggest
                                                    id="AS_SupplierName"
                                                    suggestions={JobIdSuggestions}
                                                    onSuggestionsFetchRequested={this.onJobIdFetchRequested}
                                                    onSuggestionsClearRequested={this.onJobIdClearRequested}
                                                    getSuggestionValue={this.getJobId}
                                                    renderSuggestion={this.renderJobIdSuggestion}
                                                    inputProps={AssociateJobsIP}
                                                />
                                                {
                                                ItemData[ItemData.length-1].AssocJobs.map((jobs, i)=>{
                                                    return (<button key={i} className="form-control btn-primary float-left mr-2 mb-2" disabled={this.state.data.SupplierId=== -1} onClick={(e)=>{ this.removeJob(e,jobs);}} style={{width: '5rem'}}>{jobs}<i className='fe fe-x'></i></button>);
                                                })
                                                }

                                            </td>
                                            <td><input className="form-control text-right"  disabled={this.state.data.SupplierId=== -1} placeholder='Quantity' onChange={(e)=>{if(!isNaN(e.target.value)){this.setState({RowQuantity: e.target.value})}}}   value={this.state.RowQuantity}/></td>
                                            <td><input className="form-control text-right" disabled={this.state.data.SupplierId=== -1} placeholder='Price' onChange={(e)=>{if(!isNaN(e.target.value)){this.setState({RowPrice: e.target.value})}}} value={this.state.RowPrice}/></td>
                                            <td className='pt-4'>
                                            {this.state.data.SupplierId!==-1?
                                            (
                                            <i className="fe fe-plus-circle link p-2" onClick={this.addNewRow}></i>
                                            ):(<React.Fragment></React.Fragment>)}

                                            {this.state.data.SupplierId!==-1 && ItemData.length>1?(
                                                <i className="fe fe-x-circle p-2 text-danger" style={{cursor: "pointer"}} onClick={(e)=>{this.deleteRow(-1);}}></i>
                                            ):(<React.Fragment></React.Fragment>)}
                                            </td>
                                            <td></td>
                                        </tr>):(<React.Fragment></React.Fragment>)
                                    }
                                    </tbody>
                                </table>
                            </div>
                        ):(<React.Fragment></React.Fragment>)}

                    {this.state.EditingRow==-1?(
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            {this.props.params.PurchaseOrderId!=="-1"?(
                            <button type="button" className="btn btn-success" disabled={this.state.data.SupplierId=== -1} onClick={this.saveBtn}>Save</button>
                            ):(
                            <button type="button" className="btn btn-success" disabled={this.state.data.SupplierId=== -1} onClick={this.saveBtn}>Create</button>
                            )}
                            {this.props.params.PurchaseOrderId!=="-1"?(
                            <button type="button" className="btn btn-danger ml-2" disabled={this.state.data.SupplierId=== -1} data-toggle="modal" data-target="#myModal" >Delete</button>
                            ):(<div/>)}
                            {this.props.params.PurchaseOrderId!=="-1"?(
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}`}>
                                <button type="button" className="btn btn-dark ml-3">History</button>
                            </Link>
                            ):(<div></div>)}
                        </div>
                    </div>
                    ):(<React.Fragment></React.Fragment>)}

                    </form>
                </div>
                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Purchase Order</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.data("Name")}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.modalLoading?this.modal():<></>}
            </main>
        );

    }
    render() {
        return this.page();
    }
}
//<input className="form-control"  placeholder='Description'  disabled={this.state.data.SupplierId=== -1} onChange={(e)=>{this.setState({RowDescription: e.target.value})}} value={this.state.RowDescription}/>
export default PurchaseOrderEdit;