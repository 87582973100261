import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import queryString from "query-string"
import DatePicker, {registerLocale} from "react-datepicker";

class Timesheet extends Component {
    interval = null;

    state = {
        loading: true,
        loadingMsg: "Loading users...",
        redirect: "",
        OperatorId: -1,
        totalHours: "",
        totalJobs: "",
        Operator: null,
        operators: [],
        Jobs: [],
        Companies: [],
        Contacts: [],
        FromDate: null,
        ToDate: null,
        dateError: false,
        data: [
            
        ]
    }
    getCompanyName = (CompanyId,ContactId)=>{
        var {Companies,Contacts} = this.state;
        if(CompanyId==-2){
            for(let i=0; i<Contacts.length; i++){
                if(Contacts[i].Id===ContactId){
                    return "["+Contacts[i].Name+"]";
                }
            }
        }
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===CompanyId){
                return Companies[i].Name;
            }
        }
        return null;
    }
    updateTable = async (FromDate, ToDate, OperatorId)=>{
        const {databaseRequest} = this.props;
        var { Jobs} = this.state;
        if(typeof FromDate !== 'undefined' && typeof ToDate !== 'undefined'){
            if(FromDate !==null && ToDate!==null){
                if(FromDate.getTime() > 0 && ToDate.getTime() > 0 && OperatorId !==1){
                    var timesheet = await databaseRequest(`/timesheet?OperatorId=${OperatorId}&CreatedDateTime:>=${FromDate.getTime()}&SubmittedDateTime:<=${ToDate.getTime()}`,"GET");
                    
                    var data = [];
                    var totalHours = 0;
                    
                    for(let j=0; j<timesheet.length; j++){
                        var found = -1;
                        var obj = {
                            JobId: timesheet[j].JobId,
                            CompanyName: "",
                            JobName: "",
                            DatePick: "",
                            StartTime: timesheet[j].CreatedDateTime,
                            FinishTime: timesheet[j].SubmittedDateTime,
                            Hours: this.secondsToTime(((timesheet[j].SubmittedDateTime-timesheet[j].CreatedDateTime)/1000))
                        };
                        totalHours += ((timesheet[j].SubmittedDateTime-timesheet[j].CreatedDateTime)/1000);
                        var found = -1;
                        for(var i=0; i<Jobs.length; i++){
                            if(Jobs[i].Id==obj.JobId){
                                found = i;
                            }
                        }
                        if(found!==-1){
                            obj.JobName =  Jobs[found].Name;
                            obj.CompanyName = this.getCompanyName(Jobs[found].CompanyId,Jobs[found].ContactId)
                            obj.DateEnable = true;
                        }
                        else{
                            obj.JobName =  "Not Found";
                            obj.CompanyName = "Not Found";
                            obj.DateEnable = false;
                        }

                        data.push(obj)
                    }
                    this.setState({totalHours: this.secondsToTime(totalHours), totalJobs: timesheet.length, data: data});
                    console.log(timesheet);
                }
            }
        }
    }
    onFromDateChange = (date) => {
        this.setState({FromDate:date});
        
        var {ToDate, OperatorId} = this.state;
        var FromDate = date;

        if (FromDate !== null && ToDate !==null){
            if(FromDate.getTime() > ToDate.getTime()){
                this.setState({dateError: true});
            }
            else{
                this.setState({dateError: false});
            }
        }
        this.updateTable(FromDate, ToDate, OperatorId);
    };
    onToDateChange = (date) => {

        this.setState({ToDate:date});
        
        var {FromDate, OperatorId} = this.state;
        var ToDate = date;
        if (FromDate !== null && ToDate !==null){
            if(FromDate.getTime() > ToDate.getTime()){
                this.setState({dateError: true});
            }
            else{
                this.setState({dateError: false});
            }
        }
        this.updateTable(FromDate, ToDate, OperatorId);
    };
    onChange = (e) => {
        let data = this.state.data;
        data[e.target.name]=e.target.value;
        this.setState({ data: data});  
    };

    onJobIdChange = (e,rowNumber) => {
        const {databaseRequest}=this.props;
        const Jobs = this.state.Jobs;

        let data = this.state.data;
        
        var value = Number(e.target.value);
        if(!Number.isInteger(value)){
            return;
        }
        if(typeof data[rowNumber].JobId !== undefined){
            data[rowNumber].JobId = value;
        }

        var found = -1;
        for(var i=0; i<Jobs.length; i++){
            if(Jobs[i].Id==value){
                found = i;
            }
        }
        if(found!==-1){
            data[rowNumber].JobName =  Jobs[found].Name;
            data[rowNumber].CompanyName = this.getCompanyName(Jobs[found].CompanyId,Jobs[found].ContactId)
            data[rowNumber].DateEnable = true;
        }
        else{
            data[rowNumber].JobName =  "Not Found";
            data[rowNumber].CompanyName = "Not Found";
            data[rowNumber].DateEnable = false;
        }
        this.setState({data:data});
    }

    onStartTimeChange = (e,rowNumber) => {
        let data = this.state.data;

        if(typeof data[rowNumber].StartTime !== undefined){
            data[rowNumber].StartTime = e.target.value;
        }
        data[rowNumber].FinishTimeEnable = true;
        this.setState({data:data});
    }
    onFinishTimeChange = (e,rowNumber) => {
        let data = this.state.data;

        if(typeof data[rowNumber].FinishTime !== undefined){
            data[rowNumber].FinishTime = e.target.value;
        }
        if(data[rowNumber].StartTime > 0 && data[rowNumber].FinishTime> 0){
            var startdate = new Date(data[rowNumber].DatePick);
            var finishdate = new Date(data[rowNumber].DatePick);
            startdate.setHours(data[rowNumber].StartTime - data[rowNumber].StartTime%1);
            startdate.setMinutes((data[rowNumber].StartTime%1)*60);

            finishdate.setHours(data[rowNumber].FinishTime - data[rowNumber].FinishTime%1);
            finishdate.setMinutes((data[rowNumber].FinishTime%1)*60);

            data[rowNumber].StartTimeReal = startdate.getTime();
            data[rowNumber].FinishTimeReal = finishdate.getTime();
            data[rowNumber].Hours = this.secondsToTime(((finishdate.getTime()-startdate.getTime())/1000));
        }
        this.setState({data:data});
        
    }
    onDateChange = (date,rowNumber) => {
        let data = this.state.data;
 
        if(typeof data[rowNumber].DatePick !== undefined){
            data[rowNumber].DatePick = date.getTime();
        }
        data[rowNumber].StartTimeEnable = true;
        this.setState({data:data});
    };

    onOperatorChange = (e) =>{
        const {operators, ToDate, FromDate} = this.state;
        var Id = e.target.value;
        var Operator = null;
        for(var i=0; i<operators.length; i++){
            if(Id==operators[i].Id){
                Operator = operators[i];
            }
        }
        this.setState({
            OperatorId: Id,
            Operator: Operator

        })
        this.updateTable(FromDate, ToDate, Id);
    }

    addNewRow = () =>{
        let data = this.state.data;
        data.push({
            JobId: -1,
            CompanyName: "",
            JobName: "",
            DateEnable: false,
            DatePick: "",
            StartTimeEnable: false,
            FinishTimeEnable: false,
            StartTime: -1,
            FinishTime: -1,
            StartTimeReal: -1,
            FinishTimeReal: -1,
            Hours: 0
        });
        this.setState({data:data});
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    
    async componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        var operators = await databaseRequest(`/operators`,"GET"); 
        var Jobs = await databaseRequest(`/jobs?State:not=8&State:not=11&State:not=20`,"GET"); 
        var companies = await databaseRequest("/companies","GET");
        var contacts = await databaseRequest("/contacts","GET");
        setPage("Timesheet - Search");

        t.setState({
            Jobs: Jobs,
            loading: false, 
            operators: operators,
            Companies: companies,
            Contacts: contacts
        });
    }
    saveBtn = async () =>{
        const {databaseRequest}=this.props;
        let t = this;
        let data = this.state.data;
        let OperatorId = this.state.OperatorId;

        
        t.setState({
            loading: true, 
            loadingMsg: "Saving timesheet"
        });
        for(var i=0; i<data.length; i++){
            if(data[i].JobId > 0 && data[i].StartTimeReal > 0 && data[i].FinishTimeReal){
                var error = await databaseRequest(`/timesheet/${OperatorId}/${data[i].JobId}/${data[i].StartTimeReal}/${data[i].FinishTimeReal}`, "POST"); 
            }
        }



        t.setState({
            loading: false, 
            loadingMsg: "Saving timesheet",
            data: [
                {
                    JobId: -1,
                    CompanyName: "",
                    JobName: "",
                    DateEnable: false,
                    DatePick: "",
                    StartTimeEnable: false,
                    FinishTimeEnable: false,
                    StartTime: -1,
                    FinishTime: -1,
                    StartTimeReal: -1,
                    FinishTimeReal: -1,
                    Hours: 0
                }
            ]
        });

    }

    secondsToTime = (time)=>{
        var seconds = time%60;
        var minutes = ((time - seconds)/60)%60;
        var hours = (time-seconds-(minutes*60))/3600;
        

        return `${hours<10?"0"+hours:hours}:${minutes<10?"0"+minutes:minutes}`
    }
    componentWillUnmount(){
        clearInterval(this.interval);    
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    error = ()=>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.Error}</h1>
                    <div>
                        <Link to="/home"><button type="button" className="btn btn-secondary">Home</button></Link>
                    </div>
                </div>
            </main>
        )
    }
    time = () =>{
        <input className='form-control text-right' type="text" placeholder="00:00" style={{"width":"5em"}}/>
    }

    validFormClass = (row) =>{
        if(row.JobId==-1){
            return "form-control text-align-right";
        }
        if(row.JobId > -1 && row.DateEnable){
            return "form-control text-align-right is-valid";
        }
        if(row.JobId > -1 && !row.DateEnable){
            return "form-control text-align-right is-invalid";
        }

    }

    page = () =>{
        const {props} = this;
        const query = queryString.parse(this.props.location.search);
        if(this.state.loading){
            return this.loading();
        }
        const DatepickerInput = ({ ...props }) => (
            <input type="text" {...props} readOnly />
          );

        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }

        var times = [];
        for(var h = 6.5; h<=17.5; h+=0.25){
            times.push({h:h, hour:h - h%1, minute: (h%1)*60});
        }

        return (
            
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-file-plus"></i> {this.state.Operator!==null?"Timesheet - "+this.state.Operator.Name:"Timesheet"}</h2>
                <div className="row form-group">
                    <div className="form-group col-md-3">
                        <label>Operator</label>
                        <select type="text" ref={(input) => { this.jobId = input; }}  autoComplete="off"  className="form-control" onChange={this.onOperatorChange} placeholder="Number" name="Number" >
                        <option>-</option>
                        {this.state.operators.map((row, i) => {
                            return (<option key={i} selected={row.Id==this.state.OperatorId?true:false} value={row.Id}>{row.Name}</option>)
                        })}
                        </select>
                    </div> 
                    <div className="form-group col-md-3">
                    <label>From</label>
                    <DatePicker  
                                dateFormat="dd/MM/yyyy" 
                                minDate={new Date().setMonth(new Date().getMonth() - 12)} 
                                placeholderText="From Date" 
                                className={this.state.dateError?"form-control text-right is-invalid":"form-control text-right"} 
                                disabled={this.state.OperatorId===-1}
                                selected={this.state.FromDate}
                                onChange={this.onFromDateChange} 
                                withPortal
                                customInput={<DatepickerInput />}
                                />
                    </div>
                    <div className="form-group col-md-3">
                    <label>To</label>
                    <DatePicker  
                                dateFormat="dd/MM/yyyy" 
                                minDate={new Date().setMonth(new Date().getMonth() - 12)} 
                                placeholderText="To Date" 
                                className={this.state.dateError?"form-control text-right is-invalid":"form-control text-right"} 
                                disabled={this.state.OperatorId===-1}
                                onChange={this.onToDateChange} 
                                selected={this.state.ToDate}
                                withPortal
                                customInput={<DatepickerInput />}
                                />
                    </div>
                </div>
                <div className="row form-group">
                    <table className="table table-striped ">
                        <thead className="thead-dark ">
                            <tr>
                                <th style={{width: "8rem"}} >Job No.</th>
                                <th style={{width: "12rem"}} >Company/Contact</th>
                                <th style={{width: "12rem"}} >Name</th>
                                <th style={{width: "10rem"}} >Date</th>
                                <th>Start Time</th>
                                <th>Finish Time</th>
                                <th>Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((row, i) => {
                            return (<tr key={i}>
                                <td><Link to={`/jobs/${row.JobId}`}>{row.JobId}</Link></td>
                                <td>{row.CompanyName}</td>
                                <td>{row.JobName}</td>
                                <td>
                                {new Date(row.StartTime).toLocaleDateString()}
                                </td>
                                <td>
                                    {new Date(row.StartTime).toLocaleTimeString()}
                                </td>
                                <td>
                                    {new Date(row.FinishTime).toLocaleTimeString()}   
                                </td>
                                <td><p className='p-2 font-weight-bold'>{row.Hours==0?"":row.Hours}</p></td>
                              </tr>);
                            })
                        }

                        </tbody>
                    </table>
                </div>
                {this.state.OperatorId!==-1?
                <div className="row float-right text-right w-30">
                    <table className='table'>
                        <thead></thead>
                        <tbody>
                            <tr><th className='table-dark border-0'>Hours</th><td className='table-secondary p-3 text-right font-weight-bold border-0' style={{width: "15rem"}}>{this.state.totalHours}</td></tr>
                            <tr><th className='table-dark border-0'>Jobs</th><td className='table-secondary p-3 text-right border-0' >{this.state.totalJobs}</td></tr>
                        </tbody>
                    </table>
                    </div>
                :
                <p>No operator selected</p>}
            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default Timesheet;