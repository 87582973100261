import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import queryString from "query-string"
import ReactToPrint from 'react-to-print';
import ProcessListFabricationPrint from './ProcessListFabricationPrint.js'

class Home extends Component {
    jobInput = null;
    
    state = {
        loading: true,
        editing: false,
        printEnable: false,
        search: "",
        redirect: "",
        processes: [
            { id: 0, name: "Drafting", selected: false},
            { id: 1, name: "Material Prep.", selected: false},
            { id: 2, name: "Laser", selected: false},
            { id: 3, name: "Turning", selected: false},
            { id: 4, name: "Milling", selected: false},
            { id: 5, name: "Grinding", selected: false},
            { id: 6, name: "Fitting", selected: false},
            { id: 7, name: "Folding", selected: false},
            { id: 8, name: "Pressing", selected: false},
            { id: 9, name: "Welding", selected: false},
            { id: 10, name: "Supply", selected: false},
            { id: 11, name: "Hypermill", selected: false}
        ],
        FoldingJobs: [],
        PressingJobs: [],
        WeldingJobs: [],
    }

    async componentDidMount() {
        let t=this;
        
        var query = queryString.parse(this.props.location.search);
        var search = "";
        if(typeof query.q !== 'undefined'){
            search = query.q;

        }
        const {socketServer, setPage} = this.props;
        setPage("Machining - Process List");
        this.reload(true, search, true);
        
        this.setState({search: search, loading: false});
    }
    reload = async (loading, search, focus)=>{
        const {setPage, databaseRequest}=this.props;
        let t =this;
        
        setPage("Machining - Process List");
        this.setState({loading: loading,printEnable: false});
        var Jobs = await databaseRequest("/jobs?CompletedDateTime=-1","GET");
        var ProcessList = await databaseRequest("/schedule/process-list/fabrication?q="+search,"GET");
        var Companies = await databaseRequest("/companies","GET");
        
        var Contacts = await databaseRequest("/contacts","GET");
        var Contacts = await databaseRequest("/contacts","GET");
        var FoldingJobs = [];
        var PressingJobs = [];
        var WeldingJobs = [];
        for(var i=0; i<ProcessList.length; i++){
            ProcessList[i].Job = null;
            for(var j=0; j<Jobs.length; j++){
                if(Jobs[j].Id==ProcessList[i].JobId){
                    ProcessList[i].Job = Jobs[j];
                }
            }

            if(ProcessList[i].Job !== null){
                for(var j=0; j<Companies.length; j++){
                    if(Companies[j].Id==ProcessList[i].Job.CompanyId){
                        ProcessList[i].Company = Companies[j];
                    }
                }
                for(var j=0; j<Contacts.length; j++){
                    if(Contacts[j].Id==ProcessList[i].Job.ContactId){
                        ProcessList[i].Contact = Contacts[j];
                    }
                }
                if(ProcessList[i].Process==7 && ProcessList[i].Job.State < 6){
                    FoldingJobs.push(ProcessList[i]);
                }
                if(ProcessList[i].Process==8 && ProcessList[i].Job.State < 6){
                    PressingJobs.push(ProcessList[i]);
                }
                if(ProcessList[i].Process==9 && ProcessList[i].Job.State < 6){
                    WeldingJobs.push(ProcessList[i]);
                }
            }
            else{
                console.error("Job not found: "+ProcessList[i].JobId)
            }
        }
        this.setState({
            ProcessList: ProcessList,
            FoldingJobs: FoldingJobs,
            PressingJobs: PressingJobs,
            WeldingJobs: WeldingJobs,
            Jobs: Jobs,
            Companies: Companies,
            Contacts, Contacts,
        });
        if(focus){
            this.jobInput.focus();
        }
        setTimeout(()=>{t.setState({printEnable: true})}, 100);
        
    }
    onSearch = async (event) => {
        const {databaseRequest,view}=this.props;
        var search = event.target.value;
        this.setState({loading: true, search: search,});
        this.props.history.replace(`/schedule/process-list/fabrication?q=${search}`);

        this.reload(false, search, false);
    }
    rowData = (row) =>{
        var name = row.Company.Name;
        const ProcessList = this.state.ProcessList;
        if(row.Company.Id==-2){
            name = row.Contact.Name;
        }
        var otherWork = "";
        for(var i=0; i<ProcessList.length; i++){
            if(ProcessList[i].JobId==row.JobId && ProcessList[i].ProcessId!=row.ProcessId){
                if(ProcessList[i].ProcessId > row.ProcessId){

                    if(ProcessList[i].Process=="3"){otherWork += "Turn";}
                    if(ProcessList[i].Process=="4"){otherWork += "Mill";}
                    if(ProcessList[i].Process=="5"){otherWork += "Grind";}
                    if(ProcessList[i].Process=="7"){otherWork += "Folding";}
                    if(ProcessList[i].Process=="8"){otherWork += "Pressing";}
                    if(ProcessList[i].Process=="9"){otherWork += "Welding";}
                    otherWork += " after, ";
                }
                else{
                    if(ProcessList[i].Process=="3"){otherWork += "Turn";}
                    if(ProcessList[i].Process=="4"){otherWork += "Mill";}
                    if(ProcessList[i].Process=="5"){otherWork += "Grind";}
                    if(ProcessList[i].Process=="7"){otherWork += "Folding";}
                    if(ProcessList[i].Process=="8"){otherWork += "Pressing";}
                    if(ProcessList[i].Process=="9"){otherWork += "Pressing";}
                    otherWork += " before, ";
                }
            }
        }
        otherWork = otherWork.slice(0, -2); 
        return (
        <p>
            <Link to={"/jobs/"+row.Job.Id}><h2 className="p-1 pb-0 mb-0">[{row.Job.Id}] {name} | {row.Job.Name} </h2></Link>
            <div className="font-weight-bold">{otherWork}</div>

            {row.Job.Scope.split("\\n").map(function(item, idx) {
                return (
                    <span key={idx}>
                        {item}
                        <br/>
                    </span>
                )
            })}
        </p>)
    }
    secondsToTime = (time)=>{
        var seconds = time%60;
        var minutes = ((time - seconds)/60)%60;
        var hours = (time-seconds-(minutes*60))/3600;
        return `${hours<10?"0"+hours:hours}:${minutes<10?"0"+minutes:minutes}:${seconds<10?"0"+seconds:seconds}`
    }
    setRedirect = (url) =>{
        this.setState({redirect:url})
    }
    render(){
        if(this.state.redirect.length>1){
            this.props.history.push(this.props.location.pathname);
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        if(this.state.loading){
            return (
                <h1>Loading..</h1>
            )
        }
        return (
            <main role="main" className="p-2 pt-6">
                 <h2><i className="fe fe-trending-up"></i> Fabrication Process List</h2>
                 <div className="row p-3">
                    <form className="form-inline">
                        <input ref={(input) => { this.jobInput = input; }}  className="form-control mr-sm-2" type="search" onChange={this.onSearch} value={this.state.search} placeholder="Search" aria-label="Search"/>                      
                        
                        {this.state.printEnable?(<React.Fragment>
                        <ReactToPrint
                                            trigger={() => {
                                                
                                                return <button type="button" className="btn btn-secondary float-right ml-2">Print</button>
                                            }}
                                            content={() => this.componentRef}
                                        />
                                        <div style={{ display: "none" }}><ProcessListFabricationPrint {...this.props} ref={el => (this.componentRef = el)} /></div>
                        </React.Fragment>):(<React.Fragment></React.Fragment>)}
                    </form>

                </div>

                 <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <tr><th scope="col">WELDING</th></tr>
                            {
                                this.state.WeldingJobs.map((row, i) => {
                                    return (<tr>{this.rowData(row)}</tr>)
                                })
                            }
                        </table>
                    </div>
                    <div className="col" >
                        <table className="table table-bordered">
                            <tr><th scope="col">PRESSING</th></tr>
                            {
                                this.state.PressingJobs.map((row, i) => {
                                    return (<tr>{this.rowData(row)}</tr>)
                                })
                            }
                        </table>
                    </div>

                    </div>

            </main>

            
        )
    }
}

export default Home;