import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import md5 from 'md5';


class UsersEdit extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading operators...",
        redirect: "",
        processes: [],
        data: null,
        processes: [
            { id: 0, name: "Drafting", selected: false},
            { id: 1, name: "Material Prep.", selected: false},
            { id: 2, name: "Laser", selected: false},
            { id: 3, name: "Turning", selected: false},
            { id: 4, name: "Milling", selected: false},
            { id: 5, name: "Grinding", selected: false},
            { id: 6, name: "Fitting", selected: false},
            { id: 7, name: "Folding", selected: false},
            { id: 8, name: "Pressing", selected: false},
            { id: 9, name: "Welding", selected: false},
            { id: 10, name: "Supply", selected: false},
            { id: 11, name: "Hypermill", selected: false}
        ],
    }
    onChange = (e) => {
        let data = this.state.data;
        data[e.target.name]=e.target.value;
        this.setState({ data: data});  
    };
    saveBtn = () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: `Saving ${this.state.data.Name}...`
        });
        var data = this.state.data;
        var req = {
            url: `/operators/${params.OperatorId}`,
            method: "PUT"
        }
        if(params.OperatorId==="-1"){
            req.url="/operators";
            req.method="POST";
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(data)
        ).then((data)=>{
            setTimeout(function(){
                setPage(`${data.Name}`);
                t.setState({
                    loading: false,
                    loadingMsg: "Loading operators...",
                    redirect: "/system/operators",
                    data: data
                });
            }, 250);
        });
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    deleteBtn = () =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("Name")+"..."
        });
        databaseRequest(`/operators/${params.OperatorId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading operators...",
                    redirect: "/system/operators"
                });
            }, 1000);
        });

    }
    componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        var {processes} = this.state;
        let t = this;
        setPage(`Operators`);
        if(params.OperatorId==="-1"){
            t.setState({
                data:{
                    Name: "",
                    Processes: "[]",
                    HourlyRate: 120,
                    TimesheetStarted: 0,
                    TimesheetPaused: 0,
                    TimesheetJobId: -1,
                    TimesheetGuid: "",
                    OrderBy: -1
                },
                loadingMsg: "Loading operators...",
                loading: false
            });
        }
        else{
            databaseRequest(`/operators/${params.OperatorId}`,"GET").then((data)=>{
                    setPage(`${data.Name}`);
                    var list = [];
                    try{
                        list = JSON.parse(data.Processes);
                    }
                    catch(e){
                        list = [];
                    }
                    for(var j=0; j<processes.length; j++){
                        processes[j].selected=false;
                        for(var k=0; k<list.length; k++){
                            if(list[k]==processes[j].id){
                                processes[j].selected=true;
                            }
                        }
                        
                    } 
                    t.setState({
                        loading: false,
                        data: data,
                        processes: processes
                    });
            });
        }
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    onProcessClick = (i) =>{
        var {processes,data} = this.state;
        if(i>=0 && i<processes.length){
            processes[i].selected=!processes[i].selected;   
        }
        var list = [];
        for(var j=0; j<processes.length; j++){
            if(processes[j].selected){
                list.push(processes[j].id);
            }
        } 
        data.Processes = JSON.stringify(list);
        this.setState({
            data: data,
            processes: processes
        })
    }
    processList = () =>{
        const {processes} = this.state;
        return (
        <div className="row justify-content-cente pb-4">
            {processes.map((row, i) => {
                    if(row.selected){
                        return (<button key={i} type="button" className="btn tag-selected" onClick={()=>{this.onProcessClick(i)}}><i className="fe fe-minus"></i> {row.name}</button>)
                    }
                    else{
                        return (<button key={i} type="button" className="btn tag" onClick={()=>{this.onProcessClick(i)}}><i className="fe fe-plus"></i> {row.name}</button>)
                    }
                    
            })}
        </div>);
    }
    page = () =>{
        const {props} = this;
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-user"></i> Operators</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">{this.data("Name").length==0?"--New Operator--":this.data("Name")}</h1>
                    </div>
                    <div className="col p-0">
                        <Link to="/system/operators"><button type="button" className="btn btn-info float-right">Back to all Operators</button></Link>
                    </div>
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label>Full Name</label>
                            <input type="text" className="form-control" placeholder="Full Name" name="Name" value={this.data("Name")} onChange={this.onChange}/>
                        </div>                      
                    </div>
                    <div className="form-row">
                        {this.processList()}

                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            {this.props.params.UserId!=="-1"?(
                            <button type="button" className="btn btn-success" onClick={this.saveBtn}>Save</button>
                            ):(
                            <button type="button" className="btn btn-success" disabled={!this.state.buttonState} onClick={this.saveBtn}>Create</button>
                            )}
                            {this.props.params.UserId!=="-1"?(
                            <button type="button" className="btn btn-danger ml-2" data-toggle="modal" data-target="#myModal" >Delete</button>
                            ):(<div/>)}
                            {this.props.params.UserId!=="-1"?(
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}`}>
                                <button type="button" className="btn btn-dark ml-3">History</button>
                            </Link>
                            ):(<div></div>)}
                        </div>
                    </div>
                    </form>
                </div>
                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete User</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.data("Name")}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default UsersEdit;