import React, { Component } from 'react';
import State from '../../Components/State.js';
import TimeAgo from '../../Components/TimeAgo.js';
import {PortraitOrientation} from '../../PortraitOrientation.jsx'
import '../../JobEdit.css';

class JobsEditPrint extends Component {
    state = {
        loading: true,
        history: [],
        loadingMsg: "Loading job...",
        daysToComplete: 0,
        processes: [
            { id: 0, name: "Drafting", selected: false},
            { id: 1, name: "Material Prep.", selected: false},
            { id: 2, name: "Laser", selected: false},
            { id: 3, name: "Turning", selected: false},
            { id: 4, name: "Milling", selected: false},
            { id: 5, name: "Grinding", selected: false},
            { id: 6, name: "Fitting", selected: false},
            { id: 7, name: "Folding", selected: false},
            { id: 8, name: "Pressing", selected: false},
            { id: 9, name: "Welding", selected: false},
            { id: 10, name: "Supply", selected: false},
            { id: 11, name: "Hypermill", selected: false}
        ],
    }
    calculateWorkDates = (date)=>{

        if(date!==null){
            var today = new Date();
            today.setHours(0,0,0,0);
            var endDate = date;
            endDate.setHours(0,0,0,0);

            var days = 0;
            for(var i=today.getTime(); i<endDate.getTime(); i+=86400000){
                var temp = new Date(i)
                if(temp.getDay()!==5 && temp.getDay()!==6){
                    days++;
                }
            }
            return days;
        }
        
        return 0;
    }
    UserName = (e) =>{
        const {Users} = this.state;
        for(let i=0; i<Users.length; i++){
            if(Users[i].Id===e){
                return Users[i].Name;
            }
        }
        return " - ";
    }
    async componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        var Hours = await databaseRequest(`/jobs/hours/=${params.JobId}`,"GET");
        var Job = await databaseRequest(`/jobs/${params.JobId}`,"GET");
        var Users = await databaseRequest("/users","GET");

        if(typeof Job.Id !=='undefined'){
            var Company = await databaseRequest(`/companies/${Job.CompanyId}`,"GET");
            var Contact = await databaseRequest(`/contacts/${Job.ContactId}`,"GET");
            var steps = await databaseRequest(`/jobs/processes/${params.JobId}`,"GET");
            var history = await databaseRequest(`/history/${Job.Guid}`,"GET");

            var Operators = await databaseRequest(`/operators`,"GET");
            for(var k=0; k<steps.length; k++){
                steps[k].descShown=steps[k].description.length>0
            }
            for(var k=0; k<steps.length; k++){
                if(steps[k].process==2){
                    steps[k].OperatorId=0;
                }
                steps[k].descShown=steps[k].description.length>0
                for(var j=0; j<Operators.length; j++){
                    if(steps[k].OperatorId===Operators[j].Id){
                        steps[k].OperatorName=Operators[j].Name;
                    }
                }
            }

            var uploadList = [];
            var uploads = [];
            try{
                uploadList = JSON.parse(Job.Uploads);      
                for(let i=0; i<uploadList.length; i++){
                    var result = await databaseRequest(`/upload/${uploadList[i]}`,"GET");
                    if(result.length==1){
                        uploads.push(result[0]);
                    }
                }
            }
            catch(e){

            }
            t.setState({
                Job: Job,
                Company: Company,
                Contact: Contact,
                history: history,
                Users: Users,
                CompanyName: Company.Name,
                ContactName: Contact.Name,
                dueDate: new Date(Job.DueDateTime),
                uploads: uploads,
                daysToComplete: this.calculateWorkDates(new Date(Job.DueDateTime)),
                Hours: Hours,
                steps: steps,
                data:{
                    Id: params.JobId,
                    Name: Job.Name,
                    CompanyId: Job.CompanyId,
                    ContactId: Job.ContactId,
                    CompanyName: Company.Name,
                    ContactName: Contact.Name,
                    ContactNumber: Contact.Number,
                    ContactNumber2: Contact.Number2,
                    IsBusiness: (Job.IsBusiness).toString(),
                    IsUrgent: (Job.IsUrgent).toString(),
                    Email: Contact.Email,
                    Attachments: "",
                    Scope: Job.Scope.toString().replaceAll("\\n","\n"),
                    State: Job.State,
                    DueDateTime: Job.DueDateTime,
                    CreatedDateTime: Job.CreatedDateTime,
                    UpdatedDateTime: Job.UpdatedDateTime,
                    CompletedDateTime: Job.CompletedDateTime,          
                    Guid: Job.Guid,
                    QuoteState: Job.QuoteState,
                    Uploads: uploadList

                },
                loadingMsg: "Loading job...",
                loading: false
            });
        }

    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
     }
     ProcessName = (e)=>{
        const {processes} = this.state;
        for(let i=0; i<processes.length; i++){
            if(processes[i].id===e){
                return (processes[i].name);
            }
        }
        return "";
    }
    showCompanyName = () =>{
        if(this.state.data.CompanyName.length==0 || this.state.data.CompanyName=="Personal"){
        return (<i> {this.state.Contact.Name} - </i>);
        }
        return (<i> {this.state.Company.Name} - </i>);

    }
    page = () =>{
        const {props} = this;
        const {steps, history} = this.state;


        if(this.state.loading){
            return this.loading();
        }

        var createdBy = "";
        if(history.length > 0){
            createdBy = history[history.length-1].Name;
        }
        
        return (
            <main role="main" className="p-5">
                <PortraitOrientation/>
                <div className="">             
                        <div className="row form-group mb-0">
                            <div className="col p-0 pt-3">
                                <h1 className="mb-0 text-black"><i className="fe fe-flag"></i> {this.showCompanyName()} {this.state.Job.Name}</h1>
                                <small className="text-black">Printed: {new Date().toLocaleString()}<br/>Created by: {createdBy}</small>

                            </div>

                            <div className="col-auto p-0">               
                                <img src="/logo-full.svg" alt="" width="150" height="70" />
                                <h1 className="text-black text-center mt-2"><small className="mr-4">Job No: </small>{this.state.Job.Id}</h1>                        
                            </div>
                        </div>
                        <div className="row form-group mt-1">

                          <table className="table w-100  table-bordered">
                            <tbody className="text-black">
                                <tr>
                                    <th style={{width: '10rem'}} className="text-black border-right-0">Job Created</th>
                                    <td style={{width: '12rem'}} className="text-black border-left-0">{new Date(this.state.data.CreatedDateTime).toLocaleString()}</td>
                                    {/*<td className="border-top-0 border-bottom-0" colSpan="2"></td>*/}
   

                                    <th style={{width: '10rem'}} colSpan="3" className="text-black text-right">Due Date</th>
                                    <td style={{width: '12rem'}} className="text-black text-right border-0">
                                        {new Date(this.state.data.DueDateTime).toLocaleDateString()}
                                        <br/>
                                        <small className="text-black">
                                        {this.state.daysToComplete>0?`Due in ${this.state.daysToComplete} business day${this.state.daysToComplete>1?"s":""}`:``}
                                        </small>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-black border-right-0">Last Updated</th>
                                    <td className="text-black border-left-0">{new Date(this.state.data.UpdatedDateTime).toLocaleString()}</td>
                                    {/*<td className="border-top-0 border-bottom-0" colSpan="2"></td>*/}
                                    <th className="text-black text-right" colSpan="3">Phone Number</th>
                                    <td className="text-black text-right">{this.state.Contact.Number}</td>

                                    
                                </tr>

                                <tr>
                                    <th className="text-black">Company Name</th>
                                    <td className="text-black">{this.state.Company.Name}</td>
                                    {/*<td className="border-top-0 border-bottom-0" colSpan="2"></td>*/}
                                    <th className="text-black text-right" colSpan="3">Additional Number</th>
                                    <td className="text-black text-right">{this.state.Contact.Number2}</td>

                                </tr>
                                <tr>
                                    <th className="text-black">Contact Name</th>
                                    <td className="text-black">{this.state.Contact.Name}</td>
                                    {/*<td className="border-top-0 border-bottom-0" colSpan="2"></td>*/}
                                    <th className="text-black text-right" colSpan="3">Quote Number</th>
                                    <td className="text-black text-right">{this.state.Job.QuoteNumber}</td>

                                </tr>
                                <tr>
                                    <th className="text-black">Email</th>
                                    <td className="text-black" colSpan="3">{this.state.Contact.Email}</td>
                                    <th className="text-black text-right">PO Number</th>
                                    <td className="text-black text-right">{this.state.Job.PONumber}</td>
                                </tr>
 
                                <tr>
                                    <th colSpan="4" className="text-black">Description</th>
                                    <td colSpan="2" className="text-black text-center"><b><State color="text-black" state={this.state.data.State}/></b></td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className="text-black" style={{width: '50%',height: '25rem'}} >
                                        <div style={{position: 'relative', width: '100%', height: '100%'}}>
                                            <span dangerouslySetInnerHTML={{__html: this.data("Scope").replaceAll("\n","<br/>")}}></span>
                                            <span style={{position: 'absolute', left: '0px', bottom: '5px', padding: '1rem'}}> 
                                                {this.state.uploads.map((file, i)=>{
                                                    if(file.Filename.indexOf("sketch_") > -1){
                                                                return (<img key={i} src={this.props.restServer+"/api/download/"+file.Guid} width="350"/> )
                                                    }
                                                })
                                                }
                                            </span>
                                        </div>
                                    </td>
                                    <td colSpan="3">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th colSpan="2" className="text-black text-center"><i>Cutting List</i></th>
                                                </tr>
                                                <tr><td style={{width:'50%'}}>Material</td><td></td></tr>
                                                <tr><td>Diameter</td><td></td></tr>
                                                <tr><td>Length</td><td></td></tr>
                                                <tr><td>Width</td><td></td></tr>
                                                <tr><td>Quantity</td><td></td></tr>
                                                <tr><td>Deliver To</td><td></td></tr>
                                                <tr><td colSpan="2" className="text-black text-center">Special Instructions</td></tr>
                                                <tr><td colSpan="2" style={{height: '4rem'}}></td></tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="3">
                                        <table className="table no-borders">
                                            <tbody>
                                                <tr>
                                                    <th colSpan="2" className="text-black text-center"><i>Processes</i></th>
                                                </tr>
                                                {steps.map((row, i) => {
                                                    return (
                                                    <tr key={i}>
                                                        <th className="text-black">{row.complete?(<React.Fragment>{this.ProcessName(row.process)}</React.Fragment>):(<React.Fragment>{this.ProcessName(row.process)}</React.Fragment>)}</th>
                                                        <td className="text-black">{row.complete?(<React.Fragment>{row.OperatorName}<i className="text-black fe fe-check-circle hover ml-2"></i></React.Fragment>):(<React.Fragment>{row.OperatorName}</React.Fragment>)}</td>
                                                    </tr>
                                                    );        
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                    
                                    <td colSpan="3">
                                        <table className="table no-borders">
                                            <tbody>
                                                <tr>
                                                    <th colSpan="4" className="text-black text-center"><i>Attachments</i></th>
                                                </tr>
                                                <tr>
                                            <th scope="col" className="text-left text-black">File Name</th>
                                            <th scope="col" className="text-black  text-right">Uploaded By</th>

                                            </tr>
                                                {this.state.uploads.map((file, i)=>{
                                                    return (
                                                        <tr key={i}>
                                                            <td className="text-black p-3">
                                                            <small>{file.Filename}</small></td>
                                                            <td className="text-black p-3 text-right"><small>{this.UserName(file.UserId)}</small></td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </td>


                                </tr>

                               <tr>
                               <td colSpan="5" className="text-right">Price</td>
                               <td>$___________</td> 
                               </tr>                 


                            </tbody>
                            </table>              
                        </div>




                </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default JobsEditPrint;