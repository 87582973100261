import React, { Component } from 'react';
import {Link} from "react-router-dom";
import queryString from "query-string"
class SuppliersAll extends Component {
    state = {
        loading: true,
        search: "",
        data: []
    }
    onSearch = (search) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true, search: search});
        this.props.history.replace(`${this.props.location.pathname}?q=${search}`)
        databaseRequest(`/suppliers?Name:like=%25${search}%25`,"GET").then((suppliers)=>{
            this.setState({
                loading: false,
                Suppliers: suppliers
            })
        });
    }
    componentDidMount = async () =>{
        const {databaseRequest,setPage}=this.props;
        var query = queryString.parse(this.props.location.search);
        let t =this;
        var search = "";
        if(typeof query.q !== 'undefined'){
            search = query.q;
        }
        t.jobInput.focus();
        setPage(`Suppliers`);
        var Suppliers = await databaseRequest(`/suppliers?Name:like=%25${search}%25`,"GET");

        t.setState({
            search: search,
            Suppliers: Suppliers,
            loading: false
        })

            

        
    } 

    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading suppliers...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col" style={{width: '20px'}}>#</th>
                        <th scope="col">Supplier Name</th>
                        <th scope="col">Phone</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.Suppliers.map((row, i) => {
                        return (
                        <tr key={i}>                   
                            <th scope="row" ><Link to={`/system/suppliers/${row.Id}`}>{1+i}</Link></th>
                            <td><Link to={`/system/suppliers/${row.Id}`}>{row.Name}</Link></td>
                            <td>{row.Number}</td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-dollar-sign"></i> All Suppliers</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input ref={(input) => { this.jobInput = input; }} className="form-control mr-sm-2" type="search" value={this.state.search} onChange={(event)=>{this.onSearch(event.target.value)}} placeholder="Search" aria-label="Search"/> 
                            <div className="form-group col-md-1 align-self-end">
                                <button type="button" className="btn btn-danger" onClick={()=>{this.onSearch("")}}><i className="fe fe-x"></i></button>
                            </div>                     
                        </form>

                    </div>
                    <div className="col p-0">
                        <Link to="/system/suppliers/-1"><button type="button" className="btn btn-success float-right">New Supplier</button></Link>
                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
            </main>

            
        )
    }
}

export default SuppliersAll;