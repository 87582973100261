import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import TimeAgo from '../../Components/TimeAgo.js';
import Breadcrumb from '../../Components/Breadcrumb.js';
import queryString from "query-string"
import DatePicker, {registerLocale} from "react-datepicker";
import enAU from 'date-fns/locale/en-AU';
import "react-datepicker/dist/react-datepicker.css";
import { Time } from 'highcharts';

class UsersAll extends Component {
    interval = null;
    state = {
        loading: true,
        redirect: "",
        TimesheetTotal: 0,
        data: [], 
        count: 0,
        modal:{        
            Guid: "",
            Id: -1,
            start:{date: "", hour: 0, minute: 0, second: 0},
            end:{date: "", hour: 0, minute: 0, second: 0},
        }
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    dateStartChange = date => {
        var { modal} = this.state;
        modal.start.date = date;
        this.setState({
            modal: modal
        });
    };
    dateEndChange = date => {
        var { modal} = this.state;
        modal.end.date = date;
        this.setState({
            modal: modal
        });
    };
    reload = async ()=>{
        const {databaseRequest,params,setPage}=this.props;
        const {data} = this.state;
        let t = this;
        if(t.interval!==null){
            clearInterval(t.interval);
        }     
        setPage("Timesheet");
        if(typeof params.JobId ==='undefined' || params.JobId==-1){
            t.setState({redirect:"/jobs/-1", count: 0});
        }
        else{
            data.Id = params.JobId;
            var Job = await databaseRequest(`/jobs/${params.JobId}`,"GET");
            if(typeof Job.Id !=='undefined'){
                var Company = await databaseRequest(`/companies/${Job.CompanyId}`,"GET");
                var Contact = await databaseRequest(`/contacts/${Job.ContactId}`,"GET");
                var Operators = await databaseRequest(`/operators`,"GET");
                var Timesheet = await databaseRequest(`/timesheet/${Job.Id}`,"GET");
                var Total = 0;
                for(let i=0; i<Timesheet.length; i++){
                    if(Timesheet[i].Duration > 0){
                        Total += Timesheet[i].Duration;
                    }
                    else{
                        Total += this.toNow(Timesheet[i].CreatedDateTime);
                    }
                }

                setPage(`[${Company.Name}] - ${Job.Name} | Timesheet`);
                data.Name=Job.Name;
                data.CompanyName=Company.Name;
                data.Scope=Job.Scope;
                data.Guid = Job.Guid;
        
                t.setState({
                    loading: false,
                    Job: Job,
                    Company: Company,
                    Contact: Contact,
                    Operators: Operators,
                    Timesheet: Timesheet,
                    TimesheetTotal: Total,
                    data:data,
                });
                t.interval = setInterval(()=>{
                    const {Timesheet, count} = t.state;
                    var Total = 0;
                    for(let i=0; i<Timesheet.length; i++){
                        if(Timesheet[i].Duration > 0){
                            Total += Timesheet[i].Duration;
                        }
                        else{
                            Total += this.toNow(Timesheet[i].CreatedDateTime);
                        }
                    }
                    if(count > 10){
                        t.reload();
                    }
                    else{
                        t.setState({TimesheetTotal: Total, count: (count+1)});
                    }
                    
                }, 1000);
            }
        }
    }
    async componentDidMount(){
        this.reload();
        
    }
    loadModal = (Guid, Id) =>{
        const {Timesheet, modal} = this.state;
        for(let i=0; i<Timesheet.length; i++){
            if(Timesheet[i].Guid==Guid && Timesheet[i].Id==Id){
                modal.Guid = Guid;
                modal.Id = Id;
                modal.start.date = new Date(Timesheet[i].CreatedDateTime);
                modal.end.date = new Date(Timesheet[i].SubmittedDateTime);
                modal.start.hour = modal.start.date.getHours();
                modal.start.minute = modal.start.date.getMinutes();
                modal.start.second = modal.start.date.getSeconds();
                
                modal.end.hour = modal.end.date.getHours();
                modal.end.minute = modal.end.date.getMinutes();
                modal.end.second = modal.end.date.getSeconds();
                this.setState({
                    modal:modal
                })
            }
        }
    }
    updateTimesheet = async () =>{
        let t = this;
        const {databaseRequest}=this.props;

        var {modal, Timesheet} = this.state;
        this.setState({loading: true});

        if(modal.Id!==-1){
            var newStartDate = modal.start.date;
            newStartDate.setHours(modal.start.hour);
            newStartDate.setMinutes(modal.start.minute);
            newStartDate.setSeconds(modal.start.second);

            var newEndDate = modal.end.date;
            newEndDate.setHours(modal.end.hour);
            newEndDate.setMinutes(modal.end.minute);
            newEndDate.setSeconds(modal.end.second);

            for(let i=0;i<Timesheet.length; i++){
                if(modal.Id==Timesheet[i].Id && modal.Guid==Timesheet[i].Guid){
                    Timesheet[i].CreatedDateTime = newStartDate.getTime();
                    Timesheet[i].SubmittedDateTime = newEndDate.getTime();
                    Timesheet[i].Duration = Timesheet[i].SubmittedDateTime-Timesheet[i].CreatedDateTime;
                }
            }
            databaseRequest(`/timesheet/record/${modal.Id}`, 'PUT',JSON.stringify({
                SubmittedDateTime: newEndDate.getTime(),
                CreatedDateTime: newStartDate.getTime(),
                Duration: newEndDate.getTime() - newStartDate.getTime()
            })).then((data)=>{
                t.setState({loading: false, Timesheet: Timesheet})
            });

            
            
        }
    }
    componentWillUnmount(){
        let t = this;
        if(t.interval!==null){
            clearInterval(t.interval);
        }     
    }
    CompanyName = (e)=>{
        const {Companies} = this.state;
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===e){
                return (Companies[i].Name);
            }
        }
        return "";
    }
    ContactName = (e)=>{
        const {Contacts} = this.state;
        for(let i=0; i<Contacts.length; i++){
            if(Contacts[i].Id===e){
                return (Contacts[i].Name);
            }
        }
        return "";
    }
    OperatorName = (e)=>{
        const {Operators} = this.state;
        for(let i=0; i<Operators.length; i++){
            if(Operators[i].Id===e){
                return (Operators[i].Name);
            }
        }
        return "";
    }
    OperatorFirstName = (e)=>{
        const {Operators} = this.state;
        for(let i=0; i<Operators.length; i++){
            if(Operators[i].Id===e){
                return (Operators[i].Name).split(' ')[0];
            }
        }
        return "";
    }
    secondsToTime = (time)=>{
        var hours = Math.floor(time/3600);
        var minutes =  Math.floor((time - (hours*3600))/60);
        var seconds = time%60;

        return `${hours<10?"0"+hours:hours}:${minutes<10?"0"+minutes:minutes}:${seconds<10?"0"+seconds:seconds}`
    }
    toNow = (CreatedDateTime)=>{
        var now = new Date();
        return now - CreatedDateTime;
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">Loading timesheet...</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading users...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Operator</th>
                        <th scope="col">Start Time</th>
                        <th scope="col">End Time</th>
                        <th scope="col">Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.Timesheet.map((row, i) => {
                        return (
                        <tr key={i} className={row.Duration==-1?"bg-info text-white":""}>
                            <th scope="row" >{1+i}</th>
                            <td><i className="fe fe-edit link" onClick={()=>{this.loadModal(row.Guid, row.Id)}} data-toggle="modal" data-target="#datetimemodal"></i> - {this.OperatorName(row.OperatorId)}</td>
                            <td>{new Date(row.CreatedDateTime).toLocaleString()}</td>
                            <td>{new Date(row.SubmittedDateTime).toLocaleString()}</td>
                            <td>{row.Duration==-1?(this.secondsToTime(Math.floor(this.toNow(row.CreatedDateTime)/1000))):this.secondsToTime(Math.floor(row.Duration/1000))}</td>
                        </tr>)
                    })}

                        <tr>
                            <th scope="row"></th>
                            <td></td>
                            <td></td>
                            <td className="text-right font-weight-bold">Total</td>
                            <td className="font-weight-bold">{this.secondsToTime(Math.floor(this.state.TimesheetTotal/1000))}</td>
                        </tr>

                    </tbody>
                    </table>
                )
            }
    }

    render(){
        const {props} = this;
        const query = queryString.parse(this.props.location.search);
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        const DatepickerInput = ({ ...props }) => (
            <input type="text" {...props} readOnly />
          );
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-clock"></i> Time Sheet</h2>
                <div className="row form-group">
                    <Breadcrumb {...props} JobId={this.state.data.Id} Active="Time Sheet"/>
                </div>
                <div className="row form-group w-100">
                    <div className="col p-0 mx-auto">
                        <h1 className="border-bottom mb-1">[{this.state.data.Id==-1?" - ":this.state.data.Id}] {this.state.Job.CompanyId==-2?this.state.Contact.Name:this.state.Company.Name} - <i>{this.data("Name")}</i></h1>
                        <small className="text-muted">Created: <TimeAgo time={this.state.Job.CreatedDateTime}/> ({new Date(this.state.Job.CreatedDateTime).toLocaleDateString()})</small>
                        <br/>
                        <small className="text-muted">Last Updated: <TimeAgo time={this.state.Job.UpdatedDateTime}/> ({new Date(this.state.Job.UpdatedDateTime).toLocaleDateString()})</small>
                        <h5 className="w-100 font-weight-bold mt-3"><u>Job Description</u></h5>
                        <p className="w-100 font-italic">
                            {this.data("Scope").split("\\n").map(function(item, i) {
                            return (
                                <span key={i}>
                                    {item}
                                    <br/>
                                </span>)
                            })}
                        </p>
                    </div>
                    <div className="col p-0 col-md-2">
                    
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}?return=${query.return}`}>
                                <button type="button" className="btn btn-dark float-right">History</button>
                            </Link>
                            {/*<button type="button" className="btn btn-success ml-3" onClick={(e)=>{this.saveBtn(e, "jobs/"+this.state.data.Id+"?view="+query.view)}}>Save</button>*/}
                    </div>
                </div>

                <div className="row form-group mt-4 d-flex justify-content-center">
                        {this.table()}
                </div>

                <div className="form-row justify-content-between">
                        <div className="form-group col-mx-auto">
                            <Link to={`/jobs/${this.state.data.Id}?return=${query.return}`}><button type="button" className="btn btn-secondary float-right">Back</button></Link>
                        </div>
                 </div>
            </div>
            <div className="modal" id="datetimemodal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Update Timesheet</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Start Time</p>
                            <div className="form-row justify-content-between">
                                <div className="form-group col-mx-auto">
                                <DatePicker  
                                dateFormat="dd/MM/yyyy" 
                                minDate={new Date().setMonth(new Date().getMonth() - 12)} 
                                disabled={this.state.disabled}  
                                placeholderText="Due Date" 
                                className="form-control text-right" 
                                selected={this.state.modal.start.date} 
                                onChange={this.dateStartChange} 
                                customInput={<DatepickerInput />}
                                />
                                </div>
                                <div className="form-group col-mx-auto">
                                    <input type="text" className="form-control mr-2" onChange={(e)=>{var {modal} = this.state; modal.start.hour=e.target.value; this.setState({modal: modal})}} value={this.state.modal.start.hour} style={{width: "50px", display: "inline-block"}}/>:
                                    <input type="text" className="form-control ml-2 mr-2" onChange={(e)=>{var {modal} = this.state; modal.start.minute=e.target.value; this.setState({modal: modal})}}  value={this.state.modal.start.minute} style={{width: "50px", display: "inline-block"}}/>:
                                    <input type="text" className="form-control ml-2" onChange={(e)=>{var {modal} = this.state; modal.start.second=e.target.value; this.setState({modal: modal})}} value={this.state.modal.start.second} style={{width: "50px", display: "inline-block"}}/>
                                </div>
                            </div>
                            <p>End Time</p>
                            <div className="form-row justify-content-between">
                                <div className="form-group col-mx-auto">
                                <DatePicker  
                                dateFormat="dd/MM/yyyy" 
                                minDate={new Date().setMonth(new Date().getMonth() - 12)} 
                                disabled={this.state.disabled}  
                                placeholderText="Due Date" 
                                className="form-control text-right" 
                                selected={this.state.modal.end.date} 
                                onChange={this.dateEndChange} 
                                customInput={<DatepickerInput />}
                                />
                                </div>
                                <div className="form-group col-mx-auto">
                                    <input type="text" className="form-control mr-2" onChange={(e)=>{var {modal} = this.state; modal.end.hour=e.target.value; this.setState({modal: modal})}} value={this.state.modal.end.hour} style={{width: "50px", display: "inline-block"}}/>:
                                    <input type="text" className="form-control ml-2 mr-2" onChange={(e)=>{var {modal} = this.state; modal.end.minute=e.target.value; this.setState({modal: modal})}} value={this.state.modal.end.minute} style={{width: "50px", display: "inline-block"}}/>:
                                    <input type="text" className="form-control ml-2" onChange={(e)=>{var {modal} = this.state; modal.end.second=e.target.value; this.setState({modal: modal})}}  value={this.state.modal.end.second} style={{width: "50px", display: "inline-block"}}/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.updateTimesheet}>Update</button>
        
                        </div>
                        </div>
                    </div>
                </div>

            </main>
        );
    }
}

export default UsersAll;