import React, { Component } from 'react';

class TimeAgo extends Component {
    calculateWorkDates = (date)=>{

        if(date!==null){
            var today = new Date();
            today.setHours(0,0,0,0);
            var endDate = date;
            endDate.setHours(0,0,0,0);

            var days = 0;
            for(var i=today.getTime(); i<endDate.getTime(); i+=86400000){
                var temp = new Date(i)
                if(temp.getDay()!==5 && temp.getDay()!==6){
                    days++;
                }
            }
            return days;
        }
        
        return 0;
    }
    daysToGo = (timestamp) => {
        var business = false;
        var completed=false;
        var d = new Date();
        d.setHours(0,0,0,0);

        var duration = Math.floor((timestamp - d.getTime())/86400000);
        if(typeof this.props.businessdays!=='undefined'){
            if(this.props.businessdays){
                business=true;
                duration = this.calculateWorkDates(new Date(timestamp));
            }
        }
        
        if(typeof this.props.state!=='undefined'){
            if(this.props.state===8){
                completed=true;
            }
        }
        var textType = "text-primary";
        if(duration < 7){
            textType = "text-warning font-weight-bold";
        }
        if(duration < 1){
            textType = "text-danger";
        }
    if(duration <= 0 && business){
        duration = Math.floor((timestamp - d.getTime())/86400000)
        business=false;
        duration = Math.abs(duration);
        return (<p className="text-danger font-weight-bold">{duration} days ago</p>)
    }
    if(completed){
        return (<React.Fragment></React.Fragment>)
    }
    if(duration==0){
        return (<p className={textType}>Today</p>)
    }
    return (<p className={textType}>{duration} {business?"business ":""}day{duration===0?"":"s"}</p>)
    }
    render (){
        return this.daysToGo(this.props.time);
    }
}

export default TimeAgo;