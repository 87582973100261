import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import State from '../../Components/State.js';
import TimeAgo from '../../Components/TimeAgo.js';
import DaysToGo from '../../Components/DaysToGo.js';
import JobsPrint from './JobsPrint.js'
import ReactToPrint from 'react-to-print';
import queryString from "query-string"

class Jobs extends Component {
    state = {
        loading: true,
        sort:{
            Id: 0,
            DueDateTime: 1
        },
        JobId: "",
        JobName: "",
        ContactName: "",
        Description: "",
        search: "",
        data: [],
        Companies: [],
        query: "",
        title: "All",
        redirect: ""
    }
    async componentDidMount(){
        const {databaseRequest,setPage,view}=this.props;
        let t =this;
        setPage(`Search`);

        

        var companies = await databaseRequest("/companies","GET");
        var contacts = await databaseRequest("/contacts","GET");

        var query = queryString.parse(this.props.location.search);


        var position = 0;
        if(typeof query.p !== 'undefined'){
            position = query.p;
        }

        var search_id = "";
        var search_name="";
        var search_contact="";
        var search_desc = "";

        if(typeof query.qid !== 'undefined'){
            search_id = query.qid;
            search_name="";
            search_contact="";
            search_desc = "";
        }
        if(typeof query.qname !== 'undefined'){

            search_id = "";
            search_name = query.qname;
            search_contact="";
            search_desc = "";   
        }
        if(typeof query.qcontact !== 'undefined'){
            search_id = "";
            search_name = "";
            search_contact= query.qcontact;
            search_desc = "";
        }
        if(typeof query.desc !== 'undefined'){
            search_id = "";
            search_name = "";
            search_contact= "";
            search_desc = query.desc;
        }
        window.scrollTo(0, position);

        t.setState({
            JobId: search_id,
            JobName: search_name,
            ContactName: search_contact,
            Description: search_desc,
            Companies: companies,
            Contacts: contacts,
            loading: false,
            title:"Search",
            icon: "search"
        })

        if(search_id.length > 0){
            this.jobId.focus();
            this.JobIdChange(search_id);
        }
        if(search_name.length > 0){
            this.jobName.focus();
            this.JobNameChange(search_name);
        }
        if(search_contact.length > 0){
            this.jobContact.focus();
            this.ContactNameChange(search_contact);
        }
        if(search_desc.length > 0){
            this.jobDesc.focus();
            this.DescriptionChange(search_desc);
        }
        if(search_id.length < 0 && search_name.length < 0 && search_contact.length < 0 && search_desc.length < 0){
            this.jobId.focus();
        }
        

    }
    sortJobId = ()=>{
        var {data, sort}=this.state;
        if(sort.Id==-1 || sort.Id==0){
            data = data.sort( ( a, b ) => {
                if ( a.Id < b.Id ){
                return -1;
                }
                if ( a.Id > b.Id ){
                return 1;
                }
                return 0;
            });
            sort.Id=1;
            sort.DueDateTime=0;
        }
        else if(sort.Id==1){
            data = data.sort( ( b, a ) => {
                if ( a.Id < b.Id ){
                return -1;
                }
                if ( a.Id > b.Id ){
                return 1;
                }
                return 0;
            });
            sort.Id=-1;
            sort.DueDateTime=0;
        }
        this.setState({data: data, sort: sort})
    }
    sortDueDateTime = ()=>{
        var {data, sort}=this.state;
        if(sort.DueDateTime==-1 || sort.DueDateTime==0){
            data = data.sort( ( a, b ) => {
                if ( a.DueDateTime < b.DueDateTime ){
                return -1;
                }
                if ( a.DueDateTime > b.DueDateTime ){
                return 1;
                }
                return 0;
            });
            sort.Id=0;
            sort.DueDateTime=1;
        }
        else if(sort.DueDateTime==1){
            data = data.sort( ( b, a ) => {
                if ( a.DueDateTime < b.DueDateTime ){
                return -1;
                }
                if ( a.DueDateTime > b.DueDateTime ){
                return 1;
                }
                return 0;
            });
            sort.Id=0;
            sort.DueDateTime=-1;
        }
        this.setState({data: data, sort: sort})
    }
    jobCompleted = async (JobId) =>{
        const {databaseRequest, params}=this.props;
        let t = this; 
        t.setState({
            loading: true,
            loadingMsg: `Updating state...`
        });
        var req = {
            url: `/jobs/${JobId}/state/8`,
            method: "PUT"
        }
        await databaseRequest(req.url,req.method,JSON.stringify({}));
        setTimeout(()=>{
            t.setState({
                loading: false,
                redirect: "/redirector?r="+this.props.location.pathname.substring(1),
                loadingMsg: `Updating state...`
            });
        }, 500);
    }
    invoiceSent = async (JobId) =>{
        const {databaseRequest, params}=this.props;
        let t = this; 
        t.setState({
            loading: true,
            loadingMsg: `Updating state...`
        });
        var req = {
            url: `/jobs/${JobId}/state/11`,
            method: "PUT"
        }
        await databaseRequest(req.url,req.method,JSON.stringify({}));
        setTimeout(()=>{
            t.setState({
                loading: false,
                redirect: "/redirector?r="+this.props.location.pathname.substring(1),
                loadingMsg: `Updating state...`
            });
        }, 500);
    }
    onSearch = (event) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        var search = event.target.value;
        search = encodeURIComponent(search);
        console.log(search);
        databaseRequest(`/jobs?${this.state.query}&Name:br-like=%25${search}%25&CompanyName:or_like=%25${search}%25&ContactName:or_like=%25${search}%25&Id:or_like-br=%25${search}%25`,"GET").then((data)=>{
                this.setState({
                    loading: false,
                    data: data.length>0?data:[]
                });
        });
    }
    clearSearch = ()=>{
        this.props.history.replace(`/search`)
        this.setState({
            loading: false,
            data: [],
            JobId: "",
            JobName: "",
            ContactName: "",
            Description: ""
        })
    }

    JobIdChange = (search) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        this.setState({
            JobId: search,
            JobName: "",
            ContactName: "",
            Description: ""
        })
        if(search.length > 1){
            search = encodeURIComponent(search);
            this.props.history.replace(`/search?qid=${search}`)
            databaseRequest(`/jobs?Id=${search}`,"GET").then((data)=>{
                    this.setState({
                        loading: false,
                        data: data.length>0?data:[]
                    });
            });
        }
        else{
            this.setState({
                loading: false,
                data: []
            });
        }
    }

    JobNameChange = (search) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        this.setState({
            JobId: "",
            JobName: search,
            ContactName: "",
            Description: ""
        })
        if(search.length > 1){
            search = encodeURIComponent(search);
            this.props.history.replace(`/search?qname=${search}`)
            databaseRequest(`/jobs?Name:like=%25${search}%25`,"GET").then((data)=>{
                    this.setState({
                        loading: false,
                        data: data.length>0?data:[]
                    });
            });
        }
        else{
            this.setState({
                loading: false,
                data: []
            });
        }
    }
    ContactNameChange = (search) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        this.setState({
            JobId: "",
            JobName: "",
            ContactName: search,
            Description: ""
        })
        
        if(search.length > 1){
            search = encodeURIComponent(search);
            this.props.history.replace(`/search?qcontact=${search}`)
            databaseRequest(`/jobs?CompanyName:or_like=%25${search}%25&ContactName:or_like=%25${search}%25`,"GET").then((data)=>{
                    this.setState({
                        loading: false,
                        data: data.length>0?data:[]
                    });
            });
        }
        else{
            this.setState({
                loading: false,
                data: []
            });
        }
    }
    DescriptionChange = (search) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        this.setState({
            JobId: "",
            JobName: "",
            ContactName: "",
            Description: search
        })
        if(search.length > 1){
            search = encodeURIComponent(search);
            this.props.history.replace(`/search?qdesc=${search}`)
            databaseRequest(`/jobs?Scope:like=%25${search}%25`,"GET").then((data)=>{
                    this.setState({
                        loading: false,
                        data: data.length>0?data:[]
                    });
            });
        }
        else{
            this.setState({
                loading: false,
                data: []
            });
        }
    }
    getCompanyName = (CompanyId,ContactId)=>{
        var {Companies,Contacts} = this.state;
        if(CompanyId==-2){
            for(let i=0; i<Contacts.length; i++){
                if(Contacts[i].Id===ContactId){
                    return "["+Contacts[i].Name+"]";
                }
            }
        }
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===CompanyId){
                return Companies[i].Name;
            }
        }
        return null;
    }

    table = () => {    
        const access = localStorage.getItem("access");
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading jobs...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped ">
                    <thead className="thead-dark ">
                        <tr>
                        {access>20?(<th scope="col" style={{width: '20px'}}></th>):(<React.Fragment></React.Fragment>)}    
                        <th scope="col" className="cursor-pointer" onClick={this.sortJobId}>
                            Job No. {this.state.sort.Id==1?(<i className="fe fe-chevron-down mr-2"></i>):this.state.sort.Id==-1?(<i className="fe fe-chevron-up mr-2"></i>):(<i className="fe fe-minus mr-2"></i>)}
                        </th>
                        
                        <th scope="col">Company</th>
                        <th scope="col">Name</th>
                        <th scope="col" className="text-right">Created Date</th>
                        <th scope="col" className="text-right cursor-pointer" onClick={this.sortDueDateTime}>
                            Due Date
                            {this.state.sort.DueDateTime==1?(<i className="fe fe-chevron-down mr-2"></i>):this.state.sort.DueDateTime==-1?(<i className="fe fe-chevron-up mr-2"></i>):(<i className="fe fe-minus mr-2"></i>)}
                        </th>
                        <th scope="col" className="text-right">State</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        var CompanyName = this.getCompanyName(row.CompanyId,row.ContactId);
                        return (
                        <tr key={i}>

                            {access>20?
                            <React.Fragment>
                            {(row.State==5)?(<td><i className="fe fe-check-circle link" onClick={()=>{this.jobCompleted(row.Id)}}></i></td>):(<React.Fragment></React.Fragment>)}      
                            {(row.State==8)?(<td><i className="fe fe-send link" onClick={()=>{this.invoiceSent(row.Id)}}></i></td>):(<React.Fragment></React.Fragment>)}
                            {(row.State!=8 && row.State!=5)?(<td></td>):(<React.Fragment></React.Fragment>)}
                            </React.Fragment>
                            :
                            <React.Fragment></React.Fragment>}
                            
                            <th scope="row" ><Link to={`/jobs/${row.Id}?return=${this.props.location.pathname}`}>{row.Id}</Link></th>
                            
                            <td><Link to={`/jobs/${row.Id}?return=${this.props.location.pathname}`}>{CompanyName}</Link></td>
                            <td><Link to={`/jobs/${row.Id}?return=${this.props.location.pathname}`}>{row.Name} ({row.ProcessCount})</Link>
                            </td>
                            <td className="text-right"><p><b><TimeAgo time={row.CreatedDateTime} /></b></p> ({new Date(row.CreatedDateTime).toLocaleDateString()})</td>
                            <td className="text-right"><DaysToGo time={row.DueDateTime} businessdays={true} state={row.State}/> ({new Date(row.DueDateTime).toLocaleDateString()})</td>
                            <td className="text-right"><State state={row.State} /></td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-search"></i> {this.state.title} Jobs</h2>
                <div className="row form-group">

                            <div className="form-group col-md-2">
                                <label>Job Number</label>
                                <input type="text" ref={(input) => { this.jobId = input; }}  autoComplete="off" value={this.state.JobId} onChange={(event)=>{this.JobIdChange(event.target.value)}} className="form-control" placeholder="Number" name="Number" />
                            </div> 
                            <div className="form-group col-md-2">
                                <label>Job Name</label>
                                <input type="text" ref={(input) => { this.jobName = input; }} autoComplete="off" value={this.state.JobName} onChange={(event)=>{this.JobNameChange(event.target.value)}} className="form-control" placeholder="Job Name" name="JobName" />
                            </div> 
                            <div className="form-group col-md-2">
                                <label>Company/Contact Name</label>
                                <input type="text" ref={(input) => { this.jobContact= input; }} autoComplete="off" value={this.state.ContactName} onChange={(event)=>{this.ContactNameChange(event.target.value)}} className="form-control" placeholder="Company/Contact Name" name="ContactName" />
                            </div> 
                            <div className="form-group col-md-3">
                                <label>Description</label>
                                <input type="text" ref={(input) => { this.jobDesc = input; }} autoComplete="off" value={this.state.Description} onChange={(event)=>{this.DescriptionChange(event.target.value)}} className="form-control" placeholder="Description" name="Description" />
                            </div> 

                            <div className="form-group col-md-3 mt-4">
                                <Link to={`/jobs/-1?return=${this.props.location.pathname}`}><button type="button" className="btn btn-success float-right">New Job</button></Link>
                                <button type="button" onClick={this.clearSearch} className="btn btn-secondary float-right mr-4">Clear</button>
                            </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
                <button className="btn btn-info" style={{position: "fixed", bottom: "5px", right: "5px" }} onClick={()=>{window.scrollTo(0,0);}}><i className="fe fe-chevron-up"></i></button>
            </main>

            
        )
    }
}

export default Jobs;